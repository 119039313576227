import apiCallWrapper from 'utils/apiCallWrapper';
import * as constants from './constants';

const middleware: any = ({
  dispatch,
  getState,
}: {
  dispatch: any;
  getState: any;
}) => (next: any) => (action: any) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case constants.CURD_FORM_ON_SUBMIT_DATA:
      apiCallWrapper({
        ...action.payload,
        dispatch,
      });
      break;
    default:
      break;
  }

  return next(action);
};

export default middleware;
