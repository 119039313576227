import React from 'react';
import styled from 'styled-components';
import LayoutDashboard from 'layout/Dashboard';
import clsx from 'clsx';

type TMain = {
  className?: string;
  title: string;
};

const Main: React.FC<TMain> = ({ title, className }) => {
  return (
    <LayoutDashboard
      pageTitle={title}
      className={clsx(className, 'page_Main')}
    />
  );
};

const MainStyled = styled(Main)``;

export default MainStyled;
