import styled from 'styled-components';
import ToastMessage from './ToastMessage';

const ToastMessageStyled = styled(ToastMessage)`
  --py: 0.5rem;

  margin: 1rem;
  padding: var(--py) 1rem;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
  color: ${props => props.theme.colors.fg.primary};
  background-color: ${props =>
    props.type === 'success'
      ? props.theme.colors.brand.green
      : props.theme.colors.brand.red};
  display: inline-grid;
  width: fit-content;
  grid-template-rows: 100%;
  grid-template-columns: auto 1fr;
  transition: 300ms all;
  opacity: ${props => (props.debug ? 1 : 0)};
  top: 2rem;
  left: calc(50%);
  transform: translateX(-50%);
  position: relative;
  overflow: hidden;
  padding-bottom: calc(var(--py) + 5px);
  ${props => props.redirect && 'padding-bottom: calc(var(--py) + 5px)'};
  div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  div:nth-child(2) {
    padding: 0 1rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  .msgCode {
    font-size: 0.8rem;
    padding: 5px;

    margin-right: 1rem;
    background-color: ${props =>
      props.type === 'success'
        ? props.theme.colors.brand.greenDimmed
        : props.theme.colors.brand.redDimmed};
  }

  &:after {
    animation: __progressBarOut ${props => props.timeout}ms linear;
    position: absolute;
    content: ' ';
    bottom: 0;
    background-color: coral;
    width: 100%;
    height: 5px;
  }

  .svgIcon_wrapper {
    pointer-events: auto;
    cursor: pointer;
  }
`;

export default ToastMessageStyled;
