import * as constants from './constants';

export const crudFormOnSubmitData = (payload: any) => ({
  type: constants.CURD_FORM_ON_SUBMIT_DATA,
  payload,
});

export const crudFormOnSubmitDataStart = () => ({
  type: constants.CURD_FORM_ON_SUBMIT_DATA_START,
});

export const crudFormOnSubmitDataFinish = (payload?: any) => ({
  type: constants.CURD_FORM_ON_SUBMIT_DATA_FINISH,
  payload,
});

export const crudFormOnSubmitDataFail = (error?: any) => ({
  type: constants.CURD_FORM_ON_SUBMIT_DATA_FAIL,
  error,
});

export const crudFormToggleIsProcessing = (isProcessing?: boolean) => ({
  type: constants.CURD_FORM_TOGGLE_IS_PROCESSING,
  isProcessing,
});

export const crudFormSetMode = (payload: any) => ({
  type: constants.CURD_FORM_SET_MODE,
  payload,
});

export const crudTabOnReset = (payload: any) => ({
  type: constants.CRUD_FORM_ON_RESET,
  payload,
});
