import React from 'react';
import FormForm from 'components/forms/Form';
import { TCrudForm } from './types';
import H from 'components/typography/H/H';
import clsx from 'clsx';

const CrudForm: React.FC<TCrudForm> = ({
  params,
  initialValues,
  validationSchema,
  className,
  onSubmit,
  title,
  buttonsWrapper: ButtonsWrapper,
}) => {
  return (
    <div className={clsx(className, 'CrudForm')}>
      <div className="header_wrapper">
        <H variant="h3">{title}</H>
      </div>
      {params && (
        <div className="crud_wrapper">
          <FormForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, helpers) => {
              onSubmit(values, helpers);
            }}
            params={params}
            buttonsWrapper={ButtonsWrapper}
          />
        </div>
      )}
    </div>
  );
};

export default CrudForm;
