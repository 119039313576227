import {
  apiCustomersAdd,
  apiCustomersDelete,
  apiCustomersUpdate,
} from 'services/dataFetching/crud';
import {
  notSoSimpleFormConstructor,
  simpleFormConstructor,
} from 'components/forms/Form';
import { TCrudFormParams } from 'components/forms/CrudForm/types';
import { TMsg } from 'appTypes/messages';

export const crudValuesMapper = (values: any) => {
  const {
    id,
    email,
    first_name,
    last_name,
    phone,
    companyName,
    companyNumber,
    companySector,
    companyEmployeesNumber,
    companyPostcode,
    companyBuilding,
    companyId,
    pricingPlan,
  } = values;

  type TRet = {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    company?: object;
  };

  const ret: TRet = {
    id,
    email,
    first_name,
    last_name,
    phone,
  };

  if (companyName) {
    ret.company = {
      name: companyName,
      number: companyNumber,
      sector: companySector,
      employeesNumber: companyEmployeesNumber,
      postcode: companyPostcode,
      building: companyBuilding,
      pricing_plan_id: pricingPlan,
      id: companyId,
    };
  }

  if (!companyName && pricingPlan && pricingPlan !== 'label') {
    ret.company = {
      pricing_plan_id: pricingPlan,
      id: companyId,
    };
  }

  return ret;
};

export const crudApiCall = {
  add: (dispatch?: any, messages?: any) => ({
    apiCall: (values: any) => apiCustomersAdd(crudValuesMapper(values)),
  }),
  delete: (dispatch?: any, messages?: any) => ({
    apiCall: apiCustomersDelete,
  }),
  update: (dispatch?: any, messages?: any) => ({
    apiCall: (values: any) => apiCustomersUpdate(crudValuesMapper(values)),
  }),
};

export const crudFormParams = (messages: TMsg): TCrudFormParams => ({
  update: notSoSimpleFormConstructor({
    messages,
    fields: [
      { name: 'email', isDisabled: true },
      'first_name',
      'last_name',
      'phone',
      'companyName',
      'companyNumber',
      'companySector',
      'companyEmployeesNumber',
      'companyPostcode',
      'companyBuilding',
      'pricingPlan',
    ],
  }),
  add: simpleFormConstructor({
    messages,
    fields: [
      'email',
      'first_name',
      'last_name',
      'phone',
      'companyName',
      'companyNumber',
      'companySector',
      'companyEmployeesNumber',
      'companyPostcode',
      'companyBuilding',
      'pricingPlan',
    ],
  }),
});

export const crudInitialValuesMapper = (initialValues: any) => {
  const { id, email, first_name, last_name, phone, company } = initialValues;
  let companyData = {};

  if (company) {
    companyData = {
      companyName: company.name,
      companyNumber: company.number,
      companySector: company.sector,
      companyEmployeesNumber: company.employeesNumber,
      companyPostcode: company.postcode,
      companyBuilding: company.building,
      pricingPlan: company.pricing_plan_id,
      companyId: company.id,
    };
  }

  return {
    id,
    email,
    first_name,
    last_name,
    phone,
    ...companyData,
  };
};
