import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { TRouteAuthWrapperProps } from '../types';

/**
 * Private route wrapper. Use `redirectTo` for redirect if isAuthSuccess are true
 */
function RoutePrivate({
  isAuthSuccess,
  redirectTo,
  component: Component,
  title,
  exact,
}: TRouteAuthWrapperProps) {
  return (
    <Route
      exact={exact}
      render={({ history, location, match, staticContext }) =>
        isAuthSuccess ? (
          <Component
            history={history}
            location={location}
            match={match}
            staticContext={staticContext}
            title={title}
          />
        ) : (
          <Redirect to={redirectTo} />
        )
      }
    />
  );
}

export default RoutePrivate;
