import styled, { css } from 'styled-components';
import ButtonSubmit from './ButtonSubmit';

const ButtonSubmitStyled = styled(ButtonSubmit)<any>`
  min-width: 130px;
  min-height: 60px;
  border: none;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  padding: 0 2rem;
  transition: all 150ms;
  text-transform: uppercase;

  &:hover {
    background-color: ${props => props.theme.colors.brand.orange};
    color: ${props => props.theme.colors.brand.dark};
  }

  &.--processing {
    background-color: slategray;
  }

  ${({variant, theme}) => {
    switch (variant) {
      case 'primary':
        return css`
          background-color: ${theme.colors.brand.blue};
          color: ${theme.colors.brand.white};
        `;
      case 'secondary':
        return css`
          background-color: ${theme.colors.brand.yellow};
          color: ${theme.colors.brand.dark};
        `;
      case 'third':
        return css`
          background-color: ${theme.colors.brand.red};
          color: ${theme.colors.brand.white};
        `;
    }

    return null;
  }};
`;

export default ButtonSubmitStyled;
