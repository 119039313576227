import React from 'react';
import clsx from 'clsx';

type TButton = {
  className?: string;
  disabled?: boolean;
  label?: string;
  variant?: 'primary' | 'secondary' | 'third';
  onClick?: (event: React.MouseEvent | never) => void | {};
  children: React.ReactNode;
};

const Button: React.FC<TButton> = ({
  className,
  disabled = false,
  variant = 'primary',
  label,
  onClick,
  children,
}) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={clsx(
        className,
        'component_ui_Button',
        disabled && '--disable',
        `--${variant}`,
      )}
      type="button"
      onClick={onClick}
    >
      {label || children}
    </button>
  );
};

export default Button;
