export const TOAST_MESSAGE_ADD = 'TOAST_MESSAGE_ADD';
export type TOAST_MESSAGE_ADD = typeof TOAST_MESSAGE_ADD;

export const TOAST_MESSAGE_ADD_FAIL = 'TOAST_MESSAGE_ADD_FAIL';
export type TOAST_MESSAGE_ADD_FAIL = typeof TOAST_MESSAGE_ADD_FAIL;

export const TOAST_MESSAGE_ADD_FAIL_REDIRECT =
  'TOAST_MESSAGE_ADD_FAIL_REDIRECT';
export type TOAST_MESSAGE_ADD_FAIL_REDIRECT = typeof TOAST_MESSAGE_ADD_FAIL_REDIRECT;

export const TOAST_MESSAGE_ADD_SUCCESS = 'TOAST_MESSAGE_ADD_SUCCESS';
export type TOAST_MESSAGE_ADD_SUCCESS = typeof TOAST_MESSAGE_ADD_SUCCESS;

export const TOAST_MESSAGE_ADD_SUCCESS_REDIRECT =
  'TOAST_MESSAGE_ADD_SUCCESS_REDIRECT';
export type TOAST_MESSAGE_ADD_SUCCESS_REDIRECT = typeof TOAST_MESSAGE_ADD_SUCCESS_REDIRECT;

export const TOAST_MESSAGE_REMOVE = 'TOAST_MESSAGE_REMOVE';
export type TOAST_MESSAGE_REMOVE = typeof TOAST_MESSAGE_REMOVE;
