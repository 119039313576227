import React from 'react';
import clsx from 'clsx';
import SvgEyeCrossDark from 'assets/svg/icons/EyeCrossDark';
import SvgEyeDark from 'assets/svg/icons/EyeDark';
import { IFormSingleFieldParams, TInputField } from '../../types';

const PasswordField = ({
  id,
  value,
  onChange,
  onBlur,
  name,
  label,
  meta,
  className,
  placeholder,
}: TInputField & IFormSingleFieldParams) => {
  const [isVisible, setIsVisible] = React.useState(false);

  return (
    <div className={clsx(className, 'PasswordField')}>
      <label htmlFor={id}>{label}</label>
      <div className="icon_wrapper">
        <input
          aria-describedby={`${id}-helper-text`}
          autoComplete="new-password"
          className={clsx('text-input', 'component_PasswordField')}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          type={isVisible ? 'text' : 'password'}
          value={(value as unknown) as string}
          placeholder={(!label && placeholder) || ''}
        />
        <button
          className="buttonWithIcon_wrapper"
          type="button"
          onClick={() => setIsVisible(argIsVisible => !argIsVisible)}
        >
          {isVisible ? <SvgEyeDark /> : <SvgEyeCrossDark />}
        </button>
      </div>
      {meta && meta.touched && meta.error ? (
        <div className="helper-text" id={`${id}-helper-text`}>
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export default PasswordField;
