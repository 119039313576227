import React from 'react';
import clsx from 'clsx';
import shortid from 'shortid';

type TTabs = {
  defTab: string;
  className?: string;
};

export const Tab: React.FC<{ label?: string }> = ({ children }) => (
  <>{children}</>
);

const Tabs: React.FC<TTabs> = ({ children, defTab, className }) => {
  const [state, setState] = React.useState(defTab);

  const renderTabLabels = React.Children.toArray(children).map((tab: any) => (
    <div
      key={shortid.generate()}
      role="tab"
      className={clsx('tabLabel', state === tab.props.label && '--active')}
      onClick={() => setState(tab.props.label)}
    >
      {tab.props.label}
    </div>
  ));

  const renderTabPanel = React.Children.toArray(children).find(
    (tab: any) => tab.props.label === state,
  );

  return (
    <div className={clsx(className, 'component_Tabs')}>
      <div className={clsx('tabLabels_wrapper')}>{renderTabLabels}</div>
      <div role="tabpanel" className={clsx('tabPanel_wrapper')}>
        {renderTabPanel}
      </div>
    </div>
  );
};

export default Tabs;
