import styled from 'styled-components';
import CrudForm from './CrudForm';

export const CrudFormStyled = styled(CrudForm)`
  .crud_wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: white;
    .component_FormForm {
      width: 100%;
    }
  }

  .header_wrapper {
    text-align: left;
    padding: 2rem 0;

    .component_TypoH.h3 {
      text-align: left;
      text-transform: uppercase;
      font-size: 16px;
      color: ${props => props.theme.colors.brand.blue};
    }
  }

  .form_buttons_wrapper {
    margin-top: 4rem;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    justify-content: flex-start;
  }
`;

export default CrudFormStyled;
