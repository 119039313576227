import { TTheme } from 'appTypes/theme';

const lightTheme: TTheme = {
  colors: {
    bg: {
      primary: '#3C3C3B',
      secondary: '#3C3C3B',
      third: '#3C3C3B',
      forth: '#3C3C3B',
      fifth: '#3C3C3B',
    },
    fg: {
      primary: '#3C3C3B',
      secondary: '#3C3C3B',
      third: '#3C3C3B',
      forth: '#3C3C3B',
      fifth: '#3C3C3B',
    },
  },
};

export default lightTheme;
