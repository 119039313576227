import React, { useCallback } from 'react';
import LayoutDashboard from 'layout/Dashboard';
import Table from 'components/tables/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import getDispatchWrapper from 'utils/getDispatchWrapper';
import { ContextMessages } from 'services/messages/context';
import LayoutTable, { backBtn, crudBtnAdd } from 'layout/Table';
import CrudTab from 'layout/CrudTab';
import { CrudFormAdd, CrudFormUpdate } from 'components/forms/CrudForm';
import validators from 'services/validators';
import { columns, tableApiCall } from './tableConfig';
import {
  crudApiCall,
  crudFormParams,
  crudInitialValuesMapper,
} from './crudConfig';
import clsx from 'clsx';
import { crudFormSetMode } from 'store/actions';
import { constants } from 'configShared/store';
import styled from 'styled-components';

type TCustomers = {
  className?: string;
  title?: string;
};

const reducerId = constants.CUSTOMERS;

const RenderBalance = styled(({ className, freeBalance, prepaidBalance }) => {
  return (
    <div className={className}>
      <div className={'balance-title'}>Balance</div>
      <div className={'balance-body'}>
        <div className={'balance-type-wrapper'}>
          <div className={'balance-label'}>Free</div>
          <div className={'balance-value'}>{freeBalance || 0}</div>
        </div>
        <div className={'balance-type-wrapper'}>
          <div className={'balance-label'}>Prepaid</div>
          <div className={'balance-value'}>{prepaidBalance || 0}</div>
        </div>
      </div>
    </div>
  );
})`
  display: grid;
  grid-auto-flow: row;

  .balance-body {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
  }
  .balance-title {
    background-color: #5abdd7;
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.8rem;
  }
  .balance-type-wrapper {
    display: grid;
    grid-auto-flow: row;
    .balance-label {
      font-size: 0.8rem;
      color: #a2a2a2;
      text-align: center;
      font-weight: 500;
      text-transform: uppercase;
    }
    .balance-value {
      text-align: center;
    }
  }
`;

const Customers: React.FC<TCustomers> = ({ title, className }) => {
  const dispatchRedux = useDispatch();
  const dispatch = useCallback(getDispatchWrapper(reducerId, dispatchRedux), [
    reducerId,
    dispatchRedux,
  ]);

  const messages = React.useContext(ContextMessages);
  const tabLabel = useSelector((state: any) => state[reducerId].crud.tab);
  const tableState = useSelector((state: any) => state[reducerId].table);

  const tableApiCallMemo = React.useMemo(() => tableApiCall(dispatch), [
    dispatch,
  ]);

  return (
    <LayoutDashboard className={clsx(className, 'page_Customers')}>
      <LayoutTable
        tabLabel={tabLabel}
        title={title}
        reducerId={reducerId}
        backBtn={title && backBtn(dispatch, title)}
        crudBtnAdd={tabLabel !== 'crud-add' && crudBtnAdd(dispatch, '+ Create')}
      >
        <div className="tab" data-label="root">
          <Table
            state={tableState}
            dispatch={dispatch}
            apiCallWrapper={tableApiCallMemo}
            columns={columns}
            handleOnRowClick={(row: any) =>
              dispatch(
                crudFormSetMode({
                  tab: 'crud-update',
                  subTitle: (
                    <>
                      {row.first_name} {row.last_name}{' '}
                      {row.company && `/ ${row.company.name}`}
                    </>
                  ),
                  balance: (
                    <RenderBalance
                      prepaidBalance={row.branch.prepaid_balance}
                      freeBalance={row.branch.free_balance}
                    />
                  ),
                  initValues: crudInitialValuesMapper(row),
                }),
              )
            }
          />
        </div>
        <div className="tab" data-label="crud-add">
          <CrudTab>
            <CrudFormAdd
              apiCallWrapper={crudApiCall.add(dispatch)}
              dispatch={dispatch}
              reducerId={reducerId}
              validationSchema={validators(messages).dashboard.customers.add}
              params={crudFormParams(messages).add}
            />
          </CrudTab>
        </div>
        <div className="tab" data-label="crud-update">
          <CrudTab>
            <CrudFormUpdate
              apiCallWrapper={crudApiCall.update(dispatch)}
              deleteApiCallWrapper={crudApiCall.delete(dispatch)}
              dispatch={dispatch}
              reducerId={reducerId}
              validationSchema={validators(messages).dashboard.customers.update}
              params={crudFormParams(messages).update}
              backBtn={backBtn}
            />
          </CrudTab>
        </div>
      </LayoutTable>
    </LayoutDashboard>
  );
};

export default Customers;
