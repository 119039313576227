import * as constants from 'store/constants/auth';
import * as actions from 'store/actions';
import apiCallWrapper from 'utils/apiCallWrapper';
import {
  apiConfirmResetPassword,
  apiResetPassword,
  apiSignOut,
  apiSingIn,
} from 'services/dataFetching/auth';
import { IUserProfileData } from '../../appTypes';

const autoSignIn = async (dispatch: any) => {
  const userProfileData = sessionStorage.getItem('userProfileData');
  if (userProfileData) {
    dispatch(actions.authOnTryAutoSignInFinish());
    dispatch(
      actions.authSetUserProfileData(
        (JSON.parse(userProfileData) as unknown) as IUserProfileData,
      ),
    );
  } else {
    dispatch(actions.authOnTryAutoSignInFail());
  }
};

const signOut = async (dispatch: any) => {
  sessionStorage.clear();
  dispatch(actions.authSetSignOutTrue());
  setTimeout(() => {
    dispatch(actions.globalCleanUp());
  }, 500);
  apiSignOut();
};

const reducer = ({ dispatch }: any) => (next: any) => (action: any) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case constants.AUTH_ON_TRY_AUTO_SIGN_IN:
      autoSignIn(dispatch).then(r => r);
      break;
    case constants.AUTH_ON_SIGN_OUT:
      signOut(dispatch).then(r => r);
      break;
    case constants.AUTH_ON_RESET_PASSWORD:
      apiCallWrapper({
        onStart: () => dispatch(actions.authSetIsProcessing(true)),
        onFinish: () => dispatch(actions.authSetIsProcessing(false)),
        onFail: (error: any) => dispatch(actions.authSetError(error)),
        apiCall: () => apiResetPassword(action.payload.data),
        own: action.payload.own,
        systemLogin: true,
        dispatch,
      });
      break;
    case constants.AUTH_ON_CONFIRM_RESET_PASSWORD:
      apiCallWrapper({
        onStart: () => dispatch(actions.authSetIsProcessing(true)),
        onFinish: () => dispatch(actions.authSetIsProcessing(false)),
        onFail: (error: any) => dispatch(actions.authSetError(error)),
        apiCall: () => apiConfirmResetPassword(action.payload.data),
        own: action.payload.own,
        systemLogin: true,
        dispatch,
      });
      break;
    case constants.AUTH_ON_SIGN_IN:
      apiCallWrapper({
        onStart: () => dispatch(actions.authSetIsProcessing(true)),
        onFinish: () => {
          sessionStorage.setItem(
            'userProfileData',
            JSON.stringify({ email: 'admin' }),
          );
          dispatch(actions.authSetUserProfileData({ email: 'admin' }));
        },
        onFail: (error: any) => dispatch(actions.authSetError(error)),
        apiCall: () => apiSingIn(action.payload.data),
        own: action.payload.own,
        csrfToken: true,
        dispatch,
      });
      break;
    default:
      break;
  }

  return next(action);
};

export default reducer;
