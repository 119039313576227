import React from 'react';
import { IFormSingleFieldParams, TInputField } from '../../types';

const CompanySectorSelect = ({
  meta,
  className,
  label,
  id,
  initValue,
  ...rest
}: TInputField & IFormSingleFieldParams) => {
  return (
    <div className={className}>
      <label htmlFor={id} className="select-label">
        {label}
      </label>
      <select id={id} defaultValue="label" {...rest}>
        <option value="label" disabled>
          {label}
        </option>
        <option value="Retailer">Retailer</option>
        <option value="Mortgage Lender">Mortgage Lender</option>
        <option value="Retail Finance Lender"> Retail Finance Lender</option>
        <option value="Other Lender">Other Lender</option>
        <option value="Mortgage Broker">Mortgage Broker</option>
        <option value="Retail Finance Broker">Retail Finance Broker</option>
        <option value="Other Broker">Other Broker</option>
        <option value="Online Gambling"> Online Gambling</option>
        <option value="Rental Agency">Rental Agency</option>
        <option value="Employment">Employment</option>
        <option value="Government">Government</option>
        <option value="Utilities">Utilities</option>
        <option value="Insurance">Insurance</option>
        <option value="Other">Other</option>
      </select>
      {meta && meta.error ? (
        <div className="helper-text" id={`${id}-helper-text`}>
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export default CompanySectorSelect;
