import React from 'react';
import { ContextMessages } from './context';
import { getMessagesByLocale } from './utils';
import { TLanguages, TMsg } from 'appTypes/messages';

type TMessageProvider = {
  messages: { [key in TLanguages]: TMsg };
  locale: TLanguages;
  children: React.ReactElement;
};

const MessagesProvider: React.FC<TMessageProvider> = ({
  messages: argMessage,
  locale,
  children,
}) => {
  const messages = getMessagesByLocale(argMessage, locale);

  return (
    <ContextMessages.Provider value={messages}>
      {children}
    </ContextMessages.Provider>
  );
};

export default MessagesProvider;
