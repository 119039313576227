import { applyMiddleware, compose } from 'redux';

export const composeMiddleware = (middleware: Array<any>) => {
  const composeEnhancers =
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return composeEnhancers(
    applyMiddleware(...middleware.filter((m: any): boolean => !!m)),
  );
};

export default {
  composeMiddleware,
};
