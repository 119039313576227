import _ from 'lodash';
import IntlMessageFormat from 'intl-messageformat';
import { TLanguages, TMsg } from 'appTypes/messages';

export type TGetMsgByPath = (
  messages: TMsg,
  path: string[],
) => IntlMessageFormat;

export const getMsgByPath: TGetMsgByPath = (messages, path) => {
  if (_.has(messages, path)) {
    return new IntlMessageFormat(_.get(messages, path));
  }
  return new IntlMessageFormat('');
};

type TGetMessages = (
  messages: { [key in TLanguages]: TMsg },
  locale: TLanguages,
) => TMsg;

export const getMessagesByLocale: TGetMessages = (messages, locale = 'en') => {
  return messages[locale];
};

export default {
  getMsgByPath,
  getMessagesByLocale,
};
