import React from 'react';
import styled from 'styled-components';
import Button from 'components/ui/Button';
import ButtonSubmit from 'components/ui/ButtonSubmit';
import Modal from 'components/ui/Modal';
import clsx from 'clsx';

const ModalDeleteConfirmDialogWrapper: React.FC<{
  className?: string;
  action: any;
  setIsOpen: any;
}> = ({ className, action, setIsOpen }) => (
  <div className={clsx(className, 'modal_deleteConfirmDialog')}>
    <div className="wrapper_text">Are you sure?</div>
    <div className="wrapper_buttons">
      <Button
        variant="third"
        onClick={() => {
          action();
          setIsOpen(false);
        }}
      >
        Yes
      </Button>
      <Button onClick={() => setIsOpen(false)}>No</Button>
    </div>
  </div>
);

const ModalDeleteConfirmDialogWrapperStyled = styled(
  ModalDeleteConfirmDialogWrapper,
)`
  .wrapper_text {
    padding: 2rem 0;
    font-size: 20px;
    font-weight: 500;
    text-align: justify;
  }

  .wrapper_buttons {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
  }
`;

const DeleteConfirmDialog: React.FC<{
  className?: string;
  action: any;
}> = ({ className, action }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return (
    <div className={clsx(className, 'DeleteConfirmDialog')}>
      <ButtonSubmit
        onClick={() => setIsOpen(true)}
        variant="third"
        type="button"
      >
        Delete
      </ButtonSubmit>
      {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
          <ModalDeleteConfirmDialogWrapperStyled
            setIsOpen={setIsOpen}
            action={action}
          />
        </Modal>
      )}
    </div>
  );
};

export default DeleteConfirmDialog;
