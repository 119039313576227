import React from 'react';
import config from 'configShared';

export type TRouteCompProps = {
  title?: string;
  className?: string;
};

/** Desc.: Set current page (route) title, using route's props */
export const useRouteDefParams = ({ title }: TRouteCompProps) => {
  React.useEffect(() => {
    document.title = title
      ? `${title} • ${config.siteMainTitle}`
      : config.siteMainTitle;
  }, [title]);
};

export default useRouteDefParams;
