import React from 'react';
import clsx from 'clsx';

const ToastFail = ({ className }: { className?: string }) => {
  return (
    <div className={clsx(className, 'assets_svg', 'toastSuccess', '-dark')}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="20" fill="black" fillOpacity="0.1" />
        <path
          d="M24.8011 15.1989C25.0663 15.464 25.0663 15.8939 24.8011 16.1591L20.9602 20L24.8011 23.8409C25.0663 24.1061 25.0663 24.536 24.8011 24.8011C24.536 25.0663 24.1061 25.0663 23.8409 24.8011L20 20.9602L16.1591 24.8011C15.8939 25.0663 15.464 25.0663 15.1989 24.8011C14.9337 24.536 14.9337 24.1061 15.1989 23.8409L19.0398 20L15.1989 16.1591C14.9337 15.8939 14.9337 15.464 15.1989 15.1989C15.464 14.9337 15.8939 14.9337 16.1591 15.1989L20 19.0398L23.8409 15.1989C24.1061 14.9337 24.536 14.9337 24.8011 15.1989Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default ToastFail;
