import React from 'react';
import shortid from 'shortid';
import { IFormSingleFieldParams } from '../types';
import TextField from '../fields/TextField';
import PasswordField from '../fields/PasswordField';
import CompanySectorSelect from '../fields/CompanySectorSelect';
import CompanyEmployeesNumberSelect from '../fields/CompanyEmployeesNumberSelect';
import StipeCurrencySelect from '../fields/StipeCurrencySelect';
import PricingPlansSelect from '../fields/PricingPlansSelect';
import FieldPropsHandler from './FieldPropsHandler';
import PhoneNumberField from '../fields/PhoneNumberField';

const renderFieldsByType = ({
  name,
  wrapper: Wrapper = ({ children }: { children: any }) => <>{children}</>,
  ...rest
}: IFormSingleFieldParams) => {
  switch (name) {
    case 'passwordMatch':
    case 'password':
      return (
        <Wrapper key={shortid.generate()}>
          <FieldPropsHandler name={name} input={PasswordField} {...rest} />
        </Wrapper>
      );
    case 'companySector':
      return (
        <Wrapper key={shortid.generate()}>
          <FieldPropsHandler
            name={name}
            input={CompanySectorSelect}
            {...rest}
          />
        </Wrapper>
      );
    case 'companyEmployeesNumber':
      return (
        <Wrapper key={shortid.generate()}>
          <FieldPropsHandler
            name={name}
            input={CompanyEmployeesNumberSelect}
            {...rest}
          />
        </Wrapper>
      );
    case 'pricingPlan':
      return (
        <Wrapper key={shortid.generate()}>
          <FieldPropsHandler
            name={name}
            input={PricingPlansSelect}
            {...rest}
          />
        </Wrapper>
      )
    case 'phone':
      return (
        <Wrapper key={shortid.generate()}>
          <FieldPropsHandler name={name} input={PhoneNumberField} {...rest} />
        </Wrapper>
      );
    case 'companyName':
    case 'companyNumber':
    case 'companyPostcode':
    case 'companyBuilding':
    case 'first_name':
    case 'firstName':
    case 'last_name':
    case 'lastName':
    case 'id':
    case 'pricingPlanId':
    case 'title':
    case 'amount':
    case 'credits':
    case 'simple_password':
    case 'email':
    case 'text':
      return (
        <Wrapper key={shortid.generate()}>
          <FieldPropsHandler name={name} input={TextField} {...rest} />
        </Wrapper>
      );
    case 'currency':
      return (
        <Wrapper key={shortid.generate()}>
          <FieldPropsHandler
            name={name}
            input={StipeCurrencySelect}
            {...rest}
          />
        </Wrapper>
      );
    case 'empty':
      return (
        <Wrapper key={shortid.generate()}>
          <div />
        </Wrapper>
      )
    default:
      return null;
  }
};

export default renderFieldsByType;
