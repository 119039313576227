import React from 'react';
import { useSelector } from 'react-redux';
import { constants } from 'configShared/store';
import apiCallWrapper from 'utils/apiCallWrapper';
import { apiPricingPlanRead } from 'services/dataFetching/crud';
import { IFormSingleFieldParams, TInputField } from '../../types';

const reducerId = constants.PRICING_PLANS;

const PricingPlansSelect = ({
  meta,
  className,
  label,
  id,
  initValue,
  ...rest
}: TInputField & IFormSingleFieldParams) => {
  const [data, setData] = React.useState([]);
  const pricingPlans = useSelector((state: any) => state[reducerId].table.data);

  React.useEffect(() => {
    if (!pricingPlans.length) {
      apiCallWrapper({
        apiCall: () => apiPricingPlanRead(),
        onFinish: resp => {
          setData(resp.body.pricing_plans);
        },
      });
    } else {
      setData(pricingPlans);
    }
  }, [pricingPlans]);

  return (
    <div className={className}>
      <label htmlFor={id} className="select-label">
        {label}
      </label>
      <select id={id} defaultValue="" {...rest} disabled={!data.length}>
        <option value="label" disabled>
          {label}
        </option>
        {data.map((item: any) => (
          <option key={item.id} value={item.id}>
            {item.title}
          </option>
        ))}
      </select>
      {meta && meta.error ? (
        <div className="helper-text" id={`${id}-helper-text`}>
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export default PricingPlansSelect;
