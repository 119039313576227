import {
  apiPricingPlanPackageAdd,
  apiPricingPlanPackageDelete,
  apiPricingPlanPackageUpdate,
} from 'services/dataFetching/crud';
import {
  notSoSimpleFormConstructor,
  simpleFormConstructor,
} from 'components/forms/Form';
import { TCrudFormParams } from 'components/forms/CrudForm/types';
import { TMsg } from 'appTypes/messages';

export const crudValuesMapper = (values: any) => {
  const { id, pricing_plan_id, credits, amount, currency } = values;

  type TRet = {
    id: string;
    pricingPlanId: string;
    credits: number;
    amount: number;
    currency: string;
  };

  const ret: TRet = {
    id,
    pricingPlanId: pricing_plan_id,
    credits: parseInt(credits, 0),
    amount: parseInt(amount, 0),
    currency,
  };

  return ret;
};

export const crudApiCall = {
  add: (id: string) => ({
    apiCall: (values: any) =>
      apiPricingPlanPackageAdd(crudValuesMapper(values), id),
  }),
  delete: (id: string) => ({
    apiCall: (values: any) => apiPricingPlanPackageDelete(id, values),
  }),
  update: (id: string) => ({
    apiCall: (values: any) =>
      apiPricingPlanPackageUpdate(crudValuesMapper(values), id),
  }),
};

export const crudFormParams = (messages: TMsg): TCrudFormParams => ({
  update: notSoSimpleFormConstructor({
    messages,
    fields: ['credits', 'amount', 'currency'],
  }),
  add: simpleFormConstructor({
    messages,
    fields: ['credits', 'amount', 'currency'],
  }),
});

export const crudInitialValuesMapper = (initialValues: any) => {
  const { id, pricing_plan_id, credits, amount, currency } = initialValues;

  return {
    id,
    pricingPlanId: pricing_plan_id,
    credits,
    amount,
    currency,
  };
};
