import Table from './Table';
import styled from 'styled-components';

const getColumnSize = (columns: any) => {
  const columnSizes = [];

  for (let i = 0; i < columns.length; i += 1) {
    columnSizes.push((columns[i] && columns[i].size) || 'var(--col-def-width)');
  }

  return columnSizes;
};

const TableStyled = styled(Table)`
  .thead {
    .tr {
      display: grid;
      grid-template-columns: ${props => getColumnSize(props.columns).join(' ')};
    }
  }
  .tbody {
    .tr {
      display: grid;
      grid-template-columns: ${props => getColumnSize(props.columns).join(' ')};
    }
  }
`;

export default TableStyled;
