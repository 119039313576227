import { TColumn } from 'components/tables/Table/types';
import { apiPricingPlanRead } from 'services/dataFetching/crud';

export const columns: Array<TColumn> = [
  { Header: 'Pricing plan name', accessor: 'title' },
];

export const tableApiCall = (dispatch?: any) => ({
  apiCall: () =>
    apiPricingPlanRead().then((r: any) => ({
      data: r.body.pricing_plans,
    })),
});
