import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { IFormSingleFieldParams, TInputField } from '../../types';

const PhoneNumberField = ({
  id,
  type,
  value,
  onChange,
  onBlur,
  name,
  label,
  meta,
  className,
  placeholder,
  isDisabled,
}: TInputField & IFormSingleFieldParams) => {
  return (
    <div className={clsx(className, 'TextField')}>
      <label htmlFor={id}>{label}</label>
      <InputMask
        mask="+44 99999 999 9999"
        maskChar={null}
        onBlur={onBlur}
        onChange={onChange}
        value={(value as unknown) as string}
      >
        {() => (
          <input
            type={type}
            disabled={isDisabled}
            aria-describedby={`${id}-helper-text`}
            className={clsx('text-input', 'component_TextField')}
            id={id}
            name={name}
            placeholder={(!label && placeholder) || ''}
          />
        )}
      </InputMask>
      {meta && meta.touched && meta.error ? (
        <div className="helper-text" id={`${id}-helper-text`}>
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

const PhoneNumberFieldStyled = styled(PhoneNumberField)`
  visibility: ${props => (props.isHidden ? 'hidden' : 'visible')};
`;

export default PhoneNumberFieldStyled;
