import * as constants from './constants';
import defInitState from '../initState';

const reducer = (initState: any = defInitState) => (
  state: any = initState,
  action: any,
) => {
  switch (action.type) {
    case constants.CRUD_FORM_ON_RESET:
      return {
        ...initState,
      };
    case constants.CURD_FORM_TOGGLE_IS_PROCESSING:
      return {
        ...state,
        isProcessing: !state.isProcessing || action.isProcessing,
      };
    case constants.CURD_FORM_ON_SUBMIT_DATA_START:
      return {
        ...state,
        isProcessing: true,
      };
    case constants.CURD_FORM_ON_SUBMIT_DATA_FINISH:
      return {
        ...state,
        isProcessing: false,
      };
    case constants.CURD_FORM_ON_SUBMIT_DATA_FAIL:
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    case constants.CURD_FORM_SET_MODE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
