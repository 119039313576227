import React from 'react';
import Container from 'components/ui/Container';
import Paper from 'components/ui/Paper';
import SvgLogoDarkAdmin from 'assets/svg/logo/LogoDarkAdmin';
import clsx from 'clsx';
import H from 'components/typography/H';
import Link from 'components/ui/Link';
import config from 'configShared';

type TLayoutAuthForms = {
  pageTitle?: string;
  className?: string;
  children: React.ReactElement | React.ReactElement[];
};

const LayoutAuthForms: React.FC<TLayoutAuthForms> = ({
  className,
  children,
  pageTitle,
}) => {
  return (
    <div className={clsx(className, 'component_layout_AuthForms')}>
      <div className="grid_wrapper">
        <div className="header"></div>
        <div className="main">
          <Container maxWidth="540px">
            <Link href={config.routes.auth.signIn}>
              <SvgLogoDarkAdmin />
            </Link>
            <Paper shadowDeep={0}>
              <div className="auth_wrapper">
                <H>{pageTitle}</H>
                {children}
              </div>
            </Paper>
          </Container>
        </div>
        <div className="footer"></div>
      </div>
    </div>
  );
};

export default LayoutAuthForms;
