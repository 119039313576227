import { css } from 'styled-components';

const shadows = {
  deepLevel: [
    css`
      box-shadow: 0 10px 50px rgba(0, 0, 0, 0.2);
    `,
  ],
  tableBorder: {
    borderWhole: (width: string, color: string) => css`
      box-shadow: ${width} 0 0 0 ${color}, 0 ${width} 0 0 ${color},
        ${width} ${width} 0 0 ${color}, ${width} 0 0 0 ${color} inset,
        0 ${width} 0 0 ${color} inset;
    `,
    borderLeft: (width: string, color: string) =>
      css`
        box-shadow: inset ${width} 0 0 ${color};
      `,
    borderTop: (width: string, color: string) =>
      css`
        box-shadow: inset 0 ${width} 0 0 ${color};
      `,
    borderRight: (width: string, color: string) =>
      css`
        box-shadow: inset -${width} 0 0 0 ${color};
      `,
    borderBottom: (width: string, color: string) =>
      css`
        box-shadow: inset 0 -${width} 0 0 ${color};
      `,
    border: (width: string, color: string) =>
      css`
        box-shadow: inset 0 0 0 -${width} ${color};
      `,
  },
};

export default shadows;
