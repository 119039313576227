import React from 'react';
import { useSelector } from 'react-redux';
import { TAppState } from 'appTypes';
import config from 'configShared';
import clsx from 'clsx';
import ToastMessage from '../ToastMessage';

type TToast = {
  className?: string;
};

const Toast: React.FC<TToast> = ({ className }) => {
  const messages = useSelector((state: TAppState) => state.toast.messages);

  return (
    <div className={clsx('toast_wrapper', className)}>
      {messages.map(m => {
        const finalTimeout =
          m.timeout ||
          (m.redirect
            ? config.defToastRedirectTimeout
            : config.defToastTimeout);

        return (
          <ToastMessage
            msgId={m.id}
            key={m.id}
            type={m.type}
            code={m.code}
            text={m.text}
            msg={m.msg}
            debug={m.debug}
            redirect={m.redirect}
            timeout={finalTimeout - 250}
          />
        );
      })}
    </div>
  );
};

export default Toast;
