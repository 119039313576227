import React from 'react';
import clsx from 'clsx';

const RoundTrianlge = ({ className }: { className?: string }) => {
  return (
    <div className={clsx(className, 'assets_svg', 'roundTriangle')}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="9" cy="9" r="9" fill="#5ABDD7" />
        <path d="M10 6L6 9L10 12L10 6Z" fill="white" />
      </svg>
    </div>
  );
};

export default RoundTrianlge;
