export const GLOBAL_CLEAN_UP = 'GLOBAL_CLEAN_UP';
export type GLOBAL_CLEAN_UP = typeof GLOBAL_CLEAN_UP;

/** Reducer */
export const TABLE_SET_STATUS = 'TABLE_SET_STATUS';
export type TABLE_SET_STATUS = typeof TABLE_SET_STATUS;

export const TABLE_SET_PERIOD = 'TABLE_SET_PERIOD';
export type TABLE_SET_PERIOD = typeof TABLE_SET_PERIOD;

export const TABLE_SET_DATA = 'TABLE_SET_DATA';
export type TABLE_SET_DATA = typeof TABLE_SET_DATA;

export const TABLE_SET_SEARCH = 'TABLE_SET_SEARCH';
export type TABLE_SET_SEARCH = typeof TABLE_SET_SEARCH;

export const TABLE_SET_SEARCH_CUSTOMER = 'TABLE_SET_SEARCH_CUSTOMER';
export type TABLE_SET_SEARCH_CUSTOMER = typeof TABLE_SET_SEARCH_CUSTOMER;

export const TABLE_SET_SEARCH_PSU = 'TABLE_SET_SEARCH_PSU';
export type TABLE_SET_SEARCH_PSU = typeof TABLE_SET_SEARCH_PSU;

export const TABLE_SET_SORT = 'TABLE_SET_SORT';
export type TABLE_SET_SORT = typeof TABLE_SET_SORT;

export const TABLE_SET_IS_PROCESSING = 'TABLE_SET_IS_PROCESSING';
export type TABLE_SET_IS_PROCESSING = typeof TABLE_SET_IS_PROCESSING;

export const TABLE_TOGGLE_IS_PROCESSING = 'TABLE_TOGGLE_IS_PROCESSING';
export type TABLE_TOGGLE_IS_PROCESSING = typeof TABLE_TOGGLE_IS_PROCESSING;

export const TABLE_SET_OFFSET = 'TABLE_SET_OFFSET';
export type TABLE_SET_OFFSET = typeof TABLE_SET_OFFSET;

export const TABLE_FORCE_UPDATE = 'TABLE_FORCE_UPDATE';
export type TABLE_FORCE_UPDATE = typeof TABLE_FORCE_UPDATE;

export const TABLE_ON_RESET = 'TABLE_ON_RESET';
export type TABLE_ON_RESET = typeof TABLE_ON_RESET;

/** Middleware */

export const TABLE_ON_DATA_FETCH = 'TABLE_ON_DATA_FETCH';
export type TABLE_ON_DATA_FETCH = typeof TABLE_ON_DATA_FETCH;

export const TABLE_ON_DATA_FETCH_START = 'TABLE_ON_DATA_FETCH_START';
export type TABLE_ON_DATA_FETCH_START = typeof TABLE_ON_DATA_FETCH_START;

export const TABLE_ON_DATA_FETCH_FAIL = 'TABLE_ON_DATA_FETCH_FAIL';
export type TABLE_ON_DATA_FETCH_FAIL = typeof TABLE_ON_DATA_FETCH_FAIL;

export const TABLE_ON_DATA_FETCH_FINISH = 'TABLE_ON_DATA_FETCH_FINISH';
export type TABLE_ON_DATA_FETCH_FINISH = typeof TABLE_ON_DATA_FETCH_FINISH;
