import { combineReducers } from 'redux';
import {
  reducerApplyFilters,
  reducerFilterByReducerId,
  reducerFilterOnGlobalReset,
  reducerPass,
} from 'utils/reducer';

import authReducer, {
  defInitState as authInitState,
} from 'store/reducers/auth';
import toastReducer from 'components/ui/Toast/reducer/reducer';
import tableReducer from 'components/tables/Table/reducer/reducer';
import tableInitState from 'components/tables/Table/initState';
import crudFormReducer from 'components/forms/CrudForm/reducer/reducer';
import crudFormInitState from 'components/forms/CrudForm/initState';
import identityCheckResultsReducer, {
  defInitState as resultsInitState,
} from 'store/reducers/identityCheckResults';

import tableMiddleware from 'components/tables/Table/reducer/middleware';
import authMiddleware from 'store/middleware/auth';
import toastMiddleware from 'components/ui/Toast/reducer/middleware';
import identityCheckResultsMiddleware from 'store/middleware/identityCheckResults';
import crudFormMiddleware from 'components/forms/CrudForm/reducer/middleware';

import * as actionConstants from 'store/constants';

export const constants = {
  CONSENTS: 'CONSENTS',
  CONSENTS_TRANSACTIONS: 'CONSENTS_TRANSACTIONS',
  ADMINS: 'ADMINS',
  PSUS: 'PSUS',
  CUSTOMERS: 'CUSTOMERS',
  PRICING_PLANS: 'PRICING_PLANS',
  PRICING_PLANS_PACKAGES: 'PRICING_PLANS_PACKAGES',
};
export const middleware = [
  authMiddleware,
  toastMiddleware,
  tableMiddleware,
  crudFormMiddleware,
  identityCheckResultsMiddleware,
];

const consentsInitState = {
  ...tableInitState,
  sortBy: 'requested',
  sortOrder: true,
};

const transactionsInitState = {
  ...tableInitState,
  sortBy: 'value_date_time_parsed',
  sortOrder: true,
}

const finalReducerFilterOnGlobalReset = reducerFilterOnGlobalReset(
  actionConstants.GLOBAL_CLEAN_UP,
);

const authFinalReducer = reducerApplyFilters(
  authReducer,
  [finalReducerFilterOnGlobalReset, reducerPass],
  authInitState,
);

const consentsTableFinalReducer = reducerApplyFilters(
  tableReducer,
  [
    reducerFilterByReducerId(constants.CONSENTS),
    finalReducerFilterOnGlobalReset,
  ],
  consentsInitState,
);

const consentsResultsFinalReducer = reducerApplyFilters(
  identityCheckResultsReducer,
  [finalReducerFilterOnGlobalReset, reducerPass],
  resultsInitState,
);

const consentsTransactionsFinalReducer = reducerApplyFilters(
  tableReducer,
  [
    reducerFilterByReducerId(constants.CONSENTS_TRANSACTIONS),
    finalReducerFilterOnGlobalReset,
  ],
  transactionsInitState,
);

const getCombinedCrudReducers = (title: string) =>
  combineReducers({
    table: reducerApplyFilters(
      tableReducer,
      [finalReducerFilterOnGlobalReset, reducerFilterByReducerId(title)],
      tableInitState,
    ),
    crud: reducerApplyFilters(
      crudFormReducer,
      [finalReducerFilterOnGlobalReset, reducerFilterByReducerId(title)],
      crudFormInitState,
    ),
  });

export const reducers = {
  auth: authFinalReducer,
  toast: toastReducer(),
  [constants.CONSENTS]: combineReducers({
    table: consentsTableFinalReducer,
    identityCheckResults: consentsResultsFinalReducer,
    [constants.CONSENTS_TRANSACTIONS]: consentsTransactionsFinalReducer,
  }),
  [constants.ADMINS]: getCombinedCrudReducers(constants.ADMINS),
  [constants.PSUS]: getCombinedCrudReducers(constants.PSUS),
  [constants.CUSTOMERS]: getCombinedCrudReducers(constants.CUSTOMERS),
  [constants.PRICING_PLANS]: getCombinedCrudReducers(constants.PRICING_PLANS),
  [constants.PRICING_PLANS_PACKAGES]: getCombinedCrudReducers(constants.PRICING_PLANS_PACKAGES),
};

export default { middleware, reducers };
