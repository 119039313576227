import React from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { toastMessageRemove } from 'store/actions';
import SvgIconsToastSuccess from 'assets/svg/icons/ToastSuccess';
import SvgIconsToastFail from 'assets/svg/icons/ToastFail';

export type TToastMessageProps = {
  className?: string;
  msgId: string;
  debug?: boolean;
  type?: string;
  redirect?: string;
  timeout?: number;
  code?: number;
  text?: string;
  msg?: string;
};

const ToastMessage: React.FC<TToastMessageProps> = props => {
  const [animation, setAnimation] = React.useState<string>('');
  const {
    className,
    debug,
    type,
    timeout = 1000,
    code,
    text,
    msg,
    msgId: id,
  } = props;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let tId = 0;

    if (!debug) {
      if (!animation) {
        setAnimation('--slideInUp');
      } else {
        tId = setTimeout(() => {
          setAnimation('--slideOutUp');
          setTimeout(() => {
            dispatch(toastMessageRemove(id));
          }, 500);
        }, timeout - 200);
      }
    }

    return () => {
      clearTimeout(tId);
    };
  }, [animation, setAnimation, debug, timeout, dispatch, id]);

  return (
    <div className={clsx('toast_message', className, animation)}>
      <div
        tabIndex={-1}
        role="button"
        onKeyPress={e => {
          setAnimation('--slideOutUp');
          e.stopPropagation();
        }}
        key="one"
        className="svgIcon_wrapper"
        onClick={e => {
          setAnimation('--slideOutUp');
          setTimeout(() => {
            dispatch(toastMessageRemove(id));
          }, 500);
          e.stopPropagation();
        }}
      >
        {type === 'success' ? <SvgIconsToastSuccess /> : <SvgIconsToastFail />}
      </div>
      <div key="two">
        {code && <span className="msgCode">{code}</span>}
        {text || msg}
      </div>
      <div key="three"></div>
    </div>
  );
};

export default ToastMessage;
