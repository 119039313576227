import { get, getParsedBody, post } from '../../utils/fetch';
import config, { endpoints } from '../../configShared';

/**
 * CONSENTS
 * ========
 */

export const apiConsents = async (body: any) => {
  return post(
    [config.fetchOptions.url, endpoints.consents].join('/'),
    body,
  ).then(getParsedBody);
};

/**
 * CONSENTS - TRANSACTIONS
 * =======================
 *
 * ```
 * {
 *   "consent_id":"", #mandatory
 *   "sort_by":["value_date_time_parsed"|"amount_parsed"]
 *   "sort_order":[true|false], #false: a..z, true: z..a
 *   "from":0, #unix time in seconds and nsec nanoseconds since January 1, 1970 UTC
 *   "till":0,
 *   "limit": (1..20],
 *   "offset": 0
 * }
 * ```
 */
export const apiConsentsTransactions = async (body: any) => {
  return post(
    [config.fetchOptions.url, endpoints.consentsTransactions].join('/'),
    body,
  ).then(getParsedBody);
};

/**
 * CONSENTS - GENERAL
 * =======================
 *
 * GET /admin/consents/{consent-id}/general
 */
export const apiConsentsGeneral = async (consentId: string) => {
  return get(
    [config.fetchOptions.url, endpoints.consentsGeneral(consentId)].join('/'),
  ).then(getParsedBody);
};

/**
 * CONSENTS - SUMMARY
 * =======================
 *
 * GET /admin/consents/{id}/summary
 */
export const apiConsentsSummary = async (consentId: string) => {
  return get(
    [config.fetchOptions.url, endpoints.consentsSummary(consentId)].join('/'),
  ).then(getParsedBody);
};
