import React from 'react';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';

type TLink = {
  className?: string;
  handleOnClick?: any;
  href?: string;
  children: any;
};

const defaultHandleOnClick = (url?: string, history?: any) => () => {
  if (url && history) history.push(url, { renew: true });
  return null;
};

const Link: React.FC<TLink> = ({
  handleOnClick,
  className,
  children,
  href,
}) => {
  const location = useLocation();
  const history = useHistory();

  const onClick = handleOnClick || defaultHandleOnClick(href, history);

  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx(
        className,
        'component_ui_Link',
        location.pathname === href && 'active',
      )}
    >
      {children}
    </button>
  );
};

export default Link;
