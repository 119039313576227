import {
  apiAdminsAdd,
  apiAdminsDelete,
  apiAdminsUpdate,
} from 'services/dataFetching/crud';
import {
  notSoSimpleFormConstructor,
  simpleFormConstructor,
} from 'components/forms/Form';
import { TCrudFormParams } from 'components/forms/CrudForm/types';
import { TMsg } from 'appTypes/messages';

export const crudApiCall = {
  add: (dispatch?: any, messages?: any) => ({
    apiCall: (values: any) =>
      apiAdminsAdd({
        email: values.email,
        password: values.simple_password,
      }),
  }),
  delete: (dispatch?: any, messages?: any) => ({
    apiCall: apiAdminsDelete,
  }),
  update: (dispatch?: any, messages?: any) => ({
    apiCall: apiAdminsUpdate,
  }),
};

export const crudFormParams: TCrudFormParams = {
  update: (messages: TMsg) =>
    notSoSimpleFormConstructor({
      messages,
      fields: [{ name: 'email', isDisabled: true }, 'first_name', 'last_name'],
    }),
  add: (messages: TMsg) =>
    simpleFormConstructor({
      messages,
      fields: ['email', 'simple_password'],
    }),
};

export const crudInitialValuesMapper = (initialValues: any) => {
  const { id, email, last_name = '', first_name = '' } = initialValues;

  return {
    id,
    email,
    first_name,
    last_name,
  };
};
