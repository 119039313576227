import {
  apiPsusAdd,
  apiPsusDelete,
  apiPsusUpdate,
} from 'services/dataFetching/crud';
import {
  notSoSimpleFormConstructor,
  simpleFormConstructor,
} from 'components/forms/Form';
import { TCrudFormParams } from 'components/forms/CrudForm/types';
import { TMsg } from 'appTypes/messages';

export const crudValuesMapper = (values: any) => {
  const { id, email, firstName, lastName, phone } = values;

  type TRet = {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
  };

  const ret: TRet = {
    id,
    email,
    firstName,
    lastName,
    phone,
  };

  return ret;
};

export const crudApiCall = {
  add: (dispatch?: any, messages?: any) => ({
    apiCall: (values: any) => apiPsusAdd(crudValuesMapper(values)),
  }),
  delete: (dispatch?: any, messages?: any) => ({
    apiCall: apiPsusDelete,
  }),
  update: (dispatch?: any, messages?: any) => ({
    apiCall: (values: any) => apiPsusUpdate(crudValuesMapper(values)),
  }),
};

export const crudFormParams = (messages: TMsg): TCrudFormParams => ({
  update: notSoSimpleFormConstructor({
    messages,
    fields: [
      { name: 'email', isDisabled: true },
      'firstName',
      'lastName',
      'phone',
    ],
  }),
  add: simpleFormConstructor({
    messages,
    fields: ['email', 'firstName', 'lastName', 'phone'],
  }),
});

export const crudInitialValuesMapper = (initialValues: any) => {
  const { id, email, firstName, lastName, phone } = initialValues;

  return {
    id,
    email,
    firstName,
    lastName,
    phone,
  };
};
