import config from 'configShared';
import { TResp } from 'appTypes';

export interface THttpResponse<T> extends Response {
  parsedBody?: TResp<T>;
}

export const errorNormalize = <T>(resp: TResp<T>) => {
  const { status } = resp;
  if (status) {
    return {
      text: status.text,
      code: status.code,
    };
  }

  return resp;
};

export const getParsedBody = <T>(response: THttpResponse<TResp<T>>) => {
  return response.parsedBody;
};

export const http = <T>(
  request: RequestInfo,
): Promise<THttpResponse<TResp<T>>> => {
  return new Promise((resolve, reject) => {
    let response: THttpResponse<TResp<T>>;
    fetch(request)
      .then(res => {
        response = res;
        return res.json();
      })
      .then(body => {
        if (response.ok) {
          response.parsedBody = body;
          resolve(response);
        } else if (body && body.status) {
          reject(errorNormalize<T>(body));
        } else {
          reject(response);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const httpTimeout = <T>(
  request: RequestInfo,
): Promise<THttpResponse<TResp<T>>> => {
  return Promise.race([
    new Promise<THttpResponse<TResp<T>>>((_, reject) => {
      const response: any = {
        parsedBody: {
          status: { text: 'network_timeout_reached', code: 600 },
        },
      };
      setTimeout(() => reject(response), config.fetchOptions.timeout);
    }),
    http<T>(request),
  ]);
};

export const get = async <T>(
  path: string,
  args: RequestInit = { method: 'get', ...config.fetchOptions.method.get },
): Promise<THttpResponse<TResp<T>>> => {
  return httpTimeout<T>(new Request(path, args));
};

export const post = async <T, S>(
  path: string,
  body: S,
  args: RequestInit = {
    method: 'post',
    body: JSON.stringify(body),
    ...config.fetchOptions.method.post,
  },
): Promise<THttpResponse<TResp<T>>> => {
  return httpTimeout<T>(new Request(path, args));
};

export const put = async <T, S>(
  path: string,
  body: S,
  args: RequestInit = {
    method: 'put',
    ...config.fetchOptions.method.put,
    body: JSON.stringify(body),
  },
): Promise<THttpResponse<TResp<T>>> => {
  return http<T>(new Request(path, args));
};

export const del = async <T, S>(
  path: string,
  body?: S,
  args: RequestInit = {
    method: 'delete',
    body: JSON.stringify(body),
    ...config.fetchOptions.method.delete,
  },
): Promise<THttpResponse<TResp<T>>> => {
  return http<T>(new Request(path, args));
};

export default {
  http,
};
