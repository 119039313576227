import { TToastMessage } from 'appTypes/common';
import * as constants from './constants';

export function toastMessageAdd(msg: TToastMessage) {
  return {
    type: constants.TOAST_MESSAGE_ADD,
    msg,
  };
}

export function toastMessageAddSuccess(msg: TToastMessage) {
  return {
    type: constants.TOAST_MESSAGE_ADD_SUCCESS,
    msg,
  };
}

export function toastMessageAddSuccessRedirect(
  msg: TToastMessage,
  redirect: string,
  history: any,
) {
  return {
    type: constants.TOAST_MESSAGE_ADD_SUCCESS_REDIRECT,
    msg,
    redirect,
    history,
  };
}

export function toastMessageAddFail(msg: TToastMessage) {
  return {
    type: constants.TOAST_MESSAGE_ADD_FAIL,
    msg,
  };
}

export function toastMessageAddFailRedirect(
  msg: TToastMessage,
  redirect: string,
  history: any,
) {
  return {
    type: constants.TOAST_MESSAGE_ADD_FAIL_REDIRECT,
    msg,
    redirect,
    history,
  };
}

export function toastMessageRemove(id: string) {
  return {
    type: constants.TOAST_MESSAGE_REMOVE,
    id,
  };
}
