import React from 'react';
import { TColumn } from 'components/tables/Table/types';
import { apiCustomersRead } from 'services/dataFetching/crud';
import moment from 'moment';
import clsx from 'clsx';

export const columns: Array<TColumn> = [
  { Header: 'Email', accessor: 'email' },
  {
    Header: 'Full name',
    Cell: ({ row }) => {
      return (
        <>
          {row.first_name} {row.last_name}
        </>
      );
    },
  },
  {
    Header: 'Pricing plan',
    accessor: 'company',
    Cell: ({ value = {} }) => {
      const { pricing_plan_title } = value;
      return <>{pricing_plan_title || '—'}</>;
    },
  },
  {
    Header: 'Company',
    accessor: 'company',
    Cell: ({ value = {} }) => {
      const { name } = value;
      return <>{name || '—'}</>;
    },
  },
  {
    Header: 'Balance \n free/prepaid',
    Cell: ({ row }) => {
      return (
        <>
          {row.branch.free_balance || 0} / {row.branch.prepaid_balance || 0}
        </>
      );
    },
  },
  {
    Header: 'Created at',
    accessor: 'created_at',
    Cell: ({ value }) => {
      return <>{moment(value).format('YYYY/MM/DD HH:mm')}</>;
    },
  },
  {
    Header: 'Status',
    accessor: 'deleted_at',
    size: '80px',
    tdClassName: '-deletedAt',
    Cell: ({ value }) => {
      const isDeleted =
        moment(value).valueOf() > 0
          ? moment(value).format('DD/MM/YYYY HH:mm')
          : false;

      return (
        <div
          className={clsx('isDeleted', (isDeleted && '--deleted') || '--ok')}
        >
          {isDeleted ? '✖' : `✔`}
        </div>
      );
    },
  },
];

export const tableApiCall = (dispatch?: any) => ({
  apiCall: (body: any) =>
    apiCustomersRead(body).then((r: any) => ({
      data: r.body.customers,
      total: r.body.total,
    })),
});
