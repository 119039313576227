import { css } from 'styled-components';

const H = css`
  .h1 {
    font-weight: 700;
    font-size: 38px;
  }
  .h2 {
    font-weight: 400;
    font-size: 32px;
  }
`;
export default {
  H,
};
