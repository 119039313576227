import React from 'react';
import PreloaderTable from 'components/ui/PreloaderTable';
import clsx from 'clsx';
import * as actions from '../reducer/actions';
import * as globalActions from 'store/actions';
import {
  renderPagination,
  renderTableHeaders,
  renderTableRows,
} from '../utils';

import { TColumn } from '../types';

type TTable = {
  columns: Array<TColumn>;
  className?: string;
  dispatch: any;
  apiCallWrapper: any;
  state: any;
  handleOnRowClick?: any;
  viewDetails?: boolean;
  handleOnEdit?: any;
  params?: {
    lazyLoad?: boolean;
    isRowEditable?: (args?: any) => boolean;
  };
};

const useDataFetch = (apiCallWrapper: any, dispatch: any, state: any) => {
  const {
    offset,
    limit,
    sortBy,
    sortOrder,
    status,
    till,
    from,
    searchCustomer,
    searchPsu,
    updatedAt,
    error,
  } = state;

  React.useEffect(() => {
    const reqBody: any = {
      offset,
      limit,
      sort_by: sortBy,
      sort_order: sortOrder,
      status,
      till,
      from,
      cust_pattern: searchCustomer,
      psu_pattern: searchPsu,
    };

    if (!error) {
      dispatch(
        actions.tableOnDataFetch({
          onStart: () => {
            dispatch(actions.tableOnDataFetchStart());
          },
          apiCall: () => apiCallWrapper.apiCall(reqBody),
          onFinish: (resp: any) => {
            dispatch(actions.tableOnDataFetchFinish(resp));
            if (apiCallWrapper.onFinish) {
              apiCallWrapper.onFinish(resp);
            }
          },
          onFail: (e: any) => {
            if (typeof e === 'string') {
              dispatch(actions.tableOnDataFetchFail({ msg: e }));
            } else {
              dispatch(actions.tableOnDataFetchFail(e));
            }
            if (apiCallWrapper.onFail) {
              apiCallWrapper.onFail(e);
            }
          },
        }),
      );
    }

    if (error) {
      dispatch(globalActions.toastMessageAddFail({ ...error }));
    }
  }, [
    limit,
    error,
    dispatch,
    apiCallWrapper,
    offset,
    updatedAt,
    sortBy,
    sortOrder,
    searchCustomer,
    searchPsu,
    status,
    till,
    from,
  ]);
};

const Table: React.FC<TTable> = ({
  apiCallWrapper,
  columns,
  dispatch,
  state,
  className,
  handleOnRowClick,
  handleOnEdit,
  viewDetails,
  params = {
    isRowEditable: undefined,
    lazyLoad: false,
  },
}) => {
  const { lazyLoad, isRowEditable } = params;

  const { isProcessing, sortOrder, sortBy } = state;

  useDataFetch(apiCallWrapper, dispatch, state);

  return (
    <div className={clsx(className, 'rootTable')}>
      <div className="table_inner_wrapper">
        <div key="thead" className="thead">
          {renderTableHeaders(columns, dispatch, sortBy, sortOrder)}
        </div>
        <div className="table_tbody_wrapper">
          <div key="tbody" className="tbody">
            {renderTableRows(
              state.data,
              columns,
              dispatch,
              handleOnRowClick,
              handleOnEdit,
              viewDetails,
              isRowEditable,
            )}
          </div>
          <PreloaderTable isProcessing={isProcessing} />
        </div>
      </div>
      {renderPagination(state, dispatch, lazyLoad)}
    </div>
  );
};

export default Table;
