import React from 'react';
import General from './General';
import Summary from './Summary';
import Transactions from './Transactions/Transactions';
import Tabs, { Tab } from 'components/ui/Tabs';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { apiConsentsGeneral } from 'services/dataFetching/consents';
import * as actions from 'store/actions';
import { constants } from 'configShared/store';
import Preloader from 'components/ui/Preloader';
import { LayoutTableContext } from 'layout/Table/Table';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { expiredTimeFormat } from 'components/tables/Table/utils';

type TIdentityCheckResults = {
  className?: string;
};

const BankStyled = styled.div`
  font-weight: 800;
`;

const LabelStyled = styled.span`
  font-weight: 800;
  color: ${props => props.theme.colors.brand.blue};
`;

const IdentityCheckResults: React.FC<TIdentityCheckResults> = ({
  className,
}) => {
  const dispatch = useDispatch();
  const {
    general_error,
    general_isProcessing,
    general_data,
    consentId,
  } = useSelector(
    (state: any) => state[constants.CONSENTS].identityCheckResults,
  );

  const layoutTableContext = React.useContext(LayoutTableContext);
  const { consent, data } = general_data || {};
  const { psu_email, bank } = consent || {};
  const { expires_at } = data || {};

  const renderUserInfo = (
    <div className="row">
      <div className={'psuEmail'}>
        PSU <LabelStyled>{psu_email}</LabelStyled>
      </div>
      {expires_at && (
        <div className={'expiresAt'}>
          Consent expires in{' '}
          <LabelStyled>
            {expiredTimeFormat(Math.floor(expires_at / 60))}
          </LabelStyled>{' '}
          days
        </div>
      )}
    </div>
  );

  React.useEffect(() => {
    if (consentId && !general_error) {
      dispatch(
        actions.identityCheckResultsOnGetGeneral({
          onStart: () =>
            dispatch(actions.identityCheckResultsOnGetGeneralStart()),
          apiCall: () => apiConsentsGeneral(consentId).then((r: any) => r.body),
          onFinish: (resp: any) => {
            dispatch(actions.identityCheckResultsOnGetGeneralFinish(resp));
          },
          onFail: (error: any) => {
            dispatch(actions.identityCheckResultsOnGetGeneralFail(error));
          },
        }),
      );
    } else {
      dispatch(actions.toastMessageAddFail({ ...general_error }));
    }
  }, [consentId, general_error, dispatch]);

  return (
    <div className={clsx(className, 'page_identityCheckResults')}>
      <div className="identityCheckResults_topTabInfo_wrapper">
        {general_error || general_isProcessing ? '...' : renderUserInfo}
        {layoutTableContext.topRightPlaceholderRef.current &&
          ReactDOM.createPortal(
            <BankStyled>{bank}</BankStyled>,
            layoutTableContext.topRightPlaceholderRef.current,
          )}
      </div>
      <Tabs defTab="general">
        <Tab label="general">
          {general_error || general_isProcessing ? (
            <Preloader />
          ) : (
            <General generalData={general_data} />
          )}
        </Tab>
        <Tab label="summary">
          <Summary />
        </Tab>
        <Tab label="transactions">
          <Transactions />
        </Tab>
      </Tabs>
    </div>
  );
};

export default IdentityCheckResults;
