import React from 'react';
import { authOnSignIn } from 'store/actions/auth';
import validators from 'services/validators';
import FormForm, {
  getFormInitValuesFromFieldsParams,
  simpleFormConstructor,
} from 'components/forms/Form';
import LayoutAuthForms from 'layout/AuthForms';
import { ContextMessages } from 'services/messages/context';
import { TRouteCompProps } from 'components/routes';
import useRouteDefParams from 'utils/useRouteDefParams';
import Link from 'components/ui/Link';
import config from 'configShared';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessageFormat from 'intl-messageformat';
import { TAppState } from 'appTypes';
import * as actions from 'store/actions';

const SignIn: React.FC<TRouteCompProps> = ({ title }) => {
  useRouteDefParams({ title });
  const dispatch = useDispatch();
  const { isProcessing } = useSelector((state: TAppState) => state.auth);

  const messages = React.useContext(ContextMessages);

  const params = simpleFormConstructor({
    messages,
    fields: ['email', 'password'],
  });

  const msgSuccess = new IntlMessageFormat(
    messages.containers.auth.signIn.attemptSuccess,
  );
  const msgFail = new IntlMessageFormat(
    messages.containers.auth.signIn.attemptFail,
  );

  return (
    <LayoutAuthForms pageTitle={title} className="page_SignIn">
      <FormForm
        isProcessing={isProcessing}
        onSubmit={async (values, helpers) => {
          dispatch(
            authOnSignIn({
              data: {
                email: values.email,
                password: values.password,
              },
              own: {
                onFinish: (resp: any) => {
                  sessionStorage.setItem(
                    'userProfileData',
                    JSON.stringify(resp.body),
                  );
                  dispatch(actions.authSetUserProfileData(resp.body));
                  dispatch(
                    actions.toastMessageAddSuccess({
                      msg: msgSuccess.format(),
                    }),
                  );
                },
                onFail: (error: any) =>
                  dispatch(
                    actions.toastMessageAddFail({
                      msg: msgFail.format(),
                      ...error,
                    }),
                  ),
              },
            }),
          );
        }}
        validationSchema={validators(messages).auth.signIn}
        initialValues={getFormInitValuesFromFieldsParams(params)}
        params={params}
      />
      <Link
        className="textUnderTheAuthForm"
        href={config.routes.auth.resetPassword}
      >
        Forgot password
      </Link>
    </LayoutAuthForms>
  );
};

export default SignIn;
