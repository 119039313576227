import IdentityCheckResults from './IdentityCheckResults';
import styled from 'styled-components';

const IdentityCheckResultsStyled = styled(IdentityCheckResults)`
  position: relative;
  .identityCheckResults_topTabInfo_wrapper {
    min-height: 4rem;
    padding: 0 0.5rem;
    width: 100%;
    display: flex;
    align-items: center;

    .row {
      display: grid;
      grid-gap: 1rem;
      grid-auto-flow: column;
      width: max-content;
    }
  }
  .reportRowsWrapper {
    display: flex;
    flex-flow: column nowrap;

    .reportSingleRow {
      padding: 0.5rem 0.5rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      position: relative;
    }
  }
`;

export default IdentityCheckResultsStyled;
