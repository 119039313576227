import { useFormikContext } from 'formik';
import React from 'react';
import ButtonSubmit from 'components/ui/ButtonSubmit';

const ButtonsAdd: React.FC<{ isProcessing: boolean }> = ({ isProcessing }) => {
  const { isSubmitting } = useFormikContext();

  return (
    <div className="form_buttons_wrapper">
      <ButtonSubmit
        isProcessing={isProcessing || isSubmitting}
        variant="primary"
        type="submit"
      >
        Create
      </ButtonSubmit>
      <ButtonSubmit variant="secondary" type="reset">
        Reset
      </ButtonSubmit>
    </div>
  );
};

export default ButtonsAdd;
