import React from 'react';
import { middleware, reducers } from 'configShared/store';
import MessagesProvider from 'services/messages/MessagesProvider';
import messages from 'configShared/messages';
import ThemeProvider from 'services/theme/ThemeProvider';
import themes from 'configShared/theme';
import StoreProvider from 'services/store/StoreProvider';
import Router from 'containers/Router';
import clsx from 'clsx';

type TApp = {
  className?: string;
};

const App: React.FC<TApp> = ({ className }) => {
  return (
    <div className={clsx('rootApp', className)}>
      <StoreProvider middleware={middleware} reducers={reducers}>
        <MessagesProvider messages={messages} locale="en">
          <ThemeProvider themes={themes} variants="dark">
            <Router />
          </ThemeProvider>
        </MessagesProvider>
      </StoreProvider>
    </div>
  );
};

export default App;
