import { get, getParsedBody, post, del, put } from 'utils/fetch';
import config, { endpoints } from 'configShared';

/**
 * ADMIN
 * =====
 */

export const apiAdminsRead = async (body: any) => {
  return post([config.fetchOptions.url, endpoints.admins].join('/'), body).then(
    getParsedBody,
  );
};

export const apiAdminsAdd = async (body: any) => {
  return post(
    [config.fetchOptions.url, endpoints.adminAdd].join('/'),
    body,
  ).then(getParsedBody);
};

export const apiAdminsDelete = async (values: any) => {
  return post([config.fetchOptions.url, endpoints.adminDelete].join('/'), {
    id: values.id,
  }).then(getParsedBody);
};

export const apiAdminsUpdate = async (body: any) => {
  return post(
    [config.fetchOptions.url, endpoints.adminUpdate].join('/'),
    body,
  ).then(getParsedBody);
};

/**
 * PSU
 * ===
 */

export const apiPsusRead = async (body: any) => {
  return post([config.fetchOptions.url, endpoints.psus].join('/'), body).then(
    getParsedBody,
  );
};

export const apiPsusAdd = async (body: any) => {
  return post<any, any>(
    [config.fetchOptions.url, endpoints.psuAdd].join('/'),
    body,
  ).then(getParsedBody);
};

export const apiPsusDelete = async (values: any) => {
  return post<any, any>(
    [config.fetchOptions.url, endpoints.psuDelete].join('/'),
    {
      id: values.id,
    },
  ).then(getParsedBody);
};

export const apiPsusUpdate = async (body: any) => {
  return post<any, any>(
    [config.fetchOptions.url, endpoints.psuUpdate].join('/'),
    body,
  ).then(getParsedBody);
};

/**
 * CUSTOMER
 * ========
 */

export const apiCustomersRead = async (body: any) => {
  return post(
    [config.fetchOptions.url, endpoints.customers].join('/'),
    body,
  ).then(getParsedBody);
};

export const apiCustomersAdd = async (body: any) => {
  return post<any, any>(
    [config.fetchOptions.url, endpoints.customerAdd].join('/'),
    body,
  ).then(getParsedBody);
};

export const apiCustomersDelete = async (values: any) => {
  return post<any, any>(
    [config.fetchOptions.url, endpoints.customerDelete].join('/'),
    {
      id: values.id,
    },
  ).then(getParsedBody);
};

export const apiCustomersUpdate = async (body: any) => {
  await post(
    [config.fetchOptions.url, endpoints.companyPricingPlanUpdate].join('/'),
    {
      company_id: body.company.id,
      pricing_plan_id: body.company.pricing_plan_id,
    },
  ).then(getParsedBody);

  await post(
    [config.fetchOptions.url, endpoints.customerUpdate].join('/'),
    body,
  ).then(getParsedBody);
};

/**
 * PRICING PLAN
 * ========
 */

export const apiPricingPlanRead = async () => {
  return get([config.fetchOptions.url, endpoints.pricingPlans].join('/')).then(
    getParsedBody,
  );
};

export const apiPricingPlanAdd = async (body: any) => {
  return post<any, any>(
    [config.fetchOptions.url, endpoints.pricingPlanAdd].join('/'),
    body,
  ).then(getParsedBody);
};

export const apiPricingPlanDelete = async (values: any) => {
  return del<any, any>(
    [config.fetchOptions.url, endpoints.pricingPlanUpdate(values.id)].join('/'),
  ).then(getParsedBody);
};

export const apiPricingPlanUpdate = async (body: any) => {
  return put<any, any>(
    [config.fetchOptions.url, endpoints.pricingPlanUpdate(body.id)].join('/'),
    {
      title: body.title,
    },
  ).then(getParsedBody);
};


/**
 * PRICING PLANS PACKAGES
 * ========
 */

export const apiPricingPlansPackagesRead = async (id: string) => {
  return get([config.fetchOptions.url, endpoints.pricingPlansPackages(id)].join('/')
  ).then(getParsedBody);
};

export const apiPricingPlanPackageAdd = async (body: any, id: string) => {
  return post<any, any>(
    [config.fetchOptions.url, endpoints.pricingPlanPackage(id)].join('/'),
    body,
  ).then(getParsedBody);
};

export const apiPricingPlanPackageUpdate = async (body: any, id: string) => {
  return put<any, any>(
    [config.fetchOptions.url, endpoints.pricingPlanPackage(id)].join('/'),
    body,
  ).then(getParsedBody);
};

export const apiPricingPlanPackageDelete = async (id: string, values: any) => {
  return del<any, any>(
    [config.fetchOptions.url, endpoints.pricingPlanPackage(id)].join('/'),
    {
      id: values.id,
    },
  ).then(getParsedBody);
};
