import React from 'react';
import * as constants from '../constants/identityCheckResults';

/** TABS */
export function identityCheckResultsSetTab({
  tab,
  consentId,
  subTitle,
}: {
  tab?: string;
  consentId?: string;
  subTitle?: string | React.ReactNode;
}) {
  return {
    type: constants.IDENTITY_CHECK_RESULTS_SET_TAB,
    tab,
    consentId,
    subTitle,
  };
}

/** GENERAL */
export function identityCheckResultsOnGetGeneral(payload: any) {
  return {
    type: constants.IDENTITY_CHECK_RESULTS_ON_GET_GENERAL,
    payload,
  };
}

export function identityCheckResultsOnGetGeneralStart() {
  return {
    type: constants.IDENTITY_CHECK_RESULTS_ON_GET_GENERAL_START,
  };
}

export function identityCheckResultsOnGetGeneralFinish(payload: any) {
  return {
    type: constants.IDENTITY_CHECK_RESULTS_ON_GET_GENERAL_FINISH,
    payload,
  };
}

export function identityCheckResultsOnGetGeneralFail(error: any) {
  return {
    type: constants.IDENTITY_CHECK_RESULTS_ON_GET_GENERAL_FAIL,
    error,
  };
}

/** SUMMARY */
export function identityCheckResultsOnGetSummary(payload: any) {
  return {
    type: constants.IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY,
    payload,
  };
}

export function identityCheckResultsOnGetSummaryStart() {
  return {
    type: constants.IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY_START,
  };
}

export function identityCheckResultsOnGetSummaryFail(error: any) {
  return {
    type: constants.IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY_FAIL,
    error,
  };
}

export function identityCheckResultsOnGetSummaryFinish(payload: any) {
  return {
    type: constants.IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY_FINISH,
    payload,
  };
}

export function identityCheckResultsOnReset() {
  return {
    type: constants.IDENTITY_CHECK_RESULTS_ON_RESET,
  };
}
