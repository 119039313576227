import { TState } from './types';

const crudDialogInitState: TState = {
  isProcessing: false,
  error: null,
  tab: 'root',
  initValues: null,
};

export default crudDialogInitState;
