import React from 'react';
import { Redirect, Router as ReactRouter, Switch } from 'react-router-dom';
import RoutePrivate from 'components/routes/RoutePrivate';
import RoutePublic from 'components/routes/RoutePublic';
import SignIn from 'containers/auth/SignIn';
import ResetPassword from 'containers/auth/ResetPassword';
import ConfirmResetPassword from 'containers/auth/ConfirmResetPassword';
import Main from 'containers/dashboard/Main';
import Consents from 'containers/dashboard/Consents';
import Admins from 'containers/dashboard/Admins';
import Psus from 'containers/dashboard/Psus';
import Customers from 'containers/dashboard/Customers';
import PricingPlans from 'containers/dashboard/PricingPlans';
import PricingPlansPackages from 'containers/dashboard/PricingPlansPackages';
import history from 'utils/history';
import { useDispatch, useSelector } from 'react-redux';
import { TAppState } from 'appTypes';
import config from 'configShared';
import clsx from 'clsx';
import * as actions from 'store/actions';
import Toast from 'components/ui/Toast/Toast';

type TRouter = {
  className?: string;
};

const Router: React.FC<TRouter> = ({ className }) => {
  const dispatch = useDispatch();
  const email = useSelector((state: TAppState) => state.auth.email);
  const isSignOut = useSelector((state: TAppState) => state.auth.isSignOut);

  let isAuthSuccess = false;
  if (!isSignOut) {
    if (email !== null) isAuthSuccess = true;
  }

  React.useEffect(() => {
    if (!isAuthSuccess) {
      dispatch(actions.authOnTryAutoSignIn());
    }
  }, [isAuthSuccess, dispatch]);

  return (
    <div className={clsx(className, 'container_Router')}>
      <ReactRouter key="ReactRouter" history={history}>
        <Switch>
          <RoutePrivate
            exact
            component={Consents}
            redirectTo={config.defPublicRoute}
            path={config.routes.dashboard.consents}
            isAuthSuccess={isAuthSuccess}
            title="BankLoop"
          />
          <RoutePrivate
            exact
            component={Main}
            redirectTo={config.defPublicRoute}
            path={config.routes.dashboard.main}
            isAuthSuccess={isAuthSuccess}
            title="Dashboard"
          />
          <RoutePrivate
            exact
            component={Admins}
            redirectTo={config.defPublicRoute}
            path={config.routes.dashboard.admins}
            isAuthSuccess={isAuthSuccess}
            title="Admin Users"
          />
          <RoutePrivate
            exact
            component={Psus}
            redirectTo={config.defPublicRoute}
            path={config.routes.dashboard.psus}
            isAuthSuccess={isAuthSuccess}
            title="PSU Users"
          />
          <RoutePrivate
            exact
            component={Customers}
            redirectTo={config.defPublicRoute}
            path={config.routes.dashboard.customers}
            isAuthSuccess={isAuthSuccess}
            title="Customer Users"
          />
          <RoutePrivate
            exact
            component={PricingPlans}
            redirectTo={config.defPublicRoute}
            path={config.routes.dashboard.pricingPlans}
            isAuthSuccess={isAuthSuccess}
            title="Pricing Plans"
          />
          <RoutePrivate
            exact
            component={PricingPlansPackages}
            redirectTo={config.defPublicRoute}
            path={config.routes.dashboard.pricingPlansPackages}
            isAuthSuccess={isAuthSuccess}
          />
          {/* -------------------------------------------------------------- */}
          {/* Auth */}
          {/* -------------------------------------------------------------- */}
          <RoutePublic
            exact
            component={SignIn}
            redirectTo={config.defPrivateRoute}
            path={config.routes.auth.signIn}
            isAuthSuccess={isAuthSuccess}
            title="Sign in"
          />
          <RoutePublic
            exact
            component={ResetPassword}
            redirectTo={config.defPrivateRoute}
            path={config.routes.auth.resetPassword}
            isAuthSuccess={isAuthSuccess}
            title="Reset password"
          />
          <RoutePublic
            exact
            component={ConfirmResetPassword}
            redirectTo={config.defPrivateRoute}
            path={config.routes.auth.confirmResetPassword}
            isAuthSuccess={isAuthSuccess}
            title="Set new password"
          />
          <Redirect
            key="redirect"
            to={
              isAuthSuccess
                ? config.routes.dashboard.main
                : config.routes.auth.signIn
            }
          />
        </Switch>
      </ReactRouter>
      <Toast key="Toast" />
    </div>
  );
};

export default Router;
