import React, { useCallback } from 'react';
import LayoutDashboard from 'layout/Dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import getDispatchWrapper from 'utils/getDispatchWrapper';
import { ContextMessages } from 'services/messages/context';
import LayoutTable from 'layout/Table';
import Table from 'components/tables/Table/Table';
import RoundTrianlge from 'assets/svg/icons/RoundTriangle/RoundTrianlge';
import CrudTab from 'layout/CrudTab';
import { CrudFormAdd, CrudFormUpdate } from 'components/forms/CrudForm';
import validators from 'services/validators';
import clsx from 'clsx';
import { backBtn, crudBtnAdd } from 'layout/Table/utils';
import { crudFormSetMode } from 'store/actions';
import { constants } from 'configShared/store';
import { columns, tableApiCall } from './tableConfig';
import {
  crudApiCall,
  crudFormParams,
  crudInitialValuesMapper,
} from './crudConfig';

type TPricingPlans = {
  className?: string;
};

const reducerId = constants.PRICING_PLANS_PACKAGES;

const PricingPlans: React.FC<TPricingPlans> = ({ className }) => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const pricingPlanId = params.get('pricingPlanId');
  const title = params.get('title');
  const dispatchRedux = useDispatch();
  const dispatch = useCallback(getDispatchWrapper(reducerId, dispatchRedux), [
    reducerId,
    dispatchRedux,
  ]);
  const messages = React.useContext(ContextMessages);
  const tabLabel = useSelector((state: any) => state[reducerId].crud.tab);
  const tableState = useSelector((state: any) => state[reducerId].table);

  const tableApiCallMemo = React.useMemo(() => tableApiCall(pricingPlanId), [
    pricingPlanId,
  ]);

  if (!pricingPlanId || !title) {
    return null;
  }

  return (
    <LayoutDashboard className={clsx(className, 'page_PricingPlans')}>
      <LayoutTable
        tabLabel={tabLabel}
        title={title}
        reducerId={reducerId}
        backBtn={title && backBtn(dispatch, title)}
        crudBtnAdd={tabLabel !== 'crud-add' && crudBtnAdd(dispatch, '+ Create')}
      >
        <div className="tab" data-label="root">
          <div className='back_btn' onClick={() => history.goBack()}>
            <RoundTrianlge />
            <div className="title">Back</div>
          </div>
          <Table
            dispatch={dispatch}
            state={tableState}
            apiCallWrapper={tableApiCallMemo}
            columns={columns}
            handleOnRowClick={(row: any) =>
              dispatch(
                crudFormSetMode({
                  tab: 'crud-update',
                  subTitle: <>{row.pricing_plan_title}</>,
                  initValues: crudInitialValuesMapper(row),
                }),
              )
            }
            handleOnEdit={(row: any) => {
              dispatch(
                crudFormSetMode({
                  tab: 'crud-update',
                  subTitle: <>{row.pricing_plan_title}</>,
                  initValues: crudInitialValuesMapper(row),
                }),
              )
            }}
          />
        </div>
        <div className="tab" data-label="crud-add">
          <CrudTab>
            <CrudFormAdd
              apiCallWrapper={crudApiCall.add(pricingPlanId)}
              dispatch={dispatch}
              reducerId={reducerId}
              validationSchema={
                validators(messages).dashboard.pricingPlansPackage.add
              }
              params={crudFormParams(messages).add}
            />
          </CrudTab>
        </div>
        <div className="tab" data-label="crud-update">
          <CrudTab>
            <CrudFormUpdate
              apiCallWrapper={crudApiCall.update(pricingPlanId)}
              deleteApiCallWrapper={crudApiCall.delete(pricingPlanId)}
              dispatch={dispatch}
              reducerId={reducerId}
              validationSchema={
                validators(messages).dashboard.pricingPlansPackage.update
              }
              params={crudFormParams(messages).update}
              backBtn={backBtn}
            />
          </CrudTab>
        </div>
      </LayoutTable>
    </LayoutDashboard>
  );
};

export default PricingPlans;
