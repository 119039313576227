import { errorsHandler } from 'utils/errorsHandler';
import csrfTokenCall from './csrfTokenCall';
import systemLoginCall from './systemLoginCall';

type TApiCallWrapper = (args: {
  onStart?: () => void;
  onFail?: (error: any) => void;
  apiCall: () => Promise<any>;
  onFinish?: (resp: any) => void;
  [keys: string]: any;
}) => void;

const apiCallWrapper: TApiCallWrapper = async ({
  onFinish,
  onStart,
  onFail,
  apiCall,
  own,
  dispatch,
  csrfToken = false,
  systemLogin = false,
}) => {
  if (onStart) onStart();
  if (own && own.onStart) own.onStart();
  let response;
  try {
    if (systemLogin) await systemLoginCall();
    if (csrfToken) await csrfTokenCall();
    response = await apiCall();
    if (onFinish) onFinish(response);
    if (own && own.onFinish) own.onFinish(response);
  } catch (e) {
    let errorParsed = e;
    if (e instanceof Error) {
      errorParsed = e.toString();
    }
    if (onFail) onFail(errorParsed);
    if (own && own.onFail) own.onFail(errorParsed);
    errorsHandler({ errorMsg: errorParsed, dispatch });
  }
};

export default apiCallWrapper;
