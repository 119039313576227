import { TToastMessage } from 'appTypes';
import shortid from 'shortid';
import * as constants from './constants';
import config from 'configShared';

type TStore = { messages: Array<TToastMessage> };

export const defInitState: TStore = {
  messages: [],
};

const reducer = (initState: TStore = defInitState) => (
  state: TStore = initState,
  action: any,
) => {
  let msg: TToastMessage;
  if (typeof action.msg === 'string') {
    msg = {
      msg: action.msg,
    };
  } else {
    msg = action.msg;
  }

  switch (action.type) {
    case constants.TOAST_MESSAGE_ADD:
      return {
        messages: [
          {
            ...msg,
            id: shortid.generate(),
            type: 'normal',
            timeout: config.defToastTimeout,
          },
          ...state.messages,
        ],
      };
    case constants.TOAST_MESSAGE_ADD_FAIL_REDIRECT:
      return {
        messages: [
          {
            ...msg,
            id: shortid.generate(),
            type: 'fail',
            redirect: action.redirect,
            history: action.history,
            timeout: config.defToastRedirectTimeoutFail,
          },
          ...state.messages,
        ],
      };
    case constants.TOAST_MESSAGE_ADD_FAIL:
      return {
        messages: [
          {
            ...msg,
            id: shortid.generate(),
            type: 'fail',
            timeout: config.defToastTimeoutFail,
          },
          ...state.messages,
        ],
      };
    case constants.TOAST_MESSAGE_ADD_SUCCESS_REDIRECT:
      return {
        messages: [
          {
            ...msg,
            id: shortid.generate(),
            type: 'success',
            redirect: action.redirect,
            history: action.history,
            timeout: config.defToastRedirectTimeoutSuccess,
          },
          ...state.messages,
        ],
      };
    case constants.TOAST_MESSAGE_ADD_SUCCESS:
      return {
        messages: [
          {
            ...msg,
            id: shortid.generate(),
            type: 'success',
            timeout: config.defToastTimeoutSuccess,
          },
          ...state.messages,
        ],
      };
    case constants.TOAST_MESSAGE_REMOVE:
      return { messages: [...state.messages.filter(e => e.id !== action.id)] };
    default:
      return state;
  }
};

export default reducer;
