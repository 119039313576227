import React from 'react';
import { IFormSingleFieldParams, TInputField } from '../../types';

const StipeCurrencySelect = ({
  meta,
  className,
  label,
  id,
  initValue,
  ...rest
}: TInputField & IFormSingleFieldParams) => {
  return (
    <div className={className}>
      <label htmlFor={id} className="select-label">
        {label}
      </label>
      <select id={id} defaultValue="label" {...rest}>
        <option value="label" selected disabled>
          Choose currency
        </option>
        <option value="USD">USD</option>
        <option value="GBP">GBP</option>
      </select>
      {meta && meta.error ? (
        <div className="helper-text" id={`${id}-helper-text`}>
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export default StipeCurrencySelect;
