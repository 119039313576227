import { TState } from './types';

const initState: TState = {
  offset: 0,
  data: [],
  total: 0,
  limit: 10,
  error: null,
  isProcessing: false,
  updatedAt: 0,
};

export default initState;
