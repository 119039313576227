import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { IFormSingleFieldParams, TInputField } from '../types';

const TextField = ({
  id,
  type,
  value,
  onChange,
  onBlur,
  name,
  label,
  meta,
  className,
  placeholder,
  isDisabled,
}: TInputField & IFormSingleFieldParams) => {
  return (
    <div className={clsx(className, 'TextField')}>
      <label htmlFor={id}>{label}</label>
      <input
        disabled={isDisabled}
        aria-describedby={`${id}-helper-text`}
        autoComplete="username"
        className={clsx('text-input', 'component_TextField')}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        type={type}
        placeholder={(!label && placeholder) || ''}
        value={(value as unknown) as string}
      />
      {meta && meta.touched && meta.error ? (
        <div className="helper-text" id={`${id}-helper-text`}>
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

const TextFieldStyled = styled(TextField)`
  visibility: ${props => (props.isHidden ? 'hidden' : 'visible')};
`;

export default TextFieldStyled;
