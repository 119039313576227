import React from 'react';
import Emblem from 'assets/svg/logo/Emblem';
import clsx from 'clsx';

type TPreloaderButton = {
  className?: string;
  isProcessing?: boolean;
  simple?: boolean;
};

const PreloaderButton: React.FC<TPreloaderButton> = ({ className }) => {
  return (
    <div className={clsx(className, 'component_ui_PreloaderButton')}>
      <Emblem />
    </div>
  );
};

export default PreloaderButton;
