import { TColumn } from 'components/tables/Table/types';
import { apiPricingPlansPackagesRead } from 'services/dataFetching/crud';

export const columns: Array<TColumn> = [
  { Header: 'Pricing plan Name', accessor: 'pricing_plan_title' },
  { Header: 'Credits', accessor: 'credits' },
  { Header: 'Amount', accessor: 'amount' },
  { Header: 'Currency', accessor: 'currency' },
];

export const tableApiCall = (id: any) => ({
  apiCall: () =>
    apiPricingPlansPackagesRead(id).then((r: any) => ({
      data: r.body,
    })),
});
