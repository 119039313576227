const colors = {
  brand: {
    grayLight20: 'rgba(224,224,224,0.2)',
    grayLight50: 'rgba(224,224,224,0.5)',
    grayLight: '#E0E0E0',
    grayLightSecond: '#d9d9d9',
    gray: '#B8B4B2',
    dark: '#3C3C3B',
    orange: '#FAB733',
    red: '#ED6C78',
    redDimmed: '#953838',
    blue: '#5ABDD7',
    white: '#FFF',
    green: '#2ea077',
    greenDimmed: '#18533d',
  },
};

export default colors;
