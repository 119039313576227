import React from 'react';
import Emblem from 'assets/svg/logo/Emblem';
import clsx from 'clsx';

type TPreloader = {
  className?: string;
};

const Preloader: React.FC<TPreloader> = ({ className }) => {
  return (
    <div className={clsx(className, 'component_ui_Preloader')}>
      <Emblem />
    </div>
  );
};

export default Preloader;
