import { TMsg } from 'appTypes/messages';

const messages: TMsg = {
  containers: {
    auth: {
      signIn: {
        attemptSuccess: 'Successful sign in',
        attemptFail: 'Sign in error',
        forgotPassword: 'Forgot password?',
      },
      resetPassword: {
        attemptSuccess: 'Please, check your email for further instructions',
        attemptFail: 'Failed to reset password',
      },
      confirmResetPassword: {
        attemptSuccess: 'New password successfully applied',
        attemptFail: 'Failed to reset password',
      },
    },
  },
  crud: {
    form: {
      buttons: {
        add: 'Create',
        delete: 'Delete',
        reset: 'Reset',
        cancel: 'Cancel',
      },
    },
    add: {
      success: 'User added!',
      fail: 'Failed to add user!',
    },
    delete: {
      success: 'User deleted!',
      fail: 'Failed to delete!',
    },
    update: {
      success: 'User updated!',
      fail: 'Failed to update!',
    },
  },
  form: {
    fields: {
      label: {
        id: 'ID',
        first_name: 'First name',
        last_name: 'Last name',
        firstName: 'First name',
        lastName: 'Last name',
        phone: 'Phone',
        simple_password: 'Password',
        password: 'Password',
        passwordMatch: 'Confirm Password',
        email: 'Email',
        companyName: 'Company name',
        companyNumber: 'Company number',
        companySector: 'Company sector',
        companyEmployeesNumber: 'Company employees number',
        companyPostcode: 'Company postcode',
        companyBuilding: 'Company building',
        pricingPlanId: 'Pricing plan ID',
        pricingPlan: 'Pricing plan',
        title: 'Title',
        credits: 'Credits',
        currency: 'Currency',
        amount: 'Amount',
      },
      placeholder: {
        id: 'ID',
        first_name: 'First name',
        last_name: 'Last name',
        firstName: 'First name',
        lastName: 'Last name',
        phone: 'Phone',
        simple_password: 'Please, enter your password',
        password: 'Please, enter your password',
        passwordMatch: 'Please, repeat your password',
        email: 'Please, enter your email',
        companyName: 'Please, fill this field',
        companyNumber: 'Please, fill this field',
        companySector: 'Please, fill this field',
        companyPostcode: 'Please, fill this field',
        companyEmployeesNumber: 'Please, fill this field',
        companyBuilding: 'Please, fill this field',
        pricingPlanId: 'Please, fill this field',
        pricingPlan: 'Please, fill this field',
        title: 'Please, fill this field',
        credits: 'Please, fill this field',
        currency: 'Please, fill this field',
        amount: 'Please, fill this field',
      },
    },
    errors: {
      id: 'ID',
      first_name: 'First name',
      last_name: 'Last name',
      firstName: 'First name',
      lastName: 'Last name',
      phone: 'Phone',
      simple_password: 'Please, enter valid password',
      password: 'Please, enter valid password',
      passwordMatch: 'Password not matched',
      email: 'Please, enter valid email',
      companyName: 'Please, enter valid value',
      companyNumber: 'Please, enter valid value',
      companySector: 'Please, enter valid value',
      companyPostcode: 'Please, enter valid value',
      companyEmployeesNumber: 'Please, enter valid value',
      companyBuilding: 'Please, enter valid value',
      pricingPlanId: 'Please, enter valid value',
      pricingPlan: 'Please, enter valid value',
      title: 'Please, enter valid value',
      credits: 'Please, enter valid value',
      currency: 'Please, enter valid value',
      amount: 'Please, enter valid value',
    },
    requirements: {
      required: 'Required',
      min:
        'Must be  {min} {min, plural,\n' +
        '                one {character}\n' +
        '                other {characters or less}\n' +
        '               }',
      max:
        'Must be  {max} {max, plural,\n' +
        '                one {character}\n' +
        '                other {characters or less}\n' +
        '               }',
      matches:
        'Must be {minMax} {amount} of {matchesType}{amount, plural,\n' +
        '                one {}\n' +
        '                other {s}\n' +
        '               }',
    },
  },
  network: {
    error: {
      timeout: 'Waiting too long. Timeout {timeout} passed.',
    },
  },
};

export default messages;
