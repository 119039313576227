import Triangle from './Triangle';
import styled from 'styled-components';

const TriangleStyled = styled(Triangle)`
  ${props => {
    if (props.direction === 'up') {
      return `transform: rotate(180deg)`;
    }
    if (props.direction === 'down') {
      return `transform: rotate(0)`;
    }
    if (props.direction === 'left') {
      return `transform: rotate(90deg)`;
    }
    if (props.direction === 'right') {
      return `transform: rotate(270deg)`;
    }
  }}
`;

export default TriangleStyled;
