import { css } from 'styled-components';

export default css`
  max-width: var(--table-width);
  width: 100%;

  position: relative;
  display: table;

  .th,
  .td {
    color: var(--color-brand-fg-primary);
  }

  .isProcessing {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: ' ';
    background-color: slategray;
    opacity: 0.2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .table_tbody_wrapper {
    min-height: 500px;
  }

  .table_inner_wrapper {
    width: 100%;
  }

  .thead {
    border-bottom: 2px solid ${props => props.theme.colors.brand.grayLight50};
    .tr {
      .th {
        &.--sortable {
          cursor: pointer;
          .th_sortIcons {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: table-cell;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500;
        padding: 1rem 0.5rem;
        color: var(--color-brand-gray);
        .th_wrapper {
          display: grid;
          grid-auto-flow: column;
          grid-gap: 10px;
          white-space: pre-line;
          height: 100%;
          align-items: center;
        }
      }
    }
  }
  .tbody {
    .tr {
      position: relative;
      cursor: pointer;
      transition: 300ms all;
      padding: 10px 0;

      .--row_viewDetails {
        visibility: hidden;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 100;
        background-color: ${$props => $props.theme.colors.brand.blue};
        color: white;
        font-size: 12px;
        font-weight: 700;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        border-bottom-left-radius: 10px;
        width: 100px;
        height: 20px;
      }

      .--row_edit {
        padding: 0;
        border-radius: 0;
        height: 100%;
      }

      &:hover {
        .--row_viewDetails {
          visibility: visible;
        }
      }

      .td {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 13px;
        font-weight: 600;
        display: inline-block;
        text-align: left;

        &:first-child {
        }

        &.-default {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 1rem 0.5rem;
        }

        &.-status {
          text-transform: uppercase;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-align: center;

          & > div {
            width: 100%;
            max-width: 120px;
            font-weight: 700;
            font-size: 12px;
            padding: 10px 12px;
            color: white;
            background-color: ${props => props.theme.colors.brand.grayLight};

            &.--request-expired,
            &.--consent-expired,
            &.--expired {
              background-color: ${props => props.theme.colors.brand.grayLight};
            }

            &.--consent-data-shared,
            &.--confirmed,
            &.--revoked {
              background-color: ${props => props.theme.colors.brand.blue};
            }
            &.--consent-data-sharing-rejected,
            &.--rejected {
              background-color: ${props => props.theme.colors.brand.red};
            }
            &.--consent-data-received,
            &.--pending {
              background-color: ${props => props.theme.colors.brand.orange};
            }
          }
        }

        &.-bank {
          padding: 1rem 0.5rem;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 800;
        }

        &.-deletedAt {
          width: 50px;
          padding: 0 1rem;
          display: inline-flex;
          align-items: center;
          .isDeleted {
            display: inline-flex;
            color: white;
            &.--deleted {
              background-color: coral;
              border-radius: 30px;
              width: 30px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            &.--ok {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 30px;
              width: 30px;
              height: 30px;
              background-color: darkseagreen;
            }
          }
        }
      }
      &:nth-child(even) {
        background-color: var(--color-brand-grayLight20);
      }

      &:hover {
        ${props =>
          props.theme.shadows.tableBorder.borderWhole(
            '3px',
            props.theme.colors.brand.blue,
          )}
      }
      &.--noEdit {
        &:hover:after {
          display: none;
        }
      }
    }
  }

  .pagination {
    display: inline-flex;
    flex-flow: row nowrap;
    padding-top: 1rem;
    margin: 0 -5px;
  }

  .pagination_wrapper {
    text-align: center;
    padding: 0 5px;
    margin: 0 -5px;
  }

  .pagination_wrapper .pageButtons_wrapper {
    display: inline-flex;
    flex-flow: row nowrap;
    max-width: calc(var(--table-width) - 13rem);
    overflow: auto;
  }

  .pagination button {
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    cursor: pointer;
    float: left;
    padding: 0;
    text-decoration: none;
    transition: background-color 0.3s;
    background-color: transparent;
    border: none;
    margin: 0 5px;
    display: flex;
    font-weight: 600;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;

    &.arrows {
      border: 1px solid ${props => props.theme.colors.brand.blue};
      background-color: transparent;
    }

    svg {
      background-color: transparent;

      width: 7px;
      height: 7px;
      fill: ${props => props.theme.colors.brand.blue};
    }

    &.arrows.disabled {
      pointer-events: none;
      border: 1px solid ${props => props.theme.colors.brand.gray};
      background-color: transparent;

      svg {
        fill: ${props => props.theme.colors.brand.gray};
      }
    }
  }

  .pagination button.active {
    background-color: ${props => props.theme.colors.brand.blue};
    color: white;
    border: 1px solid ${props => props.theme.colors.brand.blue};
  }

  .pagination button:hover:not(.active) {
    background-color: ${props => props.theme.colors.brand.grayLight};
  }
`;
