import styled, { css } from 'styled-components';
import Button from './Button';

const ButtonStyled = styled(Button as any)<any>`
  min-width: 100px;
  min-height: 60px;
  border: none;
  font-weight: 600;
  font-size: 1rem;
  padding: 0 2rem;
  cursor: pointer;
  transition: all 300ms;
  text-transform: uppercase;

  &:hover {
    background-color: ${props => props.theme.colors.brand.orange};
    color: ${props => props.theme.colors.brand.dark};
  }

  ${props => {
    switch (props.variant) {
      case 'primary':
        return css`
          background-color: ${props.theme.colors.brand.blue};
          color: ${props.theme.colors.brand.white};
        `;
      case 'secondary':
        return css`
          background-color: ${props.theme.colors.brand.yellow};
          color: ${props.theme.colors.brand.dark};
        `;
      case 'third':
        return css`
          background-color: ${props.theme.colors.brand.red};
          color: ${props.theme.colors.brand.white};
        `;
      default:
        return css`
          background-color: ${props.theme.colors.brand.blue};
          color: ${props.theme.colors.brand.white};
        `;
    }
  }};
`;

export default ButtonStyled;
