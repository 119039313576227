import React, { useCallback } from 'react';
import LayoutDashboard from 'layout/Dashboard';
import Table from 'components/tables/Table/Table';
import { apiConsents } from 'services/dataFetching/consents';
import getDispatchWrapper from 'utils/getDispatchWrapper';
import { useDispatch, useSelector } from 'react-redux';
import LayoutTable from 'layout/Table';
import clsx from 'clsx';
import columns from './columns';
import IdentityCheckResults from './IdentityCheckResults/index';
import { constants } from 'configShared/store';
import * as actions from 'store/actions';
import Link from 'components/ui/Link';
import RoundTrianlge from 'assets/svg/icons/RoundTriangle/RoundTrianlge';

type TConsents = {
  className?: string;
  title?: string;
};

const reducerId = constants.CONSENTS;

const tableApiCall = (dispatch?: any, messages?: any) => ({
  apiCall: (body: any) =>
    apiConsents(body).then((r: any) => ({
      data: r.body.consents,
      total: r.body.total,
    })),
});

const Consents: React.FC<TConsents> = ({ title, className }) => {
  const dispatchRedux = useDispatch();
  const dispatch = useCallback(getDispatchWrapper(reducerId, dispatchRedux), [
    reducerId,
    dispatchRedux,
  ]);
  const tableState = useSelector((state: any) => state[reducerId].table);

  const tabLabel = useSelector(
    (state: any) => state[reducerId].identityCheckResults.tab,
  );

  const backBtn = (
    <div className="backBtn">
      <Link
        handleOnClick={() => {
          dispatch(
            actions.identityCheckResultsSetTab({
              tab: 'root',
            }),
          );
          dispatchRedux({
            ...actions.identityCheckResultsOnReset(),
            reducerId: constants.CONSENTS,
          });
        }}
      >
        <RoundTrianlge />
        <div className="title">{title}</div>
      </Link>
    </div>
  );

  const tableApiCallMemo = React.useMemo(() => tableApiCall(dispatch), [
    dispatch,
  ]);

  return (
    <LayoutDashboard className={clsx(className, 'page_Consents')}>
      <LayoutTable
        statePath={'identityCheckResults'}
        reducerId={reducerId}
        tabLabel={tabLabel}
        title={title}
        backBtn={title && backBtn}
      >
        <div className="tab" data-label="root">
          <div className="table_filter_wrapper">
            <div className="filter">
              <select
                placeholder="Status"
                defaultValue="label"
                onChange={e =>
                  dispatch(actions.tableSetStatus(e.currentTarget.value))
                }
              >
                <option value="label" disabled>
                  Status
                </option>
                <option value="">All</option>
                <option value="request-expired">Request Expired</option>
                <option value="consent-expired">Consent Expired</option>
                <option value="pending">Pending</option>
                <option value="consent-data-received">Pending Data Sharing</option>
                <option value="consent-data-sharing-rejected">Rejected Data Sharing</option>
                <option value="rejected">Rejected</option>
                <option value="consent-data-shared">Confirmed</option>
                <option value="revoked">Revoked</option>
              </select>
            </div>
            <div className="filter">
              <select
                defaultValue="label"
                placeholder="Period"
                onChange={e =>
                  dispatch(actions.tableSetPeriod(e.currentTarget.value))
                }
              >
                <option value="label" disabled>
                  Period
                </option>
                <option value="">All</option>
                <option value="current_month">Current month</option>
                <option value="current_week">Current week</option>
                <option value="past_month">Past month</option>
              </select>
            </div>
            <div className="filter">
              <input
                placeholder={'Search by customer'}
                onChange={e =>
                  dispatch(
                    actions.tableSetSearchCustomer(e.currentTarget.value),
                  )
                }
                name="customer_search"
              />
            </div>
            <div className="filter">
              <input
                placeholder={'Search by PSU'}
                onChange={e =>
                  dispatch(actions.tableSetSearchPsu(e.currentTarget.value))
                }
                name="psu_search"
              />
            </div>
          </div>
          <Table
            state={tableState}
            dispatch={dispatch}
            apiCallWrapper={tableApiCallMemo}
            columns={columns}
            viewDetails
            params={{
              isRowEditable: row => row.status === 'consent-data-shared',
            }}
            handleOnRowClick={(row: any) => {
              dispatch(
                actions.identityCheckResultsSetTab({
                  tab: 'identity-check-results',
                  consentId: row.id,
                  subTitle: <>{row.psu_email}</>,
                }),
              );
              dispatchRedux({
                ...actions.tableOnReset(),
                reducerId: constants.CONSENTS_TRANSACTIONS,
              });
            }}
          />
        </div>
        <div className="tab" data-label="identity-check-results">
          <IdentityCheckResults />
        </div>
      </LayoutTable>
    </LayoutDashboard>
  );
};

export default Consents;
