import {
  apiPricingPlanAdd,
  apiPricingPlanDelete,
  apiPricingPlanUpdate,
} from 'services/dataFetching/crud';
import {
  notSoSimpleFormConstructor,
  simpleFormConstructor,
} from 'components/forms/Form';
import { TCrudFormParams } from 'components/forms/CrudForm/types';
import { TMsg } from 'appTypes/messages';

export const crudValuesMapper = (values: any) => {
  const { id, title, credits, amount, currency } = values;

  type TRet = {
    id: string;
    title: string;
    items: any;
  };

  const ret: TRet = {
    id,
    title,
    items: [
      {
        credits: parseInt(credits, 0),
        amount: parseInt(amount, 0),
        currency,
      },
    ],
  };

  return ret;
};

export const crudApiCall = {
  add: (dispatch?: any, messages?: any) => ({
    apiCall: (values: any) => apiPricingPlanAdd(crudValuesMapper(values)),
  }),
  delete: (dispatch?: any, messages?: any) => ({
    apiCall: apiPricingPlanDelete,
  }),
  update: (dispatch?: any, messages?: any) => ({
    apiCall: (values: any) => apiPricingPlanUpdate({title: values.title, id: values.id}),
  }),
};

export const crudFormParams = (messages: TMsg): TCrudFormParams => ({
  update: notSoSimpleFormConstructor({
    messages,
    fields: ['title'],
  }),
  add: simpleFormConstructor({
    messages,
    fields: ['title', 'empty', 'credits', 'amount', 'currency'],
  }),
});

export const crudInitialValuesMapper = (initialValues: any) => {
  const { id, title, credits, amount, currency } = initialValues;

  return {
    id,
    title,
    credits,
    amount,
    currency,
  };
};
