import React from 'react';
import Emblem from 'assets/svg/logo/Emblem';
import clsx from 'clsx';

type TPreloaderTable = {
  className?: string;
  isProcessing?: boolean;
  simple?: boolean;
};

const PreloaderTable: React.FC<TPreloaderTable> = ({ className }) => {
  return (
    <div className={clsx(className, 'component_ui_PreloaderTable')}>
      <Emblem />
    </div>
  );
};

export default PreloaderTable;
