import { TColumn } from 'components/tables/Table/types';
import moment from 'moment';
import { expiredTimeFormat } from 'components/tables/Table/utils';
import React from 'react';

const columns: Array<TColumn> = [
  { Header: 'PSU Email', accessor: 'psu_email' },
  { Header: 'Customer ID', accessor: 'customer_id' },
  {
    Header: 'Status',
    accessor: 'status',
    canSort: true,
    tdClassName: '-status',
    Cell: ({ value }) => {
      return <div className={`--${value}`}>{value}</div>;
    },
  },
  {
    Header: 'Aspsp Consent Id',
    accessor: 'aspsp_consent_id',
  },
  {
    Header: 'Requested',
    accessor: 'created_at',
    Cell: ({ value }) => {
      return <>{moment(value).format('YYYY/MM/DD HH:mm')}</>;
    },
    canSort: true,
    sortAccessor: 'requested',
  },
  {
    Header: 'Customer',
    accessor: 'customer',
    Cell: ({
      value,
    }: {
      value: { company_name: string; first_name: string; last_name: string };
    }) => {
      const { company_name, first_name, last_name } = value;
      const render = `${first_name} ${last_name}`;
      return (
        <>
          {render} {company_name && `(${company_name})`}
        </>
      );
    },
  },
  {
    Header: 'Expires in',
    canSort: true,
    sortAccessor: 'expires_at',
    accessor: 'lifetime',
    Cell: ({ value }) => {
      return <>{value ? expiredTimeFormat(parseInt(value, 10)) : '—'}</>;
    },
  },
  {
    Header: 'Bank',
    accessor: 'bank',
    tdClassName: '-bank',
    Cell: ({ value, row }) => {
      return <>{row.status === 'consent-data-shared' ? value : '—'}</>;
    },
  },
  { Header: 'ID', accessor: 'id' },
];

export default columns;
