import React, { useCallback } from 'react';
import LayoutDashboard from 'layout/Dashboard';
import Table from 'components/tables/Table/Table';
import getDispatchWrapper from 'utils/getDispatchWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { CrudFormAdd, CrudFormUpdate } from 'components/forms/CrudForm';
import validators from 'services/validators';
import { ContextMessages } from 'services/messages/context';
import clsx from 'clsx';
import LayoutTable from 'layout/Table';
import CrudTab from 'layout/CrudTab';
import { backBtn, crudBtnAdd } from 'layout/Table/utils';
import * as actions from 'store/actions';
import { constants } from 'configShared/store';
import {
  crudApiCall,
  crudFormParams,
  crudInitialValuesMapper,
} from './crudConfig';
import { columns, tableApiCall } from './tableConfig';

type TAdmins = {
  className?: string;
  title?: string;
};

const reducerId = constants.ADMINS;

const Admins: React.FC<TAdmins> = ({ title, className }) => {
  const dispatchRedux = useDispatch();
  const dispatch = useCallback(getDispatchWrapper(reducerId, dispatchRedux), [
    reducerId,
    dispatchRedux,
  ]);
  const messages = React.useContext(ContextMessages);
  const tabLabel = useSelector((state: any) => state[reducerId].crud.tab);
  const tableState = useSelector((state: any) => state[reducerId].table);

  const tableApiCallMemo = React.useMemo(() => tableApiCall(dispatch), [
    dispatch,
  ]);

  return (
    <LayoutDashboard className={clsx(className, 'page_Admins')}>
      <LayoutTable
        tabLabel={tabLabel}
        title={title}
        reducerId={reducerId}
        backBtn={title && backBtn(dispatch, title)}
        crudBtnAdd={
          !tableState.error &&
          tabLabel !== 'crud-add' &&
          crudBtnAdd(dispatch, '+ Create')
        }
      >
        <div className="tab" data-label="root">
          <Table
            dispatch={dispatch}
            state={tableState}
            apiCallWrapper={tableApiCallMemo}
            columns={columns}
            handleOnRowClick={(row: any) => {
              dispatch(
                actions.crudFormSetMode({
                  tab: 'crud-update',
                  subTitle: <>{row.email}</>,
                  initValues: crudInitialValuesMapper(row),
                }),
              );
            }}
          />
        </div>
        <div className="tab" data-label="crud-add">
          <CrudTab>
            <CrudFormAdd
              apiCallWrapper={crudApiCall.add(dispatch)}
              dispatch={dispatch}
              reducerId={reducerId}
              validationSchema={validators(messages).dashboard.admins.add}
              params={crudFormParams.add(messages)}
            />
          </CrudTab>
        </div>
        <div
          className="tab"
          data-layout-type="tab"
          data-subtype="crud"
          data-label="crud-update"
        >
          <CrudTab>
            <div className="crudForm">
              <CrudFormUpdate
                apiCallWrapper={crudApiCall.update(dispatch)}
                deleteApiCallWrapper={crudApiCall.delete(dispatch)}
                dispatch={dispatch}
                reducerId={reducerId}
                validationSchema={validators(messages).dashboard.admins.update}
                params={crudFormParams.update(messages)}
                backBtn={backBtn}
              />
            </div>
          </CrudTab>
        </div>
      </LayoutTable>
    </LayoutDashboard>
  );
};

export default Admins;
