import styled from 'styled-components';
import PasswordField from './PasswordField';

const PasswordFieldStyled = styled(PasswordField)`
  ${props => props.theme.input.input};

  display: flex;
  flex-flow: row nowrap;
  & .icon_wrapper {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: relative;

    .buttonWithIcon_wrapper {
      background-color: transparent;
      border: none;
      .assets_svg {
        z-index: 100;
      }
    }
  }
`;

export default PasswordFieldStyled;
