export const GLOBAL_CLEAN_UP = 'GLOBAL_CLEAN_UP';
export type GLOBAL_CLEAN_UP = typeof GLOBAL_CLEAN_UP;

/** Reducer */

export const CURD_FORM_TOGGLE_IS_PROCESSING = 'CURD_FORM_TOGGLE_IS_PROCESSING';
export type CURD_FORM_TOGGLE_IS_PROCESSING = typeof CURD_FORM_TOGGLE_IS_PROCESSING;

export const CURD_FORM_ON_SUBMIT_DATA = 'CURD_FORM_ON_SUBMIT_DATA';
export type CURD_FORM_ON_SUBMIT_DATA = typeof CURD_FORM_ON_SUBMIT_DATA;

export const CURD_FORM_ON_SUBMIT_DATA_START = 'CURD_FORM_ON_SUBMIT_DATA_START';
export type CURD_FORM_ON_SUBMIT_DATA_START = typeof CURD_FORM_ON_SUBMIT_DATA_START;

export const CURD_FORM_ON_SUBMIT_DATA_FINISH =
  'CURD_FORM_ON_SUBMIT_DATA_FINISH';
export type CURD_FORM_ON_SUBMIT_DATA_FINISH = typeof CURD_FORM_ON_SUBMIT_DATA_FINISH;

export const CURD_FORM_ON_SUBMIT_DATA_FAIL = 'CURD_FORM_ON_SUBMIT_DATA_FAIL';
export type CURD_FORM_ON_SUBMIT_DATA_FAIL = typeof CURD_FORM_ON_SUBMIT_DATA_FAIL;

export const CURD_FORM_SET_MODE = 'CURD_FORM_SET_MODE';
export type CURD_FORM_SET_MODE = typeof CURD_FORM_SET_MODE;

export const CRUD_FORM_ON_RESET = 'CRUD_FORM_ON_RESET';
export type CRUD_FORM_ON_RESET = typeof CRUD_FORM_ON_RESET;
