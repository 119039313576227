import { IUserProfileData } from 'appTypes';
import * as constants from 'store/constants/auth';

export function authSetUserProfileData(
  userProfileData: IUserProfileData | null,
) {
  return {
    type: constants.AUTH_SET_USER_PROFILE_DATA,
    userProfileData,
  };
}

export function authOnTryAutoSignIn() {
  return {
    type: constants.AUTH_ON_TRY_AUTO_SIGN_IN,
  };
}

export function authOnTryAutoSignInFinish() {
  return {
    type: constants.AUTH_ON_TRY_AUTO_SIGN_IN_FINISH,
  };
}

export function authOnTryAutoSignInFail() {
  return {
    type: constants.AUTH_ON_TRY_AUTO_SIGN_IN_FAIL,
  };
}

export function authSetError(error: any) {
  return {
    type: constants.AUTH_SET_ERROR,
    error,
  };
}

export function authSetIsProcessing(isProcessing: boolean) {
  return {
    type: constants.AUTH_SET_IS_PROCESSING,
    isProcessing,
  };
}

export function authOnConfirmResetPassword(payload: any) {
  return {
    type: constants.AUTH_ON_CONFIRM_RESET_PASSWORD,
    payload,
  };
}

export function authOnResetPassword(payload: any) {
  return {
    type: constants.AUTH_ON_RESET_PASSWORD,
    payload,
  };
}

export function authOnSignIn(payload: {
  data: {
    email: string;
    password: string;
  };
  own?: {
    onFinish?: any;
    onFail?: any;
  };
}) {
  return {
    type: constants.AUTH_ON_SIGN_IN,
    payload,
  };
}

export function authOnSignOut() {
  return {
    type: constants.AUTH_ON_SIGN_OUT,
  };
}

export function authSetSignOutTrue() {
  return {
    type: constants.AUTH_SET_SIGN_OUT_TRUE,
  };
}

export function authOnReset() {
  return {
    type: constants.AUTH_ON_RESET,
  };
}
