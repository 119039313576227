import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validators from 'services/validators';
import FormForm, {
  getFormInitValuesFromFieldsParams,
  simpleFormConstructor,
} from 'components/forms/Form';
import LayoutAuthForms from 'layout/AuthForms';
import { ContextMessages } from 'services/messages/context';
import { TRouteCompProps } from 'components/routes';
import useRouteDefParams from 'utils/useRouteDefParams';
import config from 'configShared';
import Link from 'components/ui/Link';
import IntlMessageFormat from 'intl-messageformat';
import { TAppState } from 'appTypes/common';
import * as actions from 'store/actions';
import { useHistory } from 'react-router-dom';

const ResetPassword: React.FC<TRouteCompProps> = ({ title }) => {
  useRouteDefParams({ title });
  const dispatch = useDispatch();
  const messages = React.useContext(ContextMessages);
  const { isProcessing } = useSelector((state: TAppState) => state.auth);
  const history = useHistory();

  const params = simpleFormConstructor({
    messages,
    fields: ['email'],
  });

  const msgSuccess = new IntlMessageFormat(
    messages.containers.auth.resetPassword.attemptSuccess,
  );
  const msgFail = new IntlMessageFormat(
    messages.containers.auth.resetPassword.attemptFail,
  );

  return (
    <LayoutAuthForms pageTitle={title} className="page_resetPassword">
      <FormForm
        isProcessing={isProcessing}
        validationSchema={validators(messages).auth.resetPassword}
        initialValues={getFormInitValuesFromFieldsParams(params)}
        params={params}
        onSubmit={async (values, helpers) => {
          dispatch(
            actions.authOnResetPassword({
              data: {
                email: values.email,
              },
              own: {
                onFinish: () =>
                  dispatch(
                    actions.toastMessageAddSuccessRedirect(
                      {
                        msg: msgSuccess.format(),
                      },
                      config.routes.auth.signIn,
                      history,
                    ),
                  ),
                onFail: (error: any) =>
                  dispatch(
                    actions.toastMessageAddFail({
                      msg: msgFail.format(),
                      ...error,
                    }),
                  ),
              },
            }),
          );
        }}
      />
      <Link className="textUnderTheAuthForm" href={config.routes.auth.signIn}>
        Back to sign in
      </Link>
    </LayoutAuthForms>
  );
};

export default ResetPassword;
