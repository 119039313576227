import React from 'react';
import clsx from 'clsx';

const Emblem = ({ className }: { className?: string }) => {
  return (
    <div className={clsx(className, 'assets_svg', 'trustloop_emblem')}>
      <svg
        width="38"
        height="37"
        viewBox="0 0 38 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.5293 11.2469C28.8647 11.2469 26.6968 9.09562 26.6968 6.4515C26.6968 4.96911 27.3789 3.64195 28.4472 2.76158C25.7393 1.09451 22.5715 0.0955486 19.1801 -3.8147e-06V7.17739C25.0523 7.44819 29.78 12.1397 30.0533 17.9673H37.2854C37.2002 14.99 36.3998 12.1888 35.054 9.72143C34.1714 10.6573 32.92 11.2469 31.5293 11.2469Z"
          fill="#FFB349"
        />
        <path
          d="M18.1061 7.17702V0.000377655C14.7142 0.0955524 11.5465 1.09451 8.83859 2.76196C9.90692 3.64233 10.589 4.96911 10.589 6.45188C10.589 9.096 8.42107 11.2469 5.75652 11.2469C4.36582 11.2469 3.11404 10.6577 2.23182 9.72144C0.886029 12.1892 0.0852536 14.99 0 17.9673H7.23247C7.50536 12.1397 12.2335 7.44781 18.1061 7.17702Z"
          fill="#52C8DA"
        />
        <path
          d="M7.23283 19.0319H0.000732422C0.085986 22.0091 0.886381 24.8107 2.23217 27.2773C3.11477 26.3418 4.36617 25.7519 5.75687 25.7519C8.42143 25.7519 10.5893 27.9035 10.5893 30.5473C10.5893 32.03 9.90728 33.3568 8.83932 34.2372C11.5469 35.9043 14.7146 36.9032 18.1061 36.9988V29.8214C12.2339 29.5517 7.50571 24.8594 7.23283 19.0319Z"
          fill="#FF6E7A"
        />
        <path
          d="M26.6968 30.5481C26.6968 27.9044 28.8647 25.7527 31.5293 25.7527C32.92 25.7527 34.1714 26.3423 35.054 27.2782C36.3998 24.8108 37.2002 22.01 37.2854 19.0331H30.0529C29.7801 24.8603 25.0519 29.5526 19.1797 29.8234V37C22.5712 36.9041 25.7389 35.9055 28.4468 34.2388C27.3789 33.358 26.6968 32.0309 26.6968 30.5481Z"
          fill="#AAB0AF"
        />
        <path
          d="M27.7706 6.45158C27.7706 8.50804 29.4567 10.1812 31.529 10.1812C33.6017 10.1812 35.2878 8.50804 35.2878 6.45158C35.2878 4.39512 33.6017 2.72201 31.529 2.72201C29.4567 2.72201 27.7706 4.39512 27.7706 6.45158Z"
          fill="#FFB349"
        />
        <path
          d="M31.5291 26.8184C29.4568 26.8184 27.7708 28.4916 27.7708 30.548C27.7708 32.6045 29.4568 34.2776 31.5291 34.2776C33.6015 34.2776 35.2875 32.6045 35.2875 30.548C35.2875 28.4916 33.6015 26.8184 31.5291 26.8184Z"
          fill="#AAB0AF"
        />
        <path
          d="M5.75631 10.1814C7.82866 10.1814 9.5147 8.50828 9.5147 6.45182C9.5147 4.39499 7.82866 2.72187 5.75631 2.72187C3.68397 2.72187 1.99792 4.39499 1.99792 6.45182C1.99792 8.50828 3.68397 10.1814 5.75631 10.1814Z"
          fill="#52C8DA"
        />
        <path
          d="M5.75631 34.2776C7.82866 34.2776 9.5147 32.6045 9.5147 30.548C9.5147 28.4916 7.82866 26.8184 5.75631 26.8184C3.68397 26.8184 1.99792 28.4916 1.99792 30.548C1.99792 32.6045 3.68397 34.2776 5.75631 34.2776Z"
          fill="#FF6E7A"
        />
      </svg>
    </div>
  );
};

export default Emblem;
