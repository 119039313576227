import styled, { css } from 'styled-components';
import LayoutDashboard from './Dashboard';

const styles = css`
  --sidebar-width: 300px;
  --color-brand-graySecond: ${props =>
    props.theme.colors.brand.grayLightSecond};
  --color-brand-gray: ${props => props.theme.colors.brand.gray};
  --color-brand-fg-primary: ${props => props.theme.colors.brand.dark};
  --color-brand-grayLight: ${props => props.theme.colors.brand.grayLight};
  --color-brand-grayLight20: ${props => props.theme.colors.brand.grayLight20};
  --border-width: 1px;
  --col-def-width: minmax(50px, 1fr);
  --border-color: #ccc;
  --table-width: calc(100vw - var(--sidebar-width) - 6rem);

  @media screen and (max-width: 1125px) {
    --table-width: calc(720px);
  }

  min-width: min-content;

  .grid_wrapper {
    background-color: white;
    overflow: hidden;
    display: grid;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    grid-template-areas:
      'sidebar header'
      'sidebar main'
      'userProfile footer';
    grid-template-rows: 0 1fr 80px;
    grid-template-columns: var(--sidebar-width) 1fr;

    &.page_confirmResetPassword {
      .component_TypoH {
        font-size: 2rem;
      }
    }
  }

  .toast_wrapper {
    width: calc(100% - var(--sidebar-width));
  }

  .footer {
    grid-area: footer;
    background-color: ${props => props.theme.colors.brand.grayLight50};
  }
  .header {
    grid-area: header;
  }
  .userProfile {
    grid-area: userProfile;
    background-color: #464646;
  }
  .sidebar {
    grid-area: sidebar;
    background-color: ${props => props.theme.colors.bg.primary};
    color: ${props => props.theme.colors.fg.primary};

    .component_ui_Link {
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .main {
    grid-area: main;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem 1rem 2rem 1rem;
    text-align: center;

    .table_layout_body_wrapper {
      width: 100%;
    }

    .back_btn {
      margin-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid var(--border-color);
      display: flex;
      flex-flow: row nowrap;
      min-width: var(--sidebar-width);
      color: ${props => props.theme.colors.brand.blue};
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.colors.brand.red};
      }

      & > div:first-child {
        padding-right: 1rem;
      }
    }

    .table_crud_wrapper {
    }

    .form_inputFields_wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 3rem;
    }

    .component_ui_Container {
      height: 100%;
      width: 100%;
    }

    .component_TypoH {
      text-align: center;
      margin-top: 0;
    }

    .trustloop_logo {
      margin-bottom: 5rem;
    }

    & > .wrapper {
      height: 100%;
      width: 100%;
      padding-bottom: 0.5rem;
    }

    .component_FormForm {
      .input-wrapper {
        margin: 1.5rem 0;

        label {
          left: 0;
          text-align: left;
        }

        input {
          height: 48px;
          width: 100%;
        }
      }

      .dashboard_wrapper {
        width: 100%;
        margin: 2.5rem 0 3rem;

        button {
          width: 100%;
        }
        .component_ui_ButtonSubmit.-submit {
        }
      }
    }
  }
`;

const LayoutDashboardStyled = styled(LayoutDashboard)`
  ${styles}
`;

export default LayoutDashboardStyled;
