import styled from 'styled-components';
import SidebarLink from '../Link';

const SidebarLinkStyled = styled(SidebarLink as any)<any>`
  cursor: pointer !important;
  user-select: none !important;
  background-color: transparent;
  border: none; 
  font-size: 1rem;
 
  color: ${({theme}) => theme.colors.brand.grayLight50};

  &.active {
    color: ${({theme}) => theme.colors.brand.white};
  }
`;

export default SidebarLinkStyled;
