import { TTheme } from 'appTypes/theme';
import shared from './shared/index';

const darkTheme: TTheme = {
  colors: {
    bg: {
      primary: shared.colors.brand.dark,
      secondary: shared.colors.brand.blue,
      third: shared.colors.brand.red,
      forth: '#3C3C3B',
      fifth: '#3C3C3B',
    },
    fg: {
      primary: shared.colors.brand.white,
      secondary: shared.colors.brand.red,
      third: '#3C3C3B',
      forth: '#3C3C3B',
      fifth: '#3C3C3B',
    },
  },
};

export default darkTheme;
