import styled, { css } from 'styled-components';
import LayoutAuthForms from './AuthForms';

const styles = css`
  min-height: 100%;
  min-width: min-content;
  width: 100%;
  background-color: ${props => props.theme.colors.bg.primary};
  color: ${props => props.theme.colors.fg.primary};

  &.pageconfirmResetPassword {
    .component_TypoH {
      font-size: 2rem;
    }
  }

  .toast_wrapper {
    width: 100%;
  }

  .grid_wrapper {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-areas: 'header' 'main' 'footer';
    grid-template-rows: minmax(0, 100px) 1fr 100px;
  }

  .header {
  }

  .footer {
  }

  .main {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    padding-top: 2rem;
    text-align: center;

    h2 {
      margin-top: 0;
    }

    .trustloop_logo {
      margin-bottom: 5rem;
    }

    .auth_wrapper {
      padding-bottom: 0.5rem;
    }

    .component_FormForm {
      .input-wrapper {
        margin: 25px 0;

        label {
          left: 0;
          text-align: left;
        }

        input {
          color: white;
          height: 48px;
          width: 100%;
        }

        input:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 50px
            ${props => props.theme.colors.bg.primary} inset;
          -webkit-text-fill-color: ${props => props.theme.colors.fg.primary};
        }

        input:-webkit-autofill:focus {
          -webkit-box-shadow: 0 0 0 50px
            ${props => props.theme.colors.bg.primary} inset;
          -webkit-text-fill-color: ${props => props.theme.colors.fg.primary};
        }
      }

      .form_buttons_wrapper {
        width: 100%;
        margin: 2.5rem 0 3rem;

        button {
          width: 100%;
        }
        .component_ui_ButtonSubmit.-submit {
        }
      }

      .textUnderTheAuthForm {
        margin-top: 1rem;
      }
    }
  }
`;

const LayoutAuthFormsStyled = styled(LayoutAuthForms)`
  ${styles}
`;

export default LayoutAuthFormsStyled;
