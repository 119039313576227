import React from 'react';
import { TColumn } from 'components/tables/Table/types';
import { apiConsentsTransactions } from 'services/dataFetching/consents';
import moment from 'moment';

export const columns: Array<TColumn> = [
  {
    Header: 'Date',
    Cell: ({ row }) => (
      <>{moment(row.ValueDateTimeParsed).format('DD/MM/YYYY HH:mm')}</>
    ),
    sortAccessor: 'value_date_time_parsed',
    canSort: true,
  },
  {
    Header: 'Amount',
    Cell: ({ row }) => (
      <>
        {row.Amount.Amount} {row.Amount.Currency}
      </>
    ),
    canSort: true,
    sortAccessor: 'amount_parsed',
  },
  { Header: 'Type', accessor: 'CreditDebitIndicator' },
  {
    Header: 'Balance',
    Cell: ({ row }) => (
      <>
        {row.Balance.Amount.Amount} {row.Balance.Amount.Currency}
      </>
    ),
  },
];

export const tableApiCall = (
  consentId: string,
  dispatch?: any,
  messages?: any,
) => ({
  apiCall: (body: any) =>
    apiConsentsTransactions({ ...body, consent_id: consentId }).then(
      (r: any) => ({
        data: r.body.transactions,
        total: r.body.total,
      }),
    ),
});
