import styled from 'styled-components';
import Table from './Table';
import componentTableStyled from './componentTableStyled';

const TableStyled = styled(Table)`
  padding: 0 2rem;

  .component_TypoH.h1 {
  }

  .horizontalLine {
    width: 100%;
    height: 2px;
    background-color: ${props => props.theme.colors.brand.grayLight50};
  }

  .header_row_wrapper {
    padding-bottom: 1.5rem;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
    .component_ui_Link {
      color: ${props => props.theme.colors.brand.blue};
      font-weight: 600;
      font-size: 18px;
      text-transform: uppercase;

      &:hover {
        color: ${props => props.theme.colors.brand.red};
      }
    }
  }

  .table_layout_global_wrapper {
    .title_wrapper {
      display: grid;
      text-align: left;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      grid-template: 100% / 1fr minmax(0, 150px) min-content;
      grid-gap: 1rem;
      overflow: hidden;

      .col1 {
        overflow: hidden;
      }

      .component_TypoH_wrapper {
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
        text-overflow: ellipsis;
        .component_TypoH.h1 {
          overflow: hidden;
          white-space: nowrap;
          text-align: left;
          text-overflow: ellipsis;
        }
      }

      .topRightPlaceholder {
        color: ${props => props.theme.colors.brand.blue};
        text-transform: uppercase;
        white-space: nowrap;
      }
    }

    .backBtn {
      padding-bottom: 1rem;
      .component_ui_Link {
        display: flex;
        flex-flow: row nowrap;
        min-width: var(--sidebar-width);
        padding: 0;
        color: ${props => props.theme.colors.brand.blue};
        &:hover {
          color: ${props => props.theme.colors.brand.red};
        }

        & > div:first-child {
          padding-right: 1rem;
        }
      }
    }

    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    .table_filter_wrapper {
      max-width: var(--table-width);
      grid-gap: 1rem;
      padding: 1rem 0;
      display: grid;
      grid-auto-flow: column;

      & > div {
        display: grid;
        align-content: center;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        input,
        select {
          padding: 0 1rem;
          font-size: 14px;
          color: ${props => props.theme.colors.brand.primary};
          font-weight: 500;
          background-color: ${props => props.theme.colors.brand.grayLight20};
          border: none;
          height: 50px;
        }
        & > label {
          text-align: left;
        }
      }

      & > div:first-child {
        border-left: none;
      }
    }
  }

  .rootTable {
    ${componentTableStyled};
  }
`;

export * from './utils';

export default TableStyled;
