import styled from 'styled-components';
import PreloaderButton from './PreloaderButton';

const PreloaderButtonStyled = styled(PreloaderButton)`
  .assets_svg svg {
    animation: __spin 1s linear infinite;
  }
`;

export default PreloaderButtonStyled;
