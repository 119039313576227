import config from 'configShared';
import * as constants from './constants';

const reducer: any = () => (next: any) => (action: any) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case constants.TOAST_MESSAGE_ADD:
    case constants.TOAST_MESSAGE_ADD_FAIL:
    case constants.TOAST_MESSAGE_ADD_SUCCESS:
      break;
    case constants.TOAST_MESSAGE_ADD_FAIL_REDIRECT:
      setTimeout(
        () => action.redirect && action.history.push(action.redirect),
        config.defToastRedirectTimeoutFail,
      );
      break;
    case constants.TOAST_MESSAGE_ADD_SUCCESS_REDIRECT:
      setTimeout(
        () => action.redirect && action.history.push(action.redirect),
        config.defToastRedirectTimeoutSuccess,
      );
      break;
    default:
      break;
  }

  return next(action);
};

export default reducer;
