import React from 'react';
import clsx from 'clsx';

const EyeCrossDark = ({ className }: { className?: string }) => {
  return (
    <div className={clsx(className, 'assets_svg', 'eyeCross', '-dark')}>
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.5 9.1106C19.5 9.13977 19.4777 9.26091 19.3526 9.49001C19.2359 9.70387 19.0553 9.9654 18.8112 10.2594C18.3237 10.8462 17.6098 11.5295 16.7258 12.1796C14.9522 13.4838 12.5543 14.6106 10 14.6106C7.49311 14.6106 5.09426 13.5067 3.30789 12.2136C2.41777 11.5693 1.69555 10.8892 1.20145 10.2988C0.954018 10.0031 0.770858 9.73836 0.652205 9.51928C0.526577 9.28732 0.5 9.15517 0.5 9.1106C0.5 9.08514 0.5234 8.96349 0.669822 8.72702C0.80585 8.50732 1.01484 8.24115 1.29358 7.94296C1.84977 7.34795 2.6481 6.66328 3.59201 6.01461C5.49489 4.70691 7.89339 3.6106 10 3.6106C12.6044 3.6106 15.0021 4.73829 16.7613 6.04068C17.6385 6.69008 18.3429 7.37276 18.8226 7.95901C19.0629 8.25262 19.2402 8.51399 19.3548 8.7278C19.4773 8.95632 19.5 9.07891 19.5 9.1106Z"
          fill="#3C3C3B"
          stroke="#B8B4B2"
        />
        <circle cx="10" cy="9.1106" r="3.5" stroke="#B8B4B2" />
        <rect
          x="15.342"
          y="1.29453"
          width="2"
          height="18.6678"
          transform="rotate(41.0994 15.342 1.29453)"
          fill="#B8B4B2"
          stroke="#3C3C3B"
        />
      </svg>
    </div>
  );
};

export default EyeCrossDark;
