import styled from 'styled-components';
import Tabs from './Tabs';

const TabsStyled = styled(Tabs)`
  .tabPanel_wrapper {
    position: relative;
    min-height: 300px;
  }
  .tabLabels_wrapper {
    padding: 2rem 0.5rem;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 2rem;
    width: max-content;
    text-transform: uppercase;

    .tabLabel {
      cursor: pointer;
      transition: all 150ms;
      &:hover {
        color: ${$props => $props.theme.colors.brand.orange};
      }
      &.--active {
        color: ${$props => $props.theme.colors.brand.blue};
      }
    }
  }
`;

export * from './Tabs';
export default TabsStyled;
