import React from 'react';
import clsx from 'clsx';

const EyeDark = ({ className }: { className?: string }) => {
  return (
    <div className={clsx(className, 'assets_svg', 'eye', '-dark')}>
      <svg
        width="20"
        height="14"
        viewBox="0 0 20 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.5 7.12519C19.5 7.15494 19.4775 7.27667 19.3524 7.50626C19.2356 7.72065 19.055 7.98278 18.8108 8.27735C18.3233 8.86548 17.6094 9.55023 16.7255 10.2016C14.9517 11.5086 12.554 12.6375 10 12.6375C7.4934 12.6375 5.09469 11.5315 3.30828 10.2357C2.41816 9.59007 1.69592 8.90852 1.20178 8.31686C0.954322 8.02056 0.771115 7.75521 0.652409 7.53558C0.526764 7.30311 0.5 7.17037 0.5 7.12519C0.5 7.09911 0.523643 6.97685 0.670063 6.73989C0.806144 6.51967 1.01517 6.2529 1.29392 5.95408C1.85015 5.35782 2.6485 4.67173 3.5924 4.02173C5.49534 2.71131 7.89369 1.61287 10 1.61287C12.6041 1.61287 15.0017 2.74276 16.7609 4.04785C17.6381 4.69859 18.3425 5.3827 18.8223 5.9702C19.0626 6.26444 19.24 6.5264 19.3546 6.74074C19.4771 6.96976 19.5 7.09293 19.5 7.12519Z"
          fill="#3C3C3B"
          stroke="#B8B4B2"
        />
        <path
          d="M13.5 7.1252C13.5 9.06371 11.932 10.6334 10 10.6334C8.06797 10.6334 6.5 9.06371 6.5 7.1252C6.5 5.1867 8.06797 3.61699 10 3.61699C11.932 3.61699 13.5 5.1867 13.5 7.1252Z"
          stroke="#B8B4B2"
        />
      </svg>
    </div>
  );
};

export default EyeDark;
