import React, { useCallback } from 'react';
import Table from 'components/tables/Table/Table';
import { constants } from 'configShared/store';
import { useDispatch, useSelector } from 'react-redux';
import getDispatchWrapper from 'utils/getDispatchWrapper';
import clsx from 'clsx';
import { columns, tableApiCall } from './tableConfig';

const Transactions: React.FC<{ className?: string }> = ({ className }) => {
  const dispatchRedux = useDispatch();
  const dispatch = useCallback(
    getDispatchWrapper(constants.CONSENTS_TRANSACTIONS, dispatchRedux),
    [constants.CONSENTS_TRANSACTIONS, dispatchRedux],
  );
  const consentId = useSelector(
    (state: any) => state[constants.CONSENTS].identityCheckResults.consentId,
  );

  const tableState = useSelector(
    (state: any) => state[constants.CONSENTS][constants.CONSENTS_TRANSACTIONS],
  );

  const tableApiCallMemo = React.useMemo(
    () => tableApiCall(consentId, dispatch),
    [dispatch, consentId],
  );

  return (
    <div className={clsx(className, 'Transactions')}>
      <Table
        dispatch={dispatch}
        state={tableState}
        apiCallWrapper={tableApiCallMemo}
        columns={columns}
        params={{
          isRowEditable: () => false,
        }}
      />
    </div>
  );
};

export default Transactions;
