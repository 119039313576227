import * as constants from '../constants';

export * from './auth';
export * from './identityCheckResults';
export * from 'components/tables/Table/reducer/actions';
export * from 'components/forms/CrudForm/reducer/actions';
export * from 'components/ui/Toast/reducer/actions';

export function globalCleanUp() {
  return {
    type: constants.GLOBAL_CLEAN_UP,
  };
}
