import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

const Typography = styled.div``;
const BankStyled = styled.div`
  font-weight: 800;
  text-transform: uppercase;
`;
const StatusStyled = styled.div`
  font-weight: 800;
  font-size: 13px;
  text-transform: uppercase;
  background-color: ${props => props.theme.colors.brand.blue};
  color: white;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const General: React.FC<{ generalData: any }> = ({ generalData }) => {
  const { consent } = generalData || {};
  const { psu_email, bank, created_at, status, customer, psu_account } = consent || {};
  const { first_name, last_name, company_name } = customer || {};
  const customerFullName = `${first_name} ${last_name} ${company_name &&
    `/ ${company_name}`}`;
  const {
    is_joint,
    address_line_1,
    address_line_2,
    county,
    first_name: psu_first_name,
    last_name: psu_last_name,
    postcode,
    salutation,
    town,
  } = psu_account || {};
  const address = `${postcode} ${county} ${town} ${address_line_1} ${address_line_2}`;
  const accountFullLegalName = `${_.capitalize(
    salutation,
  )}. ${psu_first_name} ${psu_last_name}`;

  return (
    <div className={'general'}>
      <div className={'reportRowsWrapper'}>
        <div className={'reportSingleRow'}>
          <Typography>Request Date</Typography>
          <Typography>
            {moment(created_at).format('DD MMM YYYY HH:mm')}
          </Typography>
        </div>
        <div className={'reportSingleRow'}>
          <Typography>Requested By</Typography>
          <Typography>{customerFullName}</Typography>
        </div>
        <div className={'reportSingleRow'}>
          <Typography>Status</Typography>
          <Typography>
            <StatusStyled>{status}</StatusStyled>
          </Typography>
        </div>
        <div className={'reportSingleRow'}>
          <Typography>Bank</Typography>
          <Typography>
            <BankStyled>{bank}</BankStyled>
          </Typography>
        </div>
        <div className={'reportSingleRow'}>
          <Typography>Account Name</Typography>
          <Typography>{psu_email}</Typography>
        </div>
        <div className={'reportSingleRow'}>
          <Typography>Account full legal name</Typography>
          <Typography>{accountFullLegalName}</Typography>
        </div>
        <div className={'reportSingleRow'}>
          <Typography>Address</Typography>
          <Typography>{address}</Typography>
        </div>
        <div className={'reportSingleRow'}>
          <Typography>Account type</Typography>
          <Typography>{is_joint ? 'Joint' : 'Single'}</Typography>
        </div>
      </div>
    </div>
  );
};

export default General;
