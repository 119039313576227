import Link from 'components/ui/Link';
import RoundTrianlge from 'assets/svg/icons/RoundTriangle/RoundTrianlge';
import React from 'react';
import { crudFormSetMode } from 'store/actions';

// eslint-disable-next-line import/prefer-default-export
export const backBtn = (dispatch: any, title: string) => (
  <div className="backBtn">
    <Link
      handleOnClick={() => {
        dispatch(crudFormSetMode({ tab: 'root' }));
      }}
    >
      <RoundTrianlge />
      <div className="title">{title}</div>
    </Link>
  </div>
);

// eslint-disable-next-line import/prefer-default-export
export const crudBtnAdd = (dispatch: any, title: string) => (
  <Link
    handleOnClick={() =>
      dispatch(
        crudFormSetMode({
          tab: 'crud-add',
        }),
      )
    }
  >
    {title}
  </Link>
);
