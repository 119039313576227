export const reducerApplyFilters = (
  reducer: any,
  filters: Array<any>,
  initState: any,
) => (state: any, action: any) =>
  filters.reduce(
    (acc, filter) => filter(reducer(initState), acc, action, initState),
    state,
  );

export const reducerFilterOnGlobalReset = (actionConstant: string) => (
  reducer: any,
  state: any,
  action: any,
  initState: any,
) => (action.type === actionConstant ? initState : state);

export const reducerFilterByReducerId = (reducerId: string) => (
  reducer: any,
  state: any,
  action: any,
  initState: any,
) =>
  action.reducerId === reducerId ? reducer(state, action) : state || initState;

export const reducerPass = (reducer: any, state: any, action: any) =>
  reducer(state, action);
