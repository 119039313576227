import * as constants from './constants';

export const tableSetSort = (sort: { order?: boolean; by: string }) => {
  return {
    type: constants.TABLE_SET_SORT,
    sort,
  };
};

export const tableSetStatus = (status: string) => {
  return {
    type: constants.TABLE_SET_STATUS,
    status,
  };
};

export const tableSetPeriod = (period: string) => {
  return {
    type: constants.TABLE_SET_PERIOD,
    period,
  };
};

export const tableSetSearchPsu = (search: string) => {
  return {
    type: constants.TABLE_SET_SEARCH_PSU,
    search,
  };
};

export const tableSetSearchCustomer = (search: string) => {
  return {
    type: constants.TABLE_SET_SEARCH_CUSTOMER,
    search,
  };
};

export const tableSetOffset = (offset: number) => {
  return {
    type: constants.TABLE_SET_OFFSET,
    offset,
  };
};

export const tableSetData = (data: Array<any>) => {
  return {
    type: constants.TABLE_SET_DATA,
    data,
  };
};

export const tableOnReset = () => {
  return {
    type: constants.TABLE_ON_RESET,
  };
};

export const tableOnDataFetch = (payload: any) => {
  return {
    type: constants.TABLE_ON_DATA_FETCH,
    payload,
  };
};

export const tableOnDataFetchStart = () => {
  return {
    type: constants.TABLE_ON_DATA_FETCH_START,
  };
};

export const tableOnDataFetchFinish = (resp: any) => {
  return {
    type: constants.TABLE_ON_DATA_FETCH_FINISH,
    resp,
  };
};

export const tableOnDataFetchFail = (error: any) => {
  return {
    type: constants.TABLE_ON_DATA_FETCH_FAIL,
    error,
  };
};

export const forceUpdate = () => {
  return {
    type: constants.TABLE_FORCE_UPDATE,
  };
};

export default {
  onDataFetchFinish: tableOnDataFetchFinish,
  onDataFetchStart: tableOnDataFetchStart,
  onDataFetchFail: tableOnDataFetchFail,
  onDataFetch: tableOnDataFetch,
  forceUpdate,
  setSort: tableSetSort,
  setOffset: tableSetOffset,
  setData: tableSetData,
  setStatus: tableSetStatus,
  setPeriod: tableSetPeriod,
  setSearchCustomer: tableSetSearchCustomer,
  setSearchPsu: tableSetSearchPsu,
  onReset: tableOnReset,
};
