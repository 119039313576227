import React from 'react';
import clsx from 'clsx';

type TPaper = {
  className?: string;
  children: React.ReactElement;
  shadowDeep?: number;
};

const Paper: React.FC<TPaper> = ({ className, children }) => {
  return (
    <div className={clsx(className, 'component_ui_Paper')}>{children}</div>
  );
};

export default Paper;
