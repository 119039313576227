import React from 'react';
import clsx from 'clsx';
import PreloaderButton from 'components/ui/PreloaderButton';

type TButtonSubmit = {
  className?: string;
  isProcessing?: boolean;
  disabled?: boolean;
  label?: string;
  variant?: 'primary' | 'secondary' | 'third';
  onClick?: (event?: React.MouseEvent | never) => void;
  type: 'button' | 'reset' | 'submit';
};

const ButtonSubmit: React.FC<TButtonSubmit> = ({
  className,
  isProcessing = false,
  disabled = false,
  variant = 'primary',
  label,
  onClick,
  children,
  type = 'button',
}) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={clsx(
        className,
        'component_ui_ButtonSubmit',
        isProcessing && '--processing',
        disabled && '--disable',
        `--${variant}`,
        `--${type}`,
      )}
      type={type}
      onClick={onClick}
    >
      {isProcessing ? <PreloaderButton /> : label || children}
    </button>
  );
};

export default ButtonSubmit;
