import styled from 'styled-components';
import Link from './Link';

const LinkStyled = styled(Link as any)<any>`
  cursor: pointer !important;
  user-select: none !important;
  background-color: transparent;
  border: none; 
  font-size: 1rem;
  color: ${({theme}) => theme.colors.brand.white};
  transition: all 150ms;

  &:hover {
    color: ${({theme}) => theme.colors.brand.orange};
  }
`;

export default LinkStyled;
