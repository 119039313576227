import React from 'react';
import clsx from 'clsx';

const LogoDarkAdmin = ({ className }: { className?: string }) => {
  return (
    <div
      className={clsx(
        className,
        'assets_svg',
        'trustloop_logo',
        '-dark',
        '-wAdminText',
      )}
    >
      <svg
        width="182"
        height="75"
        viewBox="0 0 182 75"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M74.4616 67L77.1216 60.14H78.3316L80.9916 67H79.9816L79.2916 65.17H76.1116L75.4416 67H74.4616ZM76.4216 64.34H78.9816L78.1816 62.2L77.7216 60.96H77.6716L77.2216 62.18L76.4216 64.34ZM82.3515 60.14H84.8915C85.9915 60.14 86.8382 60.4233 87.4315 60.99C88.0249 61.55 88.3215 62.41 88.3215 63.57C88.3215 64.73 88.0249 65.5933 87.4315 66.16C86.8382 66.72 85.9915 67 84.8915 67H82.3515V60.14ZM84.8915 66.17C85.6849 66.17 86.2882 65.97 86.7015 65.57C87.1215 65.17 87.3349 64.51 87.3415 63.59V63.57C87.3415 62.6433 87.1315 61.98 86.7115 61.58C86.2982 61.1733 85.6915 60.97 84.8915 60.97H83.3015V66.17H84.8915ZM90.0683 60.14H91.4783L93.1183 64.82C93.1783 64.9933 93.2383 65.1933 93.2983 65.42C93.365 65.64 93.4083 65.7833 93.4283 65.85H93.4683C93.4883 65.7767 93.5283 65.6233 93.5883 65.39C93.655 65.1567 93.715 64.9667 93.7683 64.82L95.4183 60.14H96.8183V67H95.8683V62.47L95.8883 61.19H95.8183C95.8117 61.2367 95.7917 61.3333 95.7583 61.48C95.725 61.62 95.6883 61.7433 95.6483 61.85L93.7883 67H92.9983L91.1683 61.85C91.135 61.7567 91.0983 61.6367 91.0583 61.49C91.025 61.3367 91.005 61.2367 90.9983 61.19H90.9383L90.9583 62.47V67H90.0683V60.14ZM99.0284 67V60.14H99.9784V67H99.0284ZM102.182 67V60.14H103.032L106.652 64.92L106.892 65.24L106.932 65.23V64.76V60.14H107.822V67H107.012L103.372 62.16L103.112 61.81L103.072 61.82V62.29V67H102.182Z"
          fill="#AAB0AF"
        />
        <rect opacity="0.3" y="63" width="58" height="1" fill="#AAB0AF" />
        <rect
          opacity="0.3"
          x="124"
          y="63"
          width="58"
          height="1"
          fill="#AAB0AF"
        />
        <path
          d="M51.9797 27.99C51.2406 27.0738 50.8714 25.9173 50.8714 24.5199V14.7275H48.6902V10.8793H50.9753L51.7719 5.79425H55.6151V10.8797H58.7657V14.7278H55.6151V24.0391C55.6151 25.0237 56.1111 25.5158 57.1036 25.5158H58.7657V28.8145C58.3729 28.9516 57.8827 29.077 57.2943 29.1929C56.7055 29.3066 56.1575 29.364 55.6498 29.364C53.9413 29.3644 52.718 28.9063 51.9797 27.99Z"
          fill="white"
        />
        <path
          d="M61.2067 10.8798H65.2577L65.6383 13.8001H65.8808C66.2732 12.7694 66.7809 11.957 67.4043 11.361C68.0273 10.7654 68.8464 10.4678 69.8626 10.4678C70.3006 10.4678 70.6877 10.5078 71.0222 10.5879C71.3564 10.6683 71.5703 10.731 71.6632 10.7767V15.1748H70.3471C68.8007 15.1748 67.6814 15.5929 66.9887 16.4287C66.2964 17.2653 65.95 18.5305 65.95 20.2259V28.9529H61.2067V10.8798Z"
          fill="white"
        />
        <path
          d="M75.1009 27.8528C74.0276 26.8451 73.491 25.2306 73.491 23.0079V10.8799H78.2343V22.0124C78.2343 23.1344 78.4992 23.948 79.0305 24.4518C79.5611 24.9556 80.3463 25.2071 81.3849 25.2071C82.4464 25.2071 83.329 24.8355 84.0339 24.0911C84.7376 23.3467 85.0893 22.424 85.0893 21.325V10.8799H89.8326V28.9522H85.7819L85.436 26.5815H85.1935C83.7161 28.4374 81.8234 29.3646 79.5154 29.3646C77.6456 29.3642 76.1742 28.8604 75.1009 27.8528Z"
          fill="white"
        />
        <path
          d="M94.594 27.8012C93.1512 26.7588 92.4292 25.3678 92.4292 23.6267L92.4642 23.2143H97.0694V23.4548C97.0919 24.257 97.4554 24.8353 98.1602 25.1899C98.8643 25.5453 99.7233 25.7224 100.739 25.7224C101.686 25.7224 102.493 25.597 103.163 25.344C103.832 25.0925 104.167 24.68 104.167 24.1078C104.167 23.4669 103.827 22.9858 103.146 22.6644C102.465 22.3441 101.397 22.0242 99.9433 21.7032C98.4426 21.3591 97.219 21.0105 96.2725 20.6551C95.3263 20.3005 94.5069 19.7441 93.8142 18.9888C93.1223 18.2331 92.7755 17.2254 92.7755 15.9655C92.7755 14.2017 93.5196 12.8443 95.0085 11.8937C96.4974 10.9435 98.419 10.468 100.773 10.468C102.966 10.468 104.777 10.9091 106.209 11.7906C107.64 12.6729 108.356 13.8694 108.356 15.3812L108.322 16.1713H103.717V16.1026C103.694 15.4386 103.406 14.9405 102.851 14.6081C102.298 14.2761 101.547 14.1099 100.601 14.1099C99.6544 14.1099 98.905 14.2361 98.3505 14.4876C97.7968 14.7403 97.5193 15.0949 97.5193 15.5531C97.5193 16.1256 97.8367 16.5494 98.4715 16.8243C99.1064 17.0993 100.128 17.3738 101.536 17.6488C103.082 17.9921 104.34 18.3362 105.31 18.6795C106.279 19.0228 107.121 19.5844 107.837 20.3632C108.553 21.1419 108.911 22.1964 108.911 23.524C108.911 27.4182 106.129 29.3647 100.566 29.3647C98.027 29.3644 96.0365 28.8436 94.594 27.8012Z"
          fill="white"
        />
        <path
          d="M113.096 27.99C112.358 27.0738 111.989 25.9173 111.989 24.5199V14.7275H109.808V10.8793H112.093L112.889 5.79425H116.732V10.8797H119.883V14.7278H116.732V24.0391C116.732 25.0237 117.228 25.5158 118.221 25.5158H119.883V28.8145C119.49 28.9516 119 29.077 118.411 29.1929C117.823 29.3066 117.274 29.364 116.767 29.364C115.058 29.3644 113.835 28.9063 113.096 27.99Z"
          fill="white"
        />
        <path
          d="M122.296 4.11087H125.343V28.9519H122.296V4.11087Z"
          fill="#AAB0AF"
        />
        <path
          d="M140.185 25.2588C141.017 24.1937 141.432 22.4587 141.432 20.054V19.7786C141.432 17.3736 141.016 15.6385 140.185 14.5731C139.355 13.5081 138.016 12.9755 136.169 12.9755C134.322 12.9755 132.984 13.5081 132.153 14.5731C131.321 15.6385 130.906 17.3736 130.906 19.7786V20.054C130.906 22.4587 131.322 24.1937 132.153 25.2588C132.984 26.3238 134.322 26.856 136.169 26.856C138.016 26.8556 139.355 26.3238 140.185 25.2588ZM129.868 27.0452C128.46 25.499 127.755 23.123 127.755 19.9161C127.755 16.7093 128.46 14.3329 129.868 12.7867C131.275 11.2405 133.376 10.4674 136.169 10.4674C138.962 10.4674 141.063 11.2405 142.472 12.7867C143.879 14.3329 144.584 16.7093 144.584 19.9161C144.584 23.123 143.879 25.499 142.471 27.0452C141.063 28.5914 138.962 29.3645 136.169 29.3645C133.376 29.3645 131.275 28.5914 129.868 27.0452Z"
          fill="#AAB0AF"
        />
        <path
          d="M158.505 25.2588C159.336 24.1937 159.751 22.4587 159.751 20.054V19.7786C159.751 17.3736 159.336 15.6385 158.505 14.5731C157.675 13.5081 156.335 12.9755 154.489 12.9755C152.642 12.9755 151.303 13.5081 150.472 14.5731C149.641 15.6385 149.226 17.3736 149.226 19.7786V20.054C149.226 22.4587 149.641 24.1937 150.472 25.2588C151.303 26.3238 152.641 26.856 154.489 26.856C156.335 26.8556 157.675 26.3238 158.505 25.2588ZM148.187 27.0452C146.778 25.499 146.075 23.123 146.075 19.9161C146.075 16.7093 146.778 14.3329 148.187 12.7867C149.595 11.2405 151.696 10.4674 154.489 10.4674C157.281 10.4674 159.382 11.2405 160.791 12.7867C162.198 14.3329 162.903 16.7093 162.903 19.9161C162.903 23.123 162.198 25.499 160.79 27.0452C159.382 28.5914 157.281 29.3645 154.489 29.3645C151.696 29.3645 149.595 28.5914 148.187 27.0452Z"
          fill="#AAB0AF"
        />
        <path
          d="M175.841 26.2034C176.534 25.7683 177.041 25.0586 177.365 24.0729C177.688 23.0883 177.849 21.7486 177.849 20.0532V19.9158C177.849 18.1977 177.688 16.8354 177.365 15.827C177.041 14.8194 176.528 14.092 175.824 13.6452C175.119 13.1984 174.191 12.9752 173.037 12.9752C171.421 12.9752 170.232 13.5594 169.471 14.7276C168.709 15.8958 168.328 17.6255 168.328 19.9158V20.0532C168.328 22.2978 168.703 23.9932 169.453 25.1379C170.203 26.2834 171.398 26.856 173.037 26.856C174.214 26.8564 175.148 26.6388 175.841 26.2034ZM165.281 10.8798H167.566L167.947 13.3192H168.154C168.755 12.3803 169.545 11.6703 170.526 11.1891C171.507 10.708 172.598 10.4674 173.798 10.4674C176.106 10.4674 177.884 11.2352 179.131 12.7693C180.377 14.3046 181 16.6862 181 19.9161C181 23.0766 180.382 25.4423 179.148 27.0112C177.912 28.5801 176.222 29.3645 174.076 29.3645C172.783 29.3645 171.687 29.1696 170.786 28.7803C169.885 28.3916 169.135 27.7617 168.535 26.8903H168.327V34.896H165.281V10.8798Z"
          fill="#AAB0AF"
        />
        <path
          d="M31.5293 11.2469C28.8647 11.2469 26.6968 9.09562 26.6968 6.4515C26.6968 4.96911 27.3789 3.64195 28.4472 2.76158C25.7393 1.09451 22.5715 0.0955486 19.1801 -3.8147e-06V7.17739C25.0523 7.44819 29.78 12.1397 30.0533 17.9673H37.2854C37.2002 14.99 36.3998 12.1888 35.054 9.72143C34.1714 10.6573 32.92 11.2469 31.5293 11.2469Z"
          fill="#FFB349"
        />
        <path
          d="M18.1061 7.1769V0.000253677C14.7142 0.0954285 11.5465 1.09439 8.83859 2.76184C9.90692 3.64221 10.589 4.96899 10.589 6.45175C10.589 9.09588 8.42107 11.2468 5.75652 11.2468C4.36582 11.2468 3.11404 10.6576 2.23182 9.72131C0.886029 12.1891 0.0852536 14.9899 0 17.9672H7.23247C7.50536 12.1396 12.2335 7.44769 18.1061 7.1769Z"
          fill="#52C8DA"
        />
        <path
          d="M7.23279 19.0319H0.000701904C0.0859555 22.0091 0.88635 24.8107 2.23214 27.2773C3.11474 26.3418 4.36614 25.7519 5.75684 25.7519C8.4214 25.7519 10.5893 27.9035 10.5893 30.5473C10.5893 32.03 9.90725 33.3568 8.83929 34.2372C11.5469 35.9043 14.7146 36.9032 18.1061 36.9988V29.8214C12.2338 29.5517 7.50568 24.8594 7.23279 19.0319Z"
          fill="#FF6E7A"
        />
        <path
          d="M26.6968 30.5481C26.6968 27.9044 28.8647 25.7527 31.5292 25.7527C32.9199 25.7527 34.1713 26.3423 35.0539 27.2782C36.3997 24.8108 37.2001 22.0099 37.2854 19.0331H30.0529C29.78 24.8603 25.0519 29.5526 19.1797 29.8234V37C22.5712 36.9041 25.7389 35.9055 28.4468 34.2388C27.3788 33.358 26.6968 32.0309 26.6968 30.5481Z"
          fill="#AAB0AF"
        />
        <path
          d="M27.7706 6.45158C27.7706 8.50804 29.4567 10.1812 31.529 10.1812C33.6017 10.1812 35.2878 8.50804 35.2878 6.45158C35.2878 4.39512 33.6017 2.72201 31.529 2.72201C29.4567 2.72201 27.7706 4.39512 27.7706 6.45158Z"
          fill="#FFB349"
        />
        <path
          d="M31.5291 26.8186C29.4568 26.8186 27.7708 28.4917 27.7708 30.5481C27.7708 32.6046 29.4568 34.2777 31.5291 34.2777C33.6015 34.2777 35.2875 32.6046 35.2875 30.5481C35.2875 28.4917 33.6015 26.8186 31.5291 26.8186Z"
          fill="#AAB0AF"
        />
        <path
          d="M5.75631 10.1814C7.82866 10.1814 9.5147 8.50828 9.5147 6.45182C9.5147 4.39499 7.82866 2.72187 5.75631 2.72187C3.68397 2.72187 1.99792 4.39499 1.99792 6.45182C1.99792 8.50828 3.68397 10.1814 5.75631 10.1814Z"
          fill="#52C8DA"
        />
        <path
          d="M5.75631 34.2777C7.82866 34.2777 9.5147 32.6046 9.5147 30.5481C9.5147 28.4917 7.82866 26.8186 5.75631 26.8186C3.68397 26.8186 1.99792 28.4917 1.99792 30.5481C1.99792 32.6046 3.68397 34.2777 5.75631 34.2777Z"
          fill="#FF6E7A"
        />
      </svg>
    </div>
  );
};

export default LogoDarkAdmin;
