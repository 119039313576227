import React from 'react';
import clsx from 'clsx';
import config from 'configShared';
import SvgLogoDarkAdmin from 'assets/svg/logo/LogoDarkAdmin';
import Link from 'components/ui/SidebarLink';
import { useHistory } from 'react-router-dom';

type TSidebar = {
  className?: string;
  children?: (React.ReactElement | null)[];
};

const Sidebar: React.FC<TSidebar> = ({ className, children = [] }) => {
  const history = useHistory();

  return (
    <div className={clsx(className, 'layout_Dashboard_Sidebar')}>
      <div className="inner_wrapper">
        <div className="sidebarHeader">
          <Link
            href="#signIn"
            handleOnClick={() => history.push(config.routes.auth.signIn)}
          >
            <SvgLogoDarkAdmin />
          </Link>
        </div>
        <div className={clsx('navLinksChild_wrapper')}>
          {React.Children.map(children, e => (
            <div className="navLinkElement">{e}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
