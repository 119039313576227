import * as actions from './reducer/actions';
import * as globalActions from 'store/actions';
import { TApiCallCrudObj } from './types';

const onSubmit = (
  dispatch: (args: any) => void,
  apiCallWrapper: TApiCallCrudObj,
  params: any = { reset: false, goToRoot: false },
) => (values: any, helpers: any = undefined) => {
  dispatch(
    actions.crudFormOnSubmitData({
      onStart: () => dispatch(actions.crudFormOnSubmitDataStart()),
      apiCall: () => apiCallWrapper.apiCall(values),
      onFinish: (resp: any) => {
        if (apiCallWrapper.onFinish) apiCallWrapper.onFinish(resp);
        dispatch(actions.crudFormOnSubmitDataFinish({ dispatch, resp }));
        dispatch(globalActions.toastMessageAddSuccess({ msg: 'Success' }));
        if (params && params.reset) helpers.resetForm();
        if (params && params.goToRoot)
          dispatch(actions.crudFormSetMode({ tab: 'root' }));
      },
      onFail: (error: any) => {
        if (apiCallWrapper.onFail) apiCallWrapper.onFail(error);
        dispatch(actions.crudFormOnSubmitDataFail(error));
        dispatch(globalActions.toastMessageAddFail({ ...error }));
      },
    }),
  );
};

export default onSubmit;
