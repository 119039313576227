import styled from 'styled-components';
import Container from './Container';

const ContainerStyled = styled(Container)`
  width: ${props => props.maxWidth};
  max-width: ${props => props.maxWidth};
  margin-left: auto;
  margin-right: auto;
`;

export default ContainerStyled;
