import * as Yup from 'yup';
import IntlMessageFormat from 'intl-messageformat';
import { TMsg } from 'appTypes/messages';

const constructValidators = (messages: TMsg) => {
  /* Requirements
   * ============
   */
  /* Min */
  const msgMin = new IntlMessageFormat(messages.form.requirements.min);
  const msgMinFunc = ({ min }: { min: number }) => msgMin.format({ min });

  /* Max */
  const msgMax = new IntlMessageFormat(messages.form.requirements.max);
  const msgMaxFunc = ({ max }: { max: number }) => msgMax.format({ max });

  /* Matches */
  const msgMatches = new IntlMessageFormat(messages.form.requirements.matches);
  const msgMinMaxFunc = (
    minMax: 'min' | 'max',
    amount: number,
    matchesType: string,
  ) => msgMatches.format({ minMax, amount, matchesType });

  /** More than 1 digit */
  const needMoreThanOneDigit: [RegExp, () => string] = [
    /\d+/,
    () => msgMinMaxFunc('min', 1, 'digit'),
  ];

  /** More than 1 uppercase character */
  const needMoreThanOneUppercase: [RegExp, () => string] = [
    /[A-Z]+/,
    () => msgMinMaxFunc('min', 1, 'uppercase character'),
  ];

  /** More than 1 lowercase character */
  const needMoreThanOneLowercase: [RegExp, () => string] = [
    /[a-z]+/,
    () => msgMinMaxFunc('min', 1, 'lowercase character'),
  ];

  /** More than 1 lowercase character */
  const needMoreThanOneSpecChars: [RegExp, () => string] = [
    /[-_!@#$%^&*()+={}[\]:;"',./?\\|~]+/,
    () => msgMinMaxFunc('min', 1, 'special character'),
  ];

  const numbersOnly: [RegExp, () => string] = [
    /^[\d ]+$/,
    () => 'Must be numbers only',
  ];

  const lettersOnly: [RegExp, () => string] = [
    /^[0-9a-zA-Z-"@',.`: ]+$/,
    () => 'Must be latin letters only',
  ];

  const numbersAndSpecialCharsOnly: [RegExp, () => string] = [
    /^[\d\W]+$/,
    () => 'Must be numbers and special characters only',
  ];

  /* Require */
  const msgReq = new IntlMessageFormat(
    messages.form.requirements.required || 'email error',
  );
  const msgReqFunc = () => msgReq.format();

  /*
   * Errors
   * ======
   */
  const msgEmail = new IntlMessageFormat(messages.form.errors.email);
  const msgEmailFunc = () => msgEmail.format();

  const password = Yup.string()
    .min(8, msgMinFunc)
    .max(50, msgMaxFunc)
    .matches(...needMoreThanOneDigit)
    .matches(...needMoreThanOneUppercase)
    .matches(...needMoreThanOneLowercase)
    .matches(...needMoreThanOneSpecChars)
    .required(msgReqFunc());

  return {
    credits: Yup.string()
      .trim()
      .required(msgReqFunc())
      .max(5, msgMaxFunc)
      .matches(...numbersOnly),
    amount: Yup.string()
      .trim()
      .required(msgReqFunc())
      .max(5, msgMaxFunc)
      .matches(...numbersOnly),
    number: Yup.string()
      .max(20, msgMaxFunc)
      .matches(...numbersOnly),
    title: Yup.string()
      .trim()
      .required(msgReqFunc())
      .min(1, msgMinFunc)
      .max(50, msgMaxFunc),
    currency: Yup.string()
      .required(msgReqFunc())
      .matches(/^((?!(label)).)*$/, () => 'Choose currency'),
    pricingPlan: Yup.string(),
    string: Yup.string().max(30, msgMaxFunc),
    phone: Yup.string()
      .min(12, msgMinFunc)
      .matches(...numbersAndSpecialCharsOnly),
    first_name: Yup.string()
      .trim()
      .min(2, msgMinFunc)
      .max(30, msgMaxFunc)
      .matches(...lettersOnly)
      .required(msgReqFunc()),
    last_name: Yup.string()
      .trim()
      .min(2, msgMinFunc)
      .max(30, msgMaxFunc)
      .matches(...lettersOnly)
      .required(msgReqFunc()),
    email: Yup.string()
      .email(msgEmailFunc())
      .max(50, msgMaxFunc)
      .required(msgReqFunc()),
    password,
    passwordMatch: password
      .test('passwordMatch', 'Passwords must match', function(value) {
        return this.parent.password === value;
      })
      .required(msgReqFunc()),
    companyName: Yup.string()
      .min(1, msgMinFunc)
      .max(50, msgMaxFunc),
    companyNumber: Yup.string()
      .matches(...numbersOnly)
      .min(1, msgMinFunc)
      .max(30, msgMaxFunc),
    companySector: Yup.string(),
    companyEmployeesNumber: Yup.string(),
    companyPostcode: Yup.string()
      .min(1, msgMinFunc)
      .max(20, msgMaxFunc),
    companyBuilding: Yup.string()
      .min(1, msgMinFunc)
      .max(30, msgMaxFunc),
  };
};

const validators = (messages: TMsg): TMsg => {
  const {
    first_name,
    last_name,
    email,
    companyNumber,
    companyName,
    companySector,
    companyEmployeesNumber,
    companyPostcode,
    companyBuilding,
    password,
    phone,
    title,
    pricingPlan,
    credits,
    amount,
    currency,
    passwordMatch,
  } = constructValidators(messages);

  const firstName = first_name;
  const lastName = last_name;
  const simple_password = password;

  return {
    auth: {
      confirmResetPassword: Yup.object({
        password,
        passwordMatch,
      }),
      resetPassword: Yup.object({
        email,
      }),
      signUp: Yup.object({
        first_name,
        last_name,
        email,
        password,
      }),
      signIn: Yup.object({
        email,
        password,
      }),
    },
    dashboard: {
      admins: {
        add: Yup.object({
          email,
          simple_password,
        }),
        update: Yup.object({
          first_name,
          last_name,
          email,
        }),
        delete: Yup.object(),
      },
      customers: {
        add: Yup.object({
          first_name,
          last_name,
          email,
          phone,
          companyNumber,
          companyName,
          companySector,
          companyEmployeesNumber,
          companyPostcode,
          companyBuilding,
          pricingPlan,
        }),
        update: Yup.object({
          first_name,
          last_name,
          email,
          phone,
          companyNumber,
          companyName,
          companySector,
          companyEmployeesNumber,
          companyPostcode,
          companyBuilding,
          pricingPlan,
        }),
        delete: Yup.object(),
      },
      psus: {
        add: Yup.object({
          firstName,
          lastName,
          email,
          phone,
        }),
        update: Yup.object({
          firstName,
          lastName,
          email,
          phone,
        }),
        delete: Yup.object(),
      },
      pricingPlans: {
        add: Yup.object({
          title,
          currency,
          amount,
          credits,
        }),
        update: Yup.object({
          title,
        }),
        delete: Yup.object(),
      },
      pricingPlansPackage: {
        add: Yup.object({
          currency,
          amount,
          credits,
        }),
        update: Yup.object({
          currency,
          amount,
          credits,
        }),
        delete: Yup.object(),
      },
    },
  };
};

export default validators;
