export const IDENTITY_CHECK_RESULTS_SET_TAB = 'IDENTITY_CHECK_RESULTS_SET_TAB';
export type IDENTITY_CHECK_RESULTS_SET_TAB = typeof IDENTITY_CHECK_RESULTS_SET_TAB;

/** SUMMARY */
export const IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY =
  'IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY';
export type IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY = typeof IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY;

export const IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY_START =
  'IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY_START';
export type IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY_START = typeof IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY_START;

export const IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY_FINISH =
  'IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY_FINISH';
export type IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY_FINISH = typeof IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY_FINISH;

export const IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY_FAIL =
  'IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY_FAIL';
export type IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY_FAIL = typeof IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY_FAIL;

/** GENERAL */
export const IDENTITY_CHECK_RESULTS_ON_GET_GENERAL =
  'IDENTITY_CHECK_RESULTS_ON_GET_GENERAL';
export type IDENTITY_CHECK_RESULTS_ON_GET_GENERAL = typeof IDENTITY_CHECK_RESULTS_ON_GET_GENERAL;

export const IDENTITY_CHECK_RESULTS_ON_GET_GENERAL_START =
  'IDENTITY_CHECK_RESULTS_ON_GET_GENERAL_START';
export type IDENTITY_CHECK_RESULTS_ON_GET_GENERAL_START = typeof IDENTITY_CHECK_RESULTS_ON_GET_GENERAL_START;

export const IDENTITY_CHECK_RESULTS_ON_GET_GENERAL_FINISH =
  'IDENTITY_CHECK_RESULTS_ON_GET_GENERAL_FINISH';
export type IDENTITY_CHECK_RESULTS_ON_GET_GENERAL_FINISH = typeof IDENTITY_CHECK_RESULTS_ON_GET_GENERAL_FINISH;

export const IDENTITY_CHECK_RESULTS_ON_GET_GENERAL_FAIL =
  'IDENTITY_CHECK_RESULTS_ON_GET_GENERAL_FAIL';
export type IDENTITY_CHECK_RESULTS_ON_GET_GENERAL_FAIL = typeof IDENTITY_CHECK_RESULTS_ON_GET_GENERAL_FAIL;

export const IDENTITY_CHECK_RESULTS_ON_RESET =
  'IDENTITY_CHECK_RESULTS_ON_RESET';
export type IDENTITY_CHECK_RESULTS_ON_RESET = typeof IDENTITY_CHECK_RESULTS_ON_RESET;
