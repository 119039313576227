import styled from 'styled-components';
import Router from './Router';

const RouterStyled = styled(Router)`
  height: 100%;
  width: 100%;
  position: relative;

  ${props => props.theme.animation};
  ${props => props.theme.common};
`;

export default RouterStyled;
