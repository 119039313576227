import { css } from 'styled-components';

const animation = css`
  @keyframes __slideInUp {
    from {
      opacity: 0;
      transform: translate(-50%, -100%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }

  @keyframes __slideOutUp {
    from {
      opacity: 1;
      transform: translate(-50%, 0);
    }
    to {
      opacity: 0;
      transform: translate(-50%, -100%);
    }
  }

  @keyframes __fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes __fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes __spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes __progressBarOut {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  .--spin {
    animation: __spin 1s infinite;
  }

  .--fadeIn {
    animation: __fadeIn 300ms;
    animation-fill-mode: forwards;
  }

  .--fadeOut {
    animation: __fadeOut 300ms;
    animation-fill-mode: forwards;
  }

  .--slideInUp {
    animation: __slideInUp 300ms;
    animation-fill-mode: forwards;
  }

  .--slideOutUp {
    animation: __slideOutUp 300ms;
    animation-fill-mode: forwards;
  }

  .--progressBarOut {
    animation: __progressBarOut 1000ms;
    animation-fill-mode: forwards;
  }
`;

export default animation;
