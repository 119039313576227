import * as actions from 'store/actions';

export const errorsHandler = ({
  errorMsg,
  dispatch,
  fallbackErrorMsg = '',
  isShowToast = false,
}: {
  dispatch?: any;
  errorMsg: any;
  fallbackErrorMsg?: string;
  isShowToast?: boolean;
}) => {
  const showToastFunc = () => {
    console.error(errorMsg);
    if (isShowToast) {
      dispatch(actions.toastMessageAddFail(errorMsg));
    }
  };

  if (dispatch) {
    switch (true) {
      case errorMsg === 'TypeError: Failed to fetch':
      case errorMsg.text === 'http: named cookie not present':
      case errorMsg.code === 400:
      case errorMsg.code === 401:
        dispatch(actions.authOnSignOut());
        break;
      default:
        showToastFunc();
    }
  }
};

export default errorsHandler;
