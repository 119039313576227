import _ from 'lodash';
import dark from './dark';
import light from './light';
import shared from './shared/index';
import global from './global';

export default {
  dark: _.merge({}, global, shared, dark),
  light: _.merge({}, global, shared, light),
};
