import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { TTheme, TThemeVariants } from 'appTypes/theme';
import { ContextTheme } from './context';

type TMessageProvider = {
  themes: { [key in TThemeVariants]: TTheme };
  variants: TThemeVariants;
  children: React.ReactElement;
};

const ThemeProvider: React.FC<TMessageProvider> = ({
  themes: argThemes,
  variants,
  children,
}) => {
  const theme = argThemes[variants];

  return (
    <ContextTheme.Provider value={theme}>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </ContextTheme.Provider>
  );
};

export default ThemeProvider;
