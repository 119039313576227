import React from 'react';
import clsx from 'clsx';
import H from 'components/typography/H';
import { useSelector } from 'react-redux';

export const LayoutTableContext = React.createContext<any>(null);

const Table: React.FC<{
  className?: string;
  tabLabel: string;
  title?: string;
  backBtn?: any;
  reducerId?: any;
  crudBtnAdd?: any;
  statePath?: string;
}> = ({
  className,
  children,
  title = '',
  backBtn,
  reducerId,
  tabLabel,
  statePath,
  crudBtnAdd,
}) => {
  const backBtnRef = React.useRef<any>();
  const topRightPlaceholderRef = React.useRef<any>();
  const contextValue = {
    backBtnRef,
    topRightPlaceholderRef,
  };

  const { subTitle, balance } = useSelector((state: any) => {
    const curStatePath = statePath || 'crud';
    return state[reducerId]?.[curStatePath];
  });

  const tab: any =
    React.Children.toArray(children).length > 1
      ? React.Children.toArray(children).find(
          (child: any) => child.props['data-label'] === tabLabel,
        )
      : children;

  return (
    <LayoutTableContext.Provider value={contextValue}>
      <div className={clsx(className)}>
        <div className="table_layout_global_wrapper">
          <div ref={backBtnRef} className="backBtn_wrapper">
            {tab && tab.props['data-label'] !== 'root' && backBtn}
          </div>
          <div className="header_row_wrapper">
            <div className="title_wrapper">
              <div className="col1">
                <H variant="h1">
                  {(tab.props['data-label'] !== 'root' && tab.props['data-label'] !== 'crud-add' && subTitle) || title}
                </H>
              </div>
              <div className="col2">
                {tab && tab.props['data-label'] !== 'root' && balance}
              </div>
              <div className="col3">
                <div
                  ref={topRightPlaceholderRef}
                  className={'topRightPlaceholder'}
                >
                  {crudBtnAdd && (
                    <div className="addBtn_wrapper">{crudBtnAdd}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="horizontalLine" />
          <div className="table_layout_body_wrapper">{tab}</div>
        </div>
      </div>
    </LayoutTableContext.Provider>
  );
};

export default Table;
