import { getParsedBody, post } from 'utils/fetch';
import config, { endpoints } from 'configShared';
import {
  IUserProfileData,
  TConfirmResetPasswordReqBody,
  TResetPasswordReqBody,
  TSignInReqBody,
} from 'appTypes';

export const apiSignOut = () => {
  fetch([config.fetchOptions.url, endpoints.signOut].join('/'), {
    method: 'GET',
  }).then(r => r);
};

export const apiSingIn = async (signInProps: TSignInReqBody) => {
  return post<IUserProfileData, TSignInReqBody>(
    [config.fetchOptions.url, endpoints.signIn].join('/'),
    signInProps,
  ).then(getParsedBody);
};

/**
 * RESET PASSWORD
 * ==============
 *
 * ```json
 * {
 *   "email": "...",
 * }
 * ```
 */

export const apiResetPassword = async (body: TConfirmResetPasswordReqBody) => {
  return post<any, any>(
    [config.fetchOptions.url, endpoints.resetPassword].join('/'),
    body,
  ).then(getParsedBody);
};

/**
 * CONFIRM RESET PASSWORD
 * ======================
 *
 * ```json
 * {
 *   "new_password": "...",
 *   "code": "..."
 * }
 * ```
 */

export const apiConfirmResetPassword = async (body: TResetPasswordReqBody) => {
  return post<any, any>(
    [config.fetchOptions.url, endpoints.confirmResetPassword].join('/'),
    body,
  ).then(getParsedBody);
};
