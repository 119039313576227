import * as constants from '../constants/identityCheckResults';
import apiCallWrapper from 'utils/apiCallWrapper';

const reducer: any = ({
  dispatch,
  getState,
}: {
  dispatch: any;
  getState: any;
}) => (next: any) => (action: any) => {
  switch (action.type) {
    case constants.IDENTITY_CHECK_RESULTS_ON_GET_GENERAL:
      apiCallWrapper({
        ...action.payload,
        dispatch,
      });
      break;
    case constants.IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY:
      apiCallWrapper({
        ...action.payload,
        dispatch,
      });
      break;
    default:
      break;
  }

  return next(action);
};

export default reducer;
