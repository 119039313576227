/* eslint-disable no-undef */
const ENV = process.env.REACT_APP_ENV || 'dev';
/* Local or AWS (local running from localhost:8080 on Ubuntu Server) */
const BACKEND_TYPE = process.env.REACT_APP_BACKEND_TYPE;
/* eslint-enable no-undef */

const fetchUrl = () => {
  switch (BACKEND_TYPE) {
    case 'local':
      return `http://localhost:8080`;
    case 'aws':
    default:
      switch (ENV) {
        case 'qa':
          return `https://dev-backend.open-banking-gateway.com`;
        case 'stage':
          return `https://stage-backend.open-banking-gateway.com`;
        case 'prod':
          return `https://prod-backend.trustloop.io`;
        case 'dev':
        default:
          return `http://35.177.148.243:8080`;
      }
  }
};

type TFetchOptions = {
  credentials: 'include';
};

const fetchOptionsMethodCommon: TFetchOptions = {
  credentials: 'include',
};

export const routes = {
  auth: {
    signIn: '/sign_in',
    signOut: '/sign_out',
    resetPassword: '/reset_password',
    confirmResetPassword: '/set_password',
  },
  dashboard: {
    main: '/dashboard',
    consents: '/consents',
    admins: '/admins',
    psus: '/psus',
    customers: '/customers',
    pricingPlans: '/pricingPlans',
    pricingPlansPackages: '/pricingPlansPackages',
  },
};

export const endpoints = {
  csrfToken: 'token',
  systemLogin: 'system/signin',
  signIn: 'signin',
  signOut: 'signout',
  resetPassword: 'reset_password',
  confirmResetPassword: 'confirm_reset_password',
  consents: 'consents',
  consentsTransactions: 'consents/transactions',
  consentsSummary: (consentId: string) => `consents/${consentId}/summary`,
  consentsGeneral: (consentId: string) => `consents/${consentId}/general`,
  admins: 'admins',
  adminAdd: 'add',
  adminDelete: 'delete',
  adminUpdate: 'update',
  psus: 'psus',
  psuAdd: 'psu/add',
  psuUpdate: 'psu/update',
  psuDelete: 'psu/delete',
  customers: 'customers',
  customerAdd: 'customer/add',
  customerUpdate: 'customer/update',
  customerDelete: 'customer/delete',
  companyPricingPlanUpdate: 'customer/company/pricingplan',
  pricingPlans: 'pricingplans',
  pricingPlanAdd: 'pricingplan',
  pricingPlanUpdate: (pricingPlanId: string) => `pricingplan/${pricingPlanId}`,
  pricingPlansPackages: (pricingPlanId: string) => `pricingplan/${pricingPlanId}/items`,
  pricingPlanPackage: (pricingPlanId: string) => `pricingplan/${pricingPlanId}/item`,
};

const config = {
  ENV,
  BACKEND_TYPE,
  endpoints,
  routes,
  siteMainTitle: 'trustloop • admin',
  defToastRedirectTimeout: 2000,
  defToastRedirectTimeoutSuccess: 2000,
  defToastRedirectTimeoutFail: 8000,
  defToastTimeout: 2000,
  defToastTimeoutFail: 8000,
  defToastTimeoutSuccess: 2000,
  defPublicRoute: routes.auth.signIn,
  defPrivateRoute: routes.dashboard.consents,
  fetchOptions: {
    url: `${fetchUrl()}/api/v0/admin`,
    timeout: 4000,
    method: {
      get: {
        ...fetchOptionsMethodCommon,
      },
      post: {
        ...fetchOptionsMethodCommon,
      },
      put: {
        ...fetchOptionsMethodCommon,
      },
      delete: {
        ...fetchOptionsMethodCommon,
      },
    },
  },
};

export default config;
