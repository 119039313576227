import React from 'react';
import { Formik, FormikHelpers, FormikValues, useFormikContext } from 'formik';
import { ObjectSchema } from 'yup';
import clsx from 'clsx';
import ButtonSubmit from 'components/ui/ButtonSubmit';
import { IFormSingleFieldParams } from './types';
import renderFieldsByType from './utils/renderFieldsByType';

type TFormForm = {
  onSubmit: (
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>,
  ) => void;
  isProcessing?: boolean;
  onSubmitLabel?: string;
  validationSchema?: ObjectSchema;
  initialValues?: any;
  params: Array<IFormSingleFieldParams>;
  className?: string;
  buttonsWrapper?: any;
};

const DefaultButtonsWrapper = ({
  isProcessing: argIsProcessing,
  onSubmitLabel,
}: {
  isProcessing?: boolean;
  onSubmitLabel: string;
}) => {
  const { isSubmitting } = useFormikContext();

  return (
    <div className="form_buttons_wrapper">
      <ButtonSubmit
        isProcessing={argIsProcessing || isSubmitting}
        variant="primary"
        type="submit"
      >
        {onSubmitLabel}
      </ButtonSubmit>
    </div>
  );
};

const FormWrapper = ({ children }: { children: any }) => {
  const formikProps = useFormikContext();

  return (
    <form onReset={formikProps.handleReset} onSubmit={formikProps.handleSubmit}>
      {children}
    </form>
  );
};

const FormForm: React.FC<TFormForm> = ({
  onSubmitLabel = 'Submit',
  onSubmit,
  params,
  isProcessing = false,
  buttonsWrapper: ButtonsWrapper = DefaultButtonsWrapper,
  validationSchema = {},
  initialValues = {},
  className,
}) => {
  return (
    <div className={clsx(className, 'component_FormForm')}>
      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        <FormWrapper>
          <div className="form_wrapper">
            <div className="form_inputFields_wrapper">
              {params.map(field => renderFieldsByType(field))}
            </div>
            <ButtonsWrapper
              onSubmitLabel={onSubmitLabel}
              isProcessing={isProcessing}
            />
          </div>
        </FormWrapper>
      </Formik>
    </div>
  );
};

export default FormForm;
