import { useFormikContext } from 'formik';
import React from 'react';
import ButtonSubmit from 'components/ui/ButtonSubmit';
import DeleteConfirmDialog from './DeleteConfirmDialog';

const ButtonsUpdate: React.FC<{
  isProcessing: boolean;
  showDelete: boolean;
  onDelete: any;
}> = ({ isProcessing, showDelete = true, onDelete }) => {
  const { isSubmitting, values } = useFormikContext();

  return (
    <div className="form_buttons_wrapper">
      <ButtonSubmit
        isProcessing={isProcessing || isSubmitting}
        variant="primary"
        type="submit"
      >
        Update
      </ButtonSubmit>
      <ButtonSubmit variant="secondary" type="reset">
        Reset
      </ButtonSubmit>
      {showDelete && <DeleteConfirmDialog action={() => onDelete(values)} />}
    </div>
  );
};

export default ButtonsUpdate;
