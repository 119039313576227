import styled from 'styled-components';
import Sidebar from './Sidebar';

const SidebarStyled = styled(Sidebar)`
  grid-area: sidebar;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 2rem 1rem 1rem 1rem;
  margin: 0 auto;
  & .sidebarHeader {
  }

  .inner_wrapper {
  }

  & .navLinksChild_wrapper {
    padding-top: 5rem;
    text-align: left;
    display: grid;
    grid-gap: 10px;
    & .navLinkElement {
      .component_ui_Link {
        padding: 1rem 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px;
        display: flex;
        align-items: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        &.active:after {
          content: ' ';
          position: absolute;
          left: 0;
          height: 50px;
          width: 5px;
          z-index: 999;
          background-color: ${props => props.theme.colors.brand.blue};
        }
      }
    }
  }
`;

export default SidebarStyled;
