import React from 'react';
import Container from 'components/ui/Container';
import clsx from 'clsx';
import H from 'components/typography/H';
import { authOnSignOut } from 'store/actions/auth';
import Link from 'components/ui/SidebarLink';
import { routes } from 'configShared';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from './Sidebar';

type TLayoutDashboard = {
  pageTitle?: string;
  className?: string;
  children?: React.ReactElement | React.ReactElement[];
};

const LayoutDashboard: React.FC<TLayoutDashboard> = ({
  className,
  children,
  pageTitle,
}) => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state: any) => state.auth);

  return (
    <div className={clsx(className, 'component_layout_AuthForms')}>
      <div className="grid_wrapper">
        <div className="sidebar">
          <Sidebar>
            <Link href={routes.dashboard.consents}>BankLoop</Link>
            {userProfile && userProfile.role === 'super-admin' ? (
              <Link href={routes.dashboard.admins}>Admin users</Link>
            ) : null}
            <Link href={routes.dashboard.psus}>PSU users</Link>
            <Link href={routes.dashboard.customers}>Customer users</Link>
            <Link href={routes.dashboard.pricingPlans}>Pricing plans</Link>
            <Link
              href="#signOut"
              handleOnClick={() => dispatch(authOnSignOut())}
            >
              Sign out
            </Link>
          </Sidebar>
        </div>
        <div className="header"></div>
        <div className="main">
          <Container>
            <div className="dashboard_wrapper">
              {pageTitle && <H variant="h2">{pageTitle}</H>}
              {children}
            </div>
          </Container>
        </div>
        <div className="userProfile"></div>
        <div className="footer"></div>
      </div>
    </div>
  );
};

export default LayoutDashboard;
