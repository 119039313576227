import React from 'react';
import clsx from 'clsx';

const ToastSuccess = ({ className }: { className?: string }) => {
  return (
    <div className={clsx(className, 'assets_svg', 'toastSuccess', '-dark')}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="20" fill="black" fillOpacity="0.1" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.728 16.029L26.6816 15.0192L19.4672 21.9808L15.8903 18.5292L14.8439 19.5389L19.4287 23.963L19.4289 23.9628L19.4675 24L27.728 16.029Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default ToastSuccess;
