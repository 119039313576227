import React from 'react';
import { Provider } from 'react-redux';
import { combineReducers, createStore } from 'redux';
import { composeMiddleware } from './utils';

type TStoreProvider = {
  middleware: Array<any>;
  reducers: any;
  children: React.ReactElement;
};

const StoreProvider: React.FC<TStoreProvider> = ({
  middleware,
  reducers,
  children,
}) => {
  const store = createStore(
    combineReducers(reducers),
    composeMiddleware(middleware),
  );

  return <Provider store={store}>{children}</Provider>;
};

export default StoreProvider;
