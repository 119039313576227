import React from 'react';
import moment from 'moment';
import Triangle from 'assets/svg/icons/Triangle';
import clsx from 'clsx';
import shortid from 'shortid';
import { TColumn } from './types';
import * as actions from './reducer/actions';

export const renderHeaderSort = (dispatch: any, accessor: string) => {
  return {
    onClick: () => dispatch(actions.tableSetSort({ by: accessor })),
  };
};

export const renderTableHeaders = (
  columns: Array<TColumn>,
  dispatch: any,
  sortBy: string,
  sortOrder: boolean,
) => (
  <div className="tr">
    {columns.map((column: TColumn, i: number) => {
      const sortAccessor = column.sortAccessor || column.accessor || false;
      let sorted = false;
      if (column.canSort) {
        sorted = sortAccessor === sortBy;
      }

      return (
        <div
          {...(column.canSort &&
            sortAccessor &&
            renderHeaderSort(dispatch, sortAccessor))}
          className={clsx(
            'th',
            column.canSort && '--sortable',
            sorted && `--sorted --sortOrder-${sortOrder}`,
          )}
          key={shortid.generate()}
        >
          <div className={clsx('th_wrapper')}>
            <div className="th_title">
              {typeof column.Header === 'string'
                ? column.Header
                : column.Header()}
            </div>
            <div className="th_sortIcons">
              {sorted &&
                (sortOrder === false ? (
                  <Triangle direction="up" />
                ) : (
                  <Triangle direction="down" />
                ))}
            </div>
          </div>
        </div>
      );
    })}
  </div>
);

export const defIsRowEditable = () => true;

export const renderTableRows = (
  data: Array<any>,
  columns: Array<TColumn>,
  dispatch: any,
  handleOnRowClick = (args?: any) => args,
  handleOnEdit?: any,
  viewDetails?: boolean,
  isRowEditable: (row?: any) => boolean = defIsRowEditable,
) =>
  data &&
  data.map((row: any) => (
    <div
      className="tr"
      key={shortid.generate()}
      onClick={() => {
        if (isRowEditable && isRowEditable(row)) handleOnRowClick(row);
      }}
    >
      {columns.map((column: TColumn, y: number) => (
        <div
          className={clsx(
            'td',
            !column.tdClassName && '-default',
            column.tdClassName,
          )}
          key={y}
        >
          {column.Cell
            ? column.Cell({
                value: (column.accessor && row[column.accessor]) || undefined,
                row,
                column,
              })
            : column.accessor && row[column.accessor]}
        </div>
      ))}
      {handleOnEdit && (
        <div 
          className={'--row_viewDetails --row_edit'}
          onClick={(e) => {
            e.stopPropagation();
            handleOnEdit(row);
          }}
        >
          Edit
        </div>
      )}
      {viewDetails && isRowEditable && isRowEditable(row) && (
        <div className={'--row_viewDetails'}>View Details</div>
      )}
    </div>
  ));

export const renderPagination = (
  state: {
    offset: number;
    limit: number;
    total: number;
  },
  dispatch: any,
  lazyLoad?: boolean,
) => {
  const { offset, limit, total } = state;
  let pages = 0;
  const page = Math.floor(offset / limit);

  if (total) {
    pages = Math.floor(total / limit);
    if (total % limit > 0) {
      pages += 1;
    }
  }

  if (total <= limit && !lazyLoad) {
    return null;
  }

  let canPrev = offset > 0 && page > 0;
  let canNext = page < pages - 1;

  if (lazyLoad) {
    canNext = true;
    canPrev = offset > 0;
  }

  const renderLazyLoadPageButtons = () => {
    const render = [];
    render.push(
      <button
        type="button"
        onClick={() => {
          if (canPrev) dispatch(actions.tableSetOffset(offset - limit));
        }}
        key="prev"
        className={clsx('arrows', !canPrev && 'disabled')}
      >
        <Triangle direction="left" />
      </button>,
    );
    render.push(
      <button
        type="button"
        onClick={() => {
          if (canNext) dispatch(actions.tableSetOffset(offset + limit));
        }}
        key="next"
        className={clsx('arrows', !canNext && 'disabled')}
      >
        <Triangle direction="right" />
      </button>,
    );
    return render;
  };

  const renderPageButtons = (): React.ReactNode => {
    const render = [];
    const pageButtons = [];

    for (let i = 0; i < pages; i += 1) {
      pageButtons.push(
        <button
          type="button"
          onClick={() => {
            dispatch(actions.tableSetOffset(i * limit));
          }}
          key={i + 1}
          className={clsx(page === i && 'active')}
        >
          {i + 1}
        </button>,
      );
    }

    render.push(
      <button
        type="button"
        onClick={() => {
          if (canPrev) dispatch(actions.tableSetOffset(0));
        }}
        key="first"
        className={clsx('arrows', !canPrev && 'disabled')}
      >
        <Triangle direction="left" />
        <Triangle direction="left" />
      </button>,
      <button
        type="button"
        onClick={() => {
          if (canPrev) dispatch(actions.tableSetOffset(offset - limit));
        }}
        key="prev"
        className={clsx('arrows', !canPrev && 'disabled')}
      >
        <Triangle direction="left" />
      </button>,
      <div key="pageButtons_wrapper" className="pageButtons_wrapper">
        {pageButtons}
      </div>,
      <button
        type="button"
        onClick={() => {
          if (canNext) dispatch(actions.tableSetOffset(offset + limit));
        }}
        key="next"
        className={clsx('arrows', !canNext && 'disabled')}
      >
        <Triangle direction="right" />
      </button>,
      <button
        type="button"
        onClick={() => {
          if (canNext) dispatch(actions.tableSetOffset((pages - 1) * limit));
        }}
        key="last"
        className={clsx('arrows', !canNext && 'disabled')}
      >
        <Triangle direction="right" />
        <Triangle direction="right" />
      </button>,
    );

    return render;
  };

  return (
    <div key="pagination_wrapper" className="pagination_wrapper">
      <div className="pagination">
        {lazyLoad ? renderLazyLoadPageButtons() : renderPageButtons()}
      </div>
    </div>
  );
};

export const expiredTimeFormat = (expiredInRaw: number) => {
  return expiredInRaw > 23
    ? `${Math.floor(expiredInRaw / (24 * 3600))}d`
    : `${expiredInRaw}h`;
};

export const formatPeriod = (period: string) => {
  let till = 0;
  let from = 0;

  switch (period) {
    case 'current_month':
      till = moment(Date.now()).unix();
      from = moment(Date.now())
        .startOf('months')
        .unix();
      break;
    case 'current_week':
      till = moment(Date.now()).unix();
      from = moment(Date.now())
        .startOf('week')
        .unix();
      break;
    case 'past_month':
      till = moment(Date.now())
        .subtract(1, 'month')
        .endOf('month')
        .unix();
      from = moment(Date.now())
        .subtract(1, 'month')
        .startOf('month')
        .unix();
      break;
    case 'all':
    default:
      till = 0;
      from = 0;
  }
  return { till, from, period };
};
