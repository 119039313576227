import React from 'react';
import clsx from 'clsx';

type TH = {
  className?: string;
  children: React.ReactNode;
  variant?: 'h1' | 'h2' | 'h3';
  component?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

const H: React.FC<TH> = ({
  className,
  children,
  component = 'h2',
  variant = 'h1',
}) => {
  return (
    <span className={clsx('component_TypoH_wrapper', className)}>
      {React.createElement(
        component,
        {
          className: `component_TypoH ${variant}`,
        },
        children,
      )}
    </span>
  );
};

export default H;
