import * as constants from 'store/constants';

export const defInitState: any = {
  tab: 'root',
  general_isProcessing: false,
  summary_isProcessing: false,
  general_data: null,
  general_error: null,
  summary_data: null,
  summary_error: null,
  transactions: null,
};

const reducer = (initState: any = defInitState) => (
  state: any = initState,
  action: any,
) => {
  switch (action.type) {
    case constants.IDENTITY_CHECK_RESULTS_ON_RESET:
      return {
        ...initState,
      };
    case constants.IDENTITY_CHECK_RESULTS_ON_GET_GENERAL_FAIL:
      return {
        ...state,
        general_error: action.error,
        general_isProcessing: false,
      };
    case constants.IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY_FAIL:
      return {
        ...state,
        summary_error: action.error,
        summary_isProcessing: false,
      };
    case constants.IDENTITY_CHECK_RESULTS_ON_GET_GENERAL_FINISH:
      return {
        ...state,
        general_data: action.payload,
        general_isProcessing: false,
      };
    case constants.IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY_FINISH:
      return {
        ...state,
        summary_data: action.payload,
        summary_isProcessing: false,
      };
    case constants.IDENTITY_CHECK_RESULTS_ON_GET_GENERAL_START:
      return {
        ...state,
        general_data: null,
        general_isProcessing: true,
      };
    case constants.IDENTITY_CHECK_RESULTS_ON_GET_SUMMARY_START:
      return {
        ...state,
        summary_data: null,
        summary_isProcessing: true,
      };
    case constants.IDENTITY_CHECK_RESULTS_SET_TAB:
      return {
        ...state,
        tab: action.tab,
        consentId: action.consentId,
        subTitle: action.subTitle,
      };
    default:
      return state;
  }
};

export default reducer;
