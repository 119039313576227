import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import clsx from 'clsx';

const el = document.createElement('div');
document.body.appendChild(el);

const Modal: React.FC<{ className?: string; onClose: any }> = ({
  className,
  children,
  onClose,
}) => (
  <div
    className={clsx(className, 'modal_backdrop_wrapper')}
    onClick={() => onClose()}
  >
    <div
      className="modal_window_wrapper"
      onClick={event => {
        event.stopPropagation();
      }}
    >
      {children}
    </div>
  </div>
);

const ModalStyled = styled(Modal)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.1);

  .modal_window_wrapper {
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 5rem;
    background-color: white;
    z-index: 9999;
  }
`;

const ModalWrapper: React.FC<{ onClose: any }> = ({ children, onClose }) => {
  return ReactDOM.createPortal(
    <ModalStyled onClose={onClose}>{children}</ModalStyled>,
    el,
  );
};

export default ModalWrapper;
