import styled from 'styled-components';
import PreloaderTable from './PreloaderTable';

const PreloaderTableStyled = styled(PreloaderTable)`
  background-color: rgba(255, 255, 255, 0.8);
  opacity: ${props => (props.isProcessing ? 1 : 0)};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  transition: all 300ms;
  z-index: 100;

  .assets_svg svg {
    width: 5rem;
    height: 5rem;
    animation: __spin 1s linear infinite;
  }
`;

export default PreloaderTableStyled;
