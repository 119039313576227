import React from 'react';
import { useField } from 'formik';
import clsx from 'clsx';
import styled from 'styled-components';
import { IFormSingleFieldParams, TInputField } from '../types';

type TDefFieldHandlersWrapper = {
  input: React.FC<IFormSingleFieldParams & TInputField>;
} & IFormSingleFieldParams;

const FieldPropsHandler = ({
  className,
  label,
  id: argsId,
  name,
  type,
  input: Input,
  placeholder,
  ...rest
}: TDefFieldHandlersWrapper) => {
  const id: string = argsId || name;
  const [field, meta] = useField(id);
  const { value } = field;
  const [focus, setFocus] = React.useState<string>('');

  const handleOnFocus = React.useCallback(() => {
    setFocus('--focused');
  }, []);

  const handleOnBlur = React.useCallback(() => {
    setFocus('');
  }, []);

  return (
    <div
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      className={clsx(
        className,
        'input-wrapper',
        'component_DefFieldHandlersWrapper',
        focus,
        value !== undefined && value !== '' && '--has-value',
        meta.touched && '--touched',
        meta.touched && meta.error && '--error',
      )}
    >
      <Input
        className="text-input"
        id={id}
        placeholder={placeholder}
        label={label}
        type={type}
        aria-describedby={`${id}-helper-text`}
        meta={meta}
        {...rest}
        {...field}
      />
    </div>
  );
};

const FieldPropsHandlerStyled = styled(FieldPropsHandler)`
  ${props => {
    const type = props.type || 'text';
    return props.theme.input[type];
  }};

  label {
    pointer-events: none;
  }
`;

export default FieldPropsHandlerStyled;
