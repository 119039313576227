import { css } from 'styled-components';

const common = css`
  background-color: ${props => props.theme.colors.brand.dark};

  .assets_svg {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
  }
`;
export default common;
