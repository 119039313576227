import React from 'react';
import clsx from 'clsx';

type TContainer = {
  className?: string;
  children: React.ReactNode;
  maxWidth?: string;
};

const Container: React.FC<TContainer> = ({ className, children }) => {
  return (
    <div className={clsx(className, 'component_ui_Container')}>{children}</div>
  );
};

export default Container;
