import { IUserProfileData } from 'appTypes';
import * as constants from 'store/constants';

type TStore = IUserProfileData;

export const defInitState: TStore = {
  email: null,
  role: null,
  first_name: '',
  last_name: '',
  isProcessing: false,
  isSignOut: true,
  error: null,
};

const reducer = (initState: TStore = defInitState) => (
  state: TStore = initState,
  action: any,
) => {
  switch (action.type) {
    case constants.AUTH_ON_RESET:
      return { ...initState };
    case constants.AUTH_SET_USER_PROFILE_DATA:
      return {
        ...state,
        ...action.userProfileData,
        isSignOut: false,
        isProcessing: false,
      };
    case constants.AUTH_SET_ERROR:
      return { ...state, error: action.error, isProcessing: false };
    case constants.AUTH_SET_IS_PROCESSING:
      return { ...state, isProcessing: action.isProcessing };
    case constants.AUTH_SET_SIGN_OUT_TRUE:
      return { ...state, isSignOut: true };
    default:
      return state;
  }
};

export default reducer;
