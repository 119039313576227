import { css } from 'styled-components';

export const select = css`
  display: grid;
  flex-flow: column;
  position: relative;

  input,
  select {
    padding: 0 1rem;
    font-size: 14px;
    color: ${props => props.theme.colors.brand.primary};
    font-weight: 500;
    background-color: ${props => props.theme.colors.brand.grayLight20};
    border: none;
    height: 50px;
  }

  .select-label {
    margin-bottom: 5px;
    font-size: 75%;
    color: ${props => props.theme.colors.brand.blue};
  }
  & > label {
    text-align: left;
  }

  .helper-text {
    z-index: 1000;
    position: absolute;
    bottom: -1rem;
    font-size: 12px;
    width: 100%;
    text-align: right;
    color: ${props => props.theme.colors.brand.red};
  }

  &.--error {
    &::after {
      background-color: ${props => props.theme.colors.brand.red};
    }
  }
`;

export const text = css`
  display: flex;
  flex-flow: column;
  position: relative;

  &::after,
  &::before {
    position: absolute;
    content: ' ';
    bottom: 10px;
    height: 2px;
    background-color: ${props => props.theme.colors.brand.gray};
    transition: 300ms all;
    width: 100%;
  }

  &::after {
    z-index: 10;
  }

  &::before {
    transform: scaleX(0);
    z-index: 20;
  }

  label {
    position: absolute;
    top: 25%;
    text-align: left;
    width: 100%;
    height: 100%;
    transition: 300ms all;
    color: ${props => props.theme.colors.brand.gray};
  }

  input {
    width: 100%;
    background-color: transparent;
    border: none;
    position: relative;
  }

  .helper-text {
    position: absolute;
    bottom: -10px;
    font-size: 12px;
    width: 100%;
    text-align: right;
    color: ${props => props.theme.colors.brand.red};
  }

  &.--error {
    &::after {
      background-color: ${props => props.theme.colors.brand.red};
    }
  }

  &.--focused,
  &.--has-value {
    label {
      font-size: 75%;
      top: -20%;
      color: ${props => props.theme.colors.brand.blue};
    }
  }

  &.--focused {
    &::before {
      transform: scaleX(1);
      background-color: ${props => props.theme.colors.brand.blue};
    }
  }
`;

export default {
  text,
  phone: text,
  password: text,
  number: text,
  email: text,
  select,
};
