import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import * as actions from 'store/actions';
import { apiConsentsSummary } from 'services/dataFetching/consents';
import { useDispatch, useSelector } from 'react-redux';
import { constants } from 'configShared/store';
import Preloader from 'components/ui/Preloader';

const Typography = styled.div``;

const Header = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 5px;
`;

const Summary: React.FC = () => {
  const dispatch = useDispatch();
  const {
    summary_isProcessing,
    summary_data,
    summary_error,
    consentId,
  } = useSelector(
    (state: any) => state[constants.CONSENTS].identityCheckResults,
  );

  React.useEffect(() => {
    if (consentId && !summary_error) {
      dispatch(
        actions.identityCheckResultsOnGetGeneral({
          onStart: () =>
            dispatch(actions.identityCheckResultsOnGetSummaryStart()),
          apiCall: () => apiConsentsSummary(consentId).then((r: any) => r.body),
          onFinish: (resp: any) => {
            dispatch(actions.identityCheckResultsOnGetSummaryFinish(resp));
          },
          onFail: (error: any) => {
            dispatch(actions.identityCheckResultsOnGetSummaryFail(error));
          },
        }),
      );
    } else {
      dispatch(actions.toastMessageAddFail({ ...summary_error }));
    }
  }, [consentId, summary_error, dispatch]);

  const renderSummaryData = (data: any) => {
    const isOverdraftDateEmpty =
      data.overview.last_overdraft_date === '0001-01-01T00:00:00Z';

    const formatCurrency = (amount: string) => {
      if (parseInt(amount, 0) < 0) {
        return `-£${amount.slice(1)}`;
      }
      return `£${amount}`;
    };

    return (
      <div className={'reportRowsWrapper'}>
        <Header>Transactions</Header>
        <div className={'reportSingleRow'}>
          <Typography>Number of Days In Range</Typography>
          <Typography>
            {data.overview.days_in_range ? data.overview.days_in_range : '—'}
          </Typography>
        </div>
        <div className={'reportSingleRow'}>
          <Typography>First Date</Typography>
          <Typography>
            {data.overview.earliest_date
              ? moment(data.overview.earliest_date).format('DD MMM YYYY')
              : '—'}
          </Typography>
        </div>
        <div className={'reportSingleRow'}>
          <Typography>Last Date</Typography>
          <Typography>
            {data.overview.last_date
              ? moment(data.overview.last_date).format('DD MMM YYYY')
              : '—'}
          </Typography>
        </div>
        <div className={'reportSingleRow'}>
          <Typography>Account Balance</Typography>
          <Typography>
            {data.overview.current_balance
              ? formatCurrency(data.overview.current_balance)
              : '-'}
          </Typography>
        </div>
        <Header>Behaviours</Header>
        <div className={'reportSingleRow'}>
          <Typography>In overdraft</Typography>
          <Typography>
            {data.overview.is_overdraft_now ? 'Yes' : 'No'}
          </Typography>
        </div>
        <div className={'reportSingleRow'}>
          <Typography>Overdraft Date</Typography>
          <Typography>
            {data.overview.last_overdraft_date && !isOverdraftDateEmpty
              ? moment(data.overview.last_overdraft_date).format('DD MMM YYYY')
              : '—'}
          </Typography>
        </div>
        <div className={'reportSingleRow'}>
          <Typography>Number of Days in Overdraft in 12 months</Typography>
          <Typography>
            {data.overview.overdraft_days_qty
              ? data.overview.overdraft_days_qty
              : '—'}
          </Typography>
        </div>
        <div className={'reportSingleRow'}>
          <Typography>Percentage of Days Spent in Overdraft</Typography>
          <Typography>
            {data.overview.overdraft_days_pct
              ? `${data.overview.overdraft_days_pct}%`
              : '—'}
          </Typography>
        </div>
        <Header>Other</Header>
        <div className={'reportSingleRow'}>
          <Typography>Average Loan Repayment Value</Typography>
          <Typography>
            {data.overview.avg_loan_repayment
              ? formatCurrency(data.overview.avg_loan_repayment)
              : '—'}
          </Typography>
        </div>
        <div className={'reportSingleRow'}>
          <Typography>Mean Income (year)</Typography>
          <Typography>
            {data.overview.avg_monthly_mean
              ? formatCurrency(data.overview.avg_monthly_mean)
              : '—'}
          </Typography>
        </div>
        <div className={'reportSingleRow'}>
          <Typography>Mean Income 3 Mths</Typography>
          <Typography>
            {data.overview.avg_monthly_mean_3_months
              ? formatCurrency(data.overview.avg_monthly_mean_3_months)
              : '—'}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div className="summary">
      {summary_isProcessing || !summary_data ? (
        <Preloader />
      ) : (
        renderSummaryData(summary_data)
      )}
    </div>
  );
};

export default Summary;
