import styled from 'styled-components';
import Paper from './Paper';

const PaperStyled = styled(Paper)`
  ${props =>
    props.shadowDeep !== undefined
      ? props.theme.shadows.deepLevel[props.shadowDeep]
      : 'none'};
  padding: 60px 120px;
  margin-left: auto;
  margin-right: auto;
`;

export default PaperStyled;
