import colors from './colors';
import shadows from './shadows';
import typography from './typography';

const theme = {
  colors,
  shadows,
  typography,
};

export default theme;
