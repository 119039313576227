import React from 'react';
import { TCrudFormUpdateDelete } from './types';
import CrudForm from './CrudFormStyled';
import ButtonsUpdate from './ButtonsUpdate';
import onSubmit from './onSubmit';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

export const CrudFormUpdate: React.FC<TCrudFormUpdateDelete> = ({
  params,
  apiCallWrapper,
  deleteApiCallWrapper,
  dispatch,
  validationSchema,
  className,
  reducerId,
}) => {
  const { initValues, isProcessing } = useSelector(
    (state: any) => state[reducerId].crud,
  );
  return (
    <div className={clsx(className, 'CrudFormUpdate')}>
      <CrudForm
        title={'Edit'}
        params={params}
        onSubmit={onSubmit(dispatch, apiCallWrapper, {
          goToRoot: true,
        })}
        buttonsWrapper={() => (
          <ButtonsUpdate
            isProcessing={isProcessing}
            showDelete
            onDelete={onSubmit(dispatch, deleteApiCallWrapper, {
              goToRoot: true,
            })}
          />
        )}
        initialValues={initValues}
        validationSchema={validationSchema}
      />
    </div>
  );
};

export default CrudFormUpdate;
