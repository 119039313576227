export const AUTH_ON_CONFIRM_RESET_PASSWORD = 'AUTH_ON_CONFIRM_RESET_PASSWORD';
export type AUTH_ON_CONFIRM_RESET_PASSWORD = typeof AUTH_ON_CONFIRM_RESET_PASSWORD;

export const AUTH_ON_RESET_PASSWORD = 'AUTH_ON_RESET_PASSWORD';
export type AUTH_ON_RESET_PASSWORD = typeof AUTH_ON_RESET_PASSWORD;

export const AUTH_ON_SIGN_IN = 'AUTH_ON_SIGN_IN';
export type AUTH_ON_SIGN_IN = typeof AUTH_ON_SIGN_IN;

export const AUTH_ON_SIGN_OUT = 'AUTH_ON_SIGN_OUT';
export type AUTH_ON_SIGN_OUT = typeof AUTH_ON_SIGN_OUT;

export const AUTH_SET_USER_PROFILE_DATA = 'AUTH_SET_USER_PROFILE_DATA';
export type SET_USER_PROFILE_DATA = typeof AUTH_SET_USER_PROFILE_DATA;

export const AUTH_SET_IS_PROCESSING = 'AUTH_SET_IS_PROCESSING';
export type AUTH_SET_IS_PROCESSING = typeof AUTH_SET_IS_PROCESSING;

export const AUTH_SET_ERROR = 'AUTH_SET_ERROR';
export type AUTH_SET_ERROR = typeof AUTH_SET_ERROR;

export const AUTH_ON_RESET = 'AUTH_ON_RESET';
export type AUTH_ON_RESET = typeof AUTH_ON_RESET;

export const AUTH_SET_SIGN_OUT_TRUE = 'AUTH_SET_SIGN_OUT_TRUE';
export type AUTH_SET_SIGN_OUT_TRUE = typeof AUTH_SET_SIGN_OUT_TRUE;

export const AUTH_ON_TRY_AUTO_SIGN_IN = 'AUTH_ON_TRY_AUTO_SIGN_IN';
export type AUTH_ON_TRY_AUTO_SIGN_IN = typeof AUTH_ON_TRY_AUTO_SIGN_IN;

export const AUTH_ON_TRY_AUTO_SIGN_IN_FINISH =
  'AUTH_ON_TRY_AUTO_SIGN_IN_FINISH';
export type AUTH_ON_TRY_AUTO_SIGN_IN_FINISH = typeof AUTH_ON_TRY_AUTO_SIGN_IN_FINISH;

export const AUTH_ON_TRY_AUTO_SIGN_IN_FAIL = 'AUTH_ON_TRY_AUTO_SIGN_IN_FAIL';
export type AUTH_ON_TRY_AUTO_SIGN_IN_FAIL = typeof AUTH_ON_TRY_AUTO_SIGN_IN_FAIL;
