import styled from 'styled-components';
import Toast from './Toast';

const ToastStyled = styled(Toast)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin-left: var(--sidebar-width);
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
`;

export default ToastStyled;
