import { FormikValues } from 'formik';
import {
  IFormSingleFieldParams,
  TFormFieldsPossibleNames,
  TFormFieldsPossibleTypes,
  TMsgMinReq,
} from './types';

export const getFieldInitValueByType = (
  type: TFormFieldsPossibleTypes | string,
) => {
  switch (type) {
    case 'select':
      return 'label';
    case 'email':
      return '';
    case 'password':
      return '';
    case 'text':
      return '';
    default:
      return '';
  }
};

export const getFieldTypeByName = (name: TFormFieldsPossibleNames | string) => {
  switch (name) {
    case 'companyEmployeesNumber':
    case 'companySector':
    case 'pricingPlan':
    case 'currency':
      return 'select';
    case 'credits':
    case 'amount':
    case 'companyNumber':
    case 'companyPostcode':
    case 'companyName':
    case 'simple_password':
    case 'id':
    case 'first_name':
    case 'last_name':
    case 'firstName':
    case 'lastName':
      return 'text';
    case 'phone':
      return 'phone';
    case 'password':
      return 'password';
    case 'passwordMatch':
      return 'password';
    case 'email':
      return 'email';
    default:
      return 'text';
  }
};

export const notSoSimpleFormConstructor = ({
  messages,
  fields,
}: {
  messages: TMsgMinReq;
  fields: Array<
    | TFormFieldsPossibleNames
    | { name: string; isHidden?: boolean; isDisabled?: boolean }
  >;
}) =>
  fields.map((f: any) => {
    if (typeof f === 'string') {
      return {
        name: f,
        type: getFieldTypeByName(f),
        label: messages.form.fields.label[f],
        placeholder: messages.form.fields.placeholder[f],
        initValue: getFieldInitValueByType(getFieldTypeByName(f)),
      };
    }
    if (typeof f === 'object') {
      const { name } = f;
      return {
        name,
        isHidden: f.isHidden || false,
        isDisabled: f.isDisabled || false,
        type: getFieldTypeByName(name),
        initValue: getFieldInitValueByType(getFieldTypeByName(name)),
        label: messages.form.fields.label[name],
        placeholder: messages.form.fields.placeholder[name],
      };
    }

    throw new Error('Params for form must be string or object');
  });

export const simpleFormConstructor = ({
  messages,
  fields,
}: {
  messages: TMsgMinReq;
  fields: Array<TFormFieldsPossibleNames>;
}) =>
  fields.map((f: TFormFieldsPossibleNames) => ({
    name: f,
    type: getFieldTypeByName(f),
    label: messages.form.fields.label[f],
    placeholder: messages.form.fields.placeholder[f],
    initValue: getFieldInitValueByType(getFieldTypeByName(f)),
  }));

export const getFormInitValuesFromFieldsParams = (
  params: Array<IFormSingleFieldParams>,
): FormikValues =>
  params.reduce(
    (reducer, field) => ({
      ...reducer,
      [field.name]:
        field.initValue || getFieldInitValueByType(field.type || 'text'),
    }),
    {},
  );
