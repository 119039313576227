import React from 'react';
import clsx from 'clsx';

const Triangle = ({
  className,
  direction,
}: {
  className?: string;
  direction: string;
}) => {
  return (
    <div className={clsx(className, 'assets_svg', 'triangle')}>
      <svg
        width="6"
        height="4"
        viewBox="0 0 6 4"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0L3 4L6 0H0Z" />
      </svg>
    </div>
  );
};

export default Triangle;
