import React, { useCallback } from 'react';
import LayoutDashboard from 'layout/Dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import getDispatchWrapper from 'utils/getDispatchWrapper';
import { ContextMessages } from 'services/messages/context';
import LayoutTable from 'layout/Table';
import Table from 'components/tables/Table/Table';
import CrudTab from 'layout/CrudTab';
import { CrudFormAdd, CrudFormUpdate } from 'components/forms/CrudForm';
import validators from 'services/validators';
import clsx from 'clsx';
import { backBtn, crudBtnAdd } from 'layout/Table/utils';
import { crudFormSetMode } from 'store/actions';
import { constants } from 'configShared/store';
import { columns, tableApiCall } from './tableConfig';
import {
  crudApiCall,
  crudFormParams,
  crudInitialValuesMapper,
} from './crudConfig';

type TPricingPlans = {
  className?: string;
  title?: string;
};

const reducerId = constants.PRICING_PLANS;

const PricingPlans: React.FC<TPricingPlans> = ({ title, className }) => {
  const history = useHistory();
  const dispatchRedux = useDispatch();
  const dispatch = useCallback(getDispatchWrapper(reducerId, dispatchRedux), [
    reducerId,
    dispatchRedux,
  ]);
  const messages = React.useContext(ContextMessages);
  const tabLabel = useSelector((state: any) => state[reducerId].crud.tab);
  const tableState = useSelector((state: any) => state[reducerId].table);

  const tableApiCallMemo = React.useMemo(() => tableApiCall(dispatch), [
    dispatch,
  ]);

  return (
    <LayoutDashboard className={clsx(className, 'page_PricingPlans')}>
      <LayoutTable
        tabLabel={tabLabel}
        title={title}
        reducerId={reducerId}
        backBtn={title && backBtn(dispatch, title)}
        crudBtnAdd={tabLabel !== 'crud-add' && crudBtnAdd(dispatch, '+ Create')}
      >
        <div className="tab" data-label="root">
          <Table
            dispatch={dispatch}
            state={tableState}
            apiCallWrapper={tableApiCallMemo}
            columns={columns}
            handleOnRowClick={(row: any) => {
              history.push(
                `/pricingPlansPackages?pricingPlanId=${row.id}&title=${row.title}`)
            }}
            handleOnEdit={(row: any) => {
              dispatch(
                crudFormSetMode({
                  tab: 'crud-update',
                  subTitle: <>{row.title}</>,
                  initValues: crudInitialValuesMapper(row),
                }),
              );
            }}
          />
        </div>
        <div className="tab" data-label="crud-add">
          <CrudTab>
            <CrudFormAdd
              apiCallWrapper={crudApiCall.add(dispatch)}
              dispatch={dispatch}
              reducerId={reducerId}
              validationSchema={validators(messages).dashboard.pricingPlans.add}
              params={crudFormParams(messages).add}
            />
          </CrudTab>
        </div>
        <div className="tab" data-label="crud-update">
          <CrudTab>
            <CrudFormUpdate
              apiCallWrapper={crudApiCall.update(dispatch)}
              deleteApiCallWrapper={crudApiCall.delete(dispatch)}
              dispatch={dispatch}
              reducerId={reducerId}
              validationSchema={
                validators(messages).dashboard.pricingPlans.update
              }
              params={crudFormParams(messages).update}
              backBtn={backBtn}
            />
          </CrudTab>
        </div>
      </LayoutTable>
    </LayoutDashboard>
  );
};

export default PricingPlans;
