import React from 'react';
import { TCrudFormAdd } from './types';
import CrudForm from './CrudFormStyled';
import ButtonsAdd from './ButtonsAdd';
import onSubmit from './onSubmit';
import clsx from 'clsx';
import { getFormInitValuesFromFieldsParams } from 'components/forms/Form';
import { useSelector } from 'react-redux';

export const CrudFormAdd: React.FC<TCrudFormAdd> = ({
  params,
  apiCallWrapper,
  dispatch,
  validationSchema,
  className,
  reducerId,
}) => {
  const { isProcessing } = useSelector((state: any) => state[reducerId].crud);
  return (
    <div className={clsx(className, 'CrudFormUpdate')}>
      <CrudForm
        title={'Create'}
        onSubmit={onSubmit(dispatch, apiCallWrapper, {
          reset: true,
          goToRoot: true,
        })}
        params={params}
        buttonsWrapper={() => <ButtonsAdd isProcessing={isProcessing} />}
        initialValues={getFormInitValuesFromFieldsParams(params)}
        validationSchema={validationSchema}
      />
    </div>
  );
};

export default CrudFormAdd;
