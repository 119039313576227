import * as constants from './constants';
import { formatPeriod } from '../utils';
import defInitState from '../initState';

const reducer = (initState: any = defInitState) => (
  state: any = initState,
  action: any,
) => {
  switch (action.type) {
    case constants.TABLE_ON_DATA_FETCH_START:
      return {
        ...state,
        isProcessing: true,
      };
    case constants.TABLE_ON_DATA_FETCH_FAIL:
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    case constants.TABLE_ON_DATA_FETCH_FINISH:
      return {
        ...state,
        data: action.resp.data,
        total: action.resp.total || 0,
        isProcessing: false,
      };
    case constants.TABLE_SET_DATA:
      return {
        ...state,
        data: action.data.data,
        total: action.data.total || 0,
        isProcessing: false,
      };
    case constants.TABLE_SET_IS_PROCESSING:
      return {
        ...state,
        isProcessing: action.isProcessing,
      };
    case constants.TABLE_SET_OFFSET:
      return {
        ...state,
        offset: action.offset,
      };
    case constants.TABLE_SET_SEARCH_CUSTOMER:
      return {
        ...state,
        searchCustomer: action.search,
        offset: 0,
      };
    case constants.TABLE_SET_SEARCH_PSU:
      return {
        ...state,
        searchPsu: action.search,
        offset: 0,
      };
    case constants.TABLE_SET_STATUS:
      return {
        ...state,
        status: action.status,
        offset: 0,
      };
    case constants.TABLE_SET_PERIOD:
      return {
        ...state,
        ...formatPeriod(action.period),
        period: action.period,
        offset: 0,
      };
    case constants.TABLE_SET_SORT:
      return {
        ...state,
        sortBy: action.sort.by,
        sortOrder: !state.sortOrder,
        offset: 0,
      };
    case constants.TABLE_FORCE_UPDATE:
      return {
        ...state,
        updatedAt: state.updatedAt + 1,
      };
    case constants.TABLE_ON_RESET:
      return initState;
    default:
      return state;
  }
};

export default reducer;
